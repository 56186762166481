import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import FCNavbarVerticalAdmin from "../../backend/components/FCNavbarVertical";
import HeaderDashboardAdmin from "./../../backend/components/HeaderDashboard";
import NavbarDashboardMobileAdmin from "./../../backend/components/NavbarDashboardMobile";
import ForumCard from "../components/ForumCard";
import MessageAllPage from "../../backend/components/MessageAllPage";
// import ForumSkeleton from "../components/ForumSkeleton";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";

class Forum extends Component {
  constructor(props) {
    super(props);
    // onRef={ref => (this.child = ref)}
    this.state = {
      token: window.localStorage.getItem("userToken"),
      userType: window.localStorage.getItem("userType"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      message: {
        name: "",
        description: "",
        creator_id: window.localStorage.getItem("userID"),
        session: null,
      },
      errors: {},
      is_empty_name: false,
      is_empty_session: false,
      isLoading: false,
      sessions: [],
      isSuccess: false,
      isError: false,
    };
  }

  handleChange = (e) => {
    let message = this.state.message;

    if (e.target.name === "session") {
      message[e.target.name] = parseInt(e.target.value);
    } else {
      message[e.target.name] = e.target.value;
    }

    this.setState({
      message: message,
    });
    // console.log(message);
  };

  onValidateForm() {
    let message = this.state.message;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!message["name"]) {
      isValidForm = false;
      this.setState({
        is_empty_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_name: false,
        });
      }, 5000);

      errors["name"] = "Ce champ est obligatoire";
    }
    // if (!message["session"]) {
    //   isValidForm = false;
    //   this.setState({
    //     is_empty_session: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       is_empty_session: false,
    //     });
    //   }, 5000);

    //   errors["session"] = "Ce champ est obligatoire";
    // }

    if (!message["creator_id"]) {
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  }

  addForm = (e) => {
    e.preventDefault();
    // console.log('valid', this.onValidateForm())
    if (this.onValidateForm()) {
      this.setState({
        isLoading: true,
      });

      var api = this.state.api;
      var data = this.state.message;

      axios
        .post(api + "forum/", data, this.state.requestConfig)
        .then((response) => {
          // console.log(response)
          if (response.status === 201) {
            // console.log("sucess");
            toast.success("Votre post a été ajouté !");
            this.setState({
              isSuccess: true,
              isLoading: false,
            });
            setTimeout(() => {
              this.setState({
                isSuccess: false,
                message: {},
              });

              // window.location = "/forum";
            }, 5000);
            this.child._getForums();
            $(".close").click();
          } else {
            toast.error("Vos données ne sont pas valides !");
            this.setState({
              isError: true,
            });
            setTimeout(() => {
              this.setState({
                isError: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Oupsss! Une erreur est survenue lors d'ajout. Veuillez réessayer plus tard."
          );
          this.setState({
            isError: true,
            isLoading: false,
          });

          setTimeout(() => {
            this.setState({
              isError: false,
            });
          }, 5000);
        });
    }
  };

  componentDidMount() {
    this.getSession();
  }

  getSession = async () => {
    var api = this.state.api;
    // console.log('api', api)
    axios
      .get(api + "session", this.state.requestConfig)
      .then((response) => {
        // console.log('response', response.data.results)
        this.setState({
          sessions: response.data.results,
        });
      })
      .catch((error) => {
        // console.log(error);
        toast.error(
          "Oupsss! Une erreur est survenue lors de l'ajout. Veuillez réessayer plus tard."
        );
      });
  };

  render() {
    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      window.location = "connexion";
    } else {
      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              {this.state.userType === "student" ? (
                <NavbarDashboardMobile />
              ) : (
                <NavbarDashboardMobileAdmin />
              )}
            </div>
          </div>

          {this.state.userType === "student" ? (
            <HeaderDashboard />
          ) : (
            <HeaderDashboardAdmin />
          )}

          <div className="row">
            {/* Navbar Desktop  */}

            {this.state.userType !== "student" && (
              <>
                {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
                <div className="nav-container">
                  <div
                    className="navbar-vertical-customisation no-view-mobile"
                    style={{ top: "40px", zIndex: 98 }}
                  >
                    <FCNavbarVerticalAdmin />
                  </div>
                </div>

                <div className="col-md-2"></div>
              </>
            )}

            {this.state.userType === "student" && (
              <div className="col-xl-2 col-lg-3 col-md-12">
                <div
                  className="navbar-vertical-customisation no-view-mobile"
                  style={{ backgroundColor: "#009688" }}
                >
                  <NavbarVertical />
                </div>
              </div>
            )}

            <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 p-t-25 content-position-after-header">
              <div className="card-content-forum">
                <h4 className="titre-page-dashboard m-b-15">Forum</h4>
                <div className="dis-flex justify-content-center m-b-45">
                  {/* {this.state.userType === 'student' &&
                    <button
                      type="button"
                      className="btn-add-forum"
                      data-toggle="modal"
                      data-target=".modalAddSujet"
                    >
                      <FeatherIcon
                        className="icon-btn-add-forum"
                        icon="plus-circle"
                      />{" "}
                      Nouveau sujet
                    </button>
                  } */}

                  <div
                    className="modal fade modalAddSujet"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content modal-card">
                        <div className="row m-b-35">
                          <div className="col-10 itm-center">
                            <p className="title-modal">Ajouter un sujet</p>
                          </div>
                          <div className="col-2">
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">
                                <FeatherIcon icon="x" />
                              </span>
                            </button>
                          </div>
                        </div>

                        <form>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control input-modal"
                              placeholder="Titre"
                              name="name"
                              onChange={(e) => this.handleChange(e)}
                            />
                            {this.state.is_empty_name && (
                              <div className="alert alert-danger keysjob-alert-danger">
                                {" "}
                                {this.state.errors.name}{" "}
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <textarea
                              className="input-modal p-t-15"
                              rows="7"
                              placeholder="Votre message ..."
                              name="description"
                              onChange={(e) => this.handleChange(e)}
                            ></textarea>
                          </div>
                          <div className="form-group">
                            <select
                              className="input-modal form-control"
                              name="session"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="">
                                {" "}
                                Selectionnez une session
                              </option>
                              {this.state.sessions
                                .reverse()
                                .slice()
                                .map((session) => {
                                  return (
                                    <option key={session.id} value={session.id}>
                                      {" "}
                                      {session.name}{" "}
                                    </option>
                                  );
                                })}
                            </select>
                            {/* {this.state.is_empty_session && (
                              <div className="alert alert-danger keysjob-alert-danger">
                                {" "}
                                {this.state.errors.session}{" "}
                              </div>
                            )} */}
                          </div>
                        </form>

                        <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                          {/* <a className="btn-theme p-l-90 p-r-90" href="/">
                          ENVOYER
                        </a> */}
                          {this.state.isLoading === false ? (
                            <button
                              type="submit"
                              onClick={(e) => this.addForm(e)}
                              className="btn-theme p-l-90 p-r-90"
                            >
                              ENVOYER
                            </button>
                          ) : (
                            <button className="btn-theme p-l-90 p-r-90 in-progress-btn">
                              Ajout en cours... &nbsp;
                              <i className="fas fa-spin fa-spinner"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ForumCard onRef={(ref) => (this.child = ref)} />
              </div>
            </div>
          </div>
          {this.state.isSuccess || this.state.isError ? <ToastContainer /> : ""}

          {/* <div className="">
            <MessageAllPage />
          </div> */}
        </div>
      );
    }
  }
}

export default Forum;
