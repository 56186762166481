import axios from "axios";
import FeatherIcon from "feather-icons-react";
import $ from "jquery";
import React, { Component, useCallback, useState } from "react";
import Swal from "sweetalert2";
import api from "../../http/global-vars";
import TypeContentCreating from "../TypeContentCreating";
import EditSlideModal from "./EditSlideModal";
import ModalAddTitleContenu from "./ModalAddTitleContenu";
import ModalEditSession from "./ModalEditSession";
import SupportClickReaveal from "./supportContentAdmin/SupportClickReaveal";
import SupportContentScale from "./supportContentAdmin/SupportContentScale";
import SupportContentTextATroux from "./supportContentAdmin/SupportContentTextATrous";
import SupportContentTextField from "./supportContentAdmin/SupportContentTextField";
import SupportContentVideo from "./supportContentAdmin/SupportContentVideo";
import SupportGlisseDepot from "./supportContentAdmin/SupportGlisseDepot";
import SupportImages from "./supportContentAdmin/SupportImages";
import SupportPodcast from "./supportContentAdmin/SupportPodcast";
import SupportQcm from "./supportContentAdmin/SupportQcm";
import SupportUpload from "./supportContentAdmin/SupportUpload";
import SupprtContentYN from "./supportContentAdmin/SupprtContentYN";

export default class ItemIntroModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api: api,
      data: [],
      open: false,
    };
  }

  componentDidMount() {
    // console.log(this.props)
    this._getContent();
  }

  _getContent = async () => {
    if (!this.props.session) {
      this._getContentByModule();
    } else {
      this._getContentBySession();
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.session !== prevProps.session) {
      this._getContent();
    }
  }
  _getContentByModule = async () => {
    let { api } = this.state;
    let url = api + `contenubymodule/${this.props.module_id}`;
    try {
      let res = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.userToken}` },
      });

      let data = res["data"];

      this.setState({ data: data });
    } catch (error) {
      console.log(error.message);
    }
  };
  _getContentBySession = async () => {
    let { api } = this.state;
    let url = api + `contenubysession/${this.props.session.id}`;
    try {
      let res = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.userToken}` },
      });
      let data = res["data"];
      this.setState({ data: data });
    } catch (error) {
      console.log(error.message);
      console.log(error.response);
    }
  };
  onDelete = (id) => {
    Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer cet élement ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: false,
      iconColor: "#f39200",
      preConfirm: () => {
        return axios
          .delete(`${api}scale/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          })
          .then(async (response) => {
            this._getContentBySession();
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Erreur: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((results) => {
      if (results?.isConfirmed) {
        Swal.fire({
          title: "Element supprimé avec succès !",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          focusCancel: false,
          iconColor: "#f39200",
          timer: 3000,
        });
      }
    });
  };

  render() {
    let { data } = this.state;
    const collapseId = this.props.session
      ? `Session_${this.props.session.id}`
      : "ModuleIntro";
    return (
      <div className="card-item-sessions-module contenu-shadow">
        <a
          className="link-collapse-session-item collapsed"
          data-toggle="collapse"
          href={`#${collapseId}`}
          role="button"
          aria-expanded="false"
          aria-controls="collapseItemSessionModule"
        >
          <i
            className="fa chevron-collapse-session-item"
            aria-expanded="false"
          ></i>
          {this.props.session?.name || "Introduction"}
        </a>
        <div className="collapse" id={collapseId}>
          <div className="card card-body p-t-50 m-b-0">
            <div className="row dis-flex justify-content-center">
              <div className="col-md-12 offset-md-0">
                {data.map((item, indx) => (
                  <ContenueItem
                    module={this.props.module}
                    session={this.props.session}
                    onDelete={this.onDelete}
                    onReload={this._getContent}
                    item={item}
                    key={item.id}
                  />
                ))}
                <AddContenuButton
                  moduleId={this.props.module_id}
                  sessionId={this.props.session?.id}
                  onDone={() => this._getContent()}
                />
              </div>
            </div>
          </div>
        </div>
        {!!this.props.session && (
          <div className="btn-action-container-modul-session">
            <EditBtnSession
              session={this.props.session}
              getSessions={() => this.props?.getSessions()}
            />
            <DeleteSession
              id={this.props.session?.id}
              getSessions={() => this.props?.getSessions()}
            />
          </div>
        )}
      </div>
    );
  }
}

function ContenueItem({ item, module, session, onReload, onDelete }) {
  const [show, setShow] = useState(false);
  const prefix = session ? "Session_" : "Module_";
  const collapseId = `${prefix}ContenueItem-${item.id}`;
  const support = item.list_supports ? item.list_supports[0] : null;

  const task =
    item.content_type === "scale"
      ? item.list_task
      : item.list_task
      ? item.list_task[0]
      : null;
  const DATA = [
    { title: "Taux de progression", value: item.progression },
    { title: "Forum", value: item.forum || "-" },
    { title: "A afficher sur", value: item.a_afficher_sur || "-" },
    { title: "Téléchargeable ?", value: item.download ? "OUI" : "NON" },
  ];
  return (
    <div>
      <div className="card-item-sessions-module contenu-shadow">
        <a
          className="link-collapse-session-item collapsed"
          style={{ display: "block", paddingRight: 60 }}
          data-toggle="collapse"
          href={`#${collapseId}`}
          onClick={() => setShow(!show)}
          role="button"
          aria-expanded="false"
          aria-controls="collapseItemSessionModule"
        >
          <i
            className="fa chevron-collapse-session-item"
            aria-expanded="false"
          ></i>
          {item.title}
        </a>
        <div className="collapse" id={collapseId}>
          {show && (
            <div className="card card-body p-t-20 m-b-0">
              <div
                className="row dis-flex justify-content-center"
                style={{ width: "100%" }}
              >
                <div className="col-md-10 offset-md-1">
                  <div className="row">
                    {DATA.map((row) => {
                      return (
                        <div
                          className="col-md-3 text-center mb-2"
                          key={row.title}
                        >
                          <div>{row.title}</div>
                          <div style={{ fontWeight: "bold" }}>{row.value}</div>
                        </div>
                      );
                    })}
                  </div>
                  {!!support && (
                    <SupportItem
                      onReload={onReload}
                      contentId={item.id}
                      item={support}
                    />
                  )}
                  {!!task && (
                    <TaskItem
                      contentId={item.id}
                      onReload={onReload}
                      onDelete={onDelete}
                      item={task}
                      type={item.content_type}
                    />
                  )}

                  <TypeContentCreating
                    visible={!support && !task}
                    onReload={onReload}
                    contenu={item}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="btn-action-container-modul-session">
          <EditBtnContent
            moduleId={session ? undefined : module?.id}
            sessionId={session?.id}
            contenu={item}
            onDone={() => onReload()}
          />
          <DeleteContent id={item?.id} getContent={() => onReload()} />
        </div>
      </div>
    </div>
  );
}

function SupportItem({ item, contentId, onReload }) {
  if (item.content_type === "podcast") {
    return <SupportPodcast onReload={onReload} data={item} />;
  }

  if (item.content_type === "video") {
    // done
    return <SupportContentVideo data={item} onReload={() => onReload()} />;
  }

  // done
  return (
    <div class="row">
      <div className="col-md-10">
        <h4 style={{ marginBottom: 20 }}>{item.name}</h4>
        <div
          className="content-item"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>
      <div className="col-md-2 text-right">
        <EditBtnSupport support={item} onReload={() => onReload()} />
        <DeleteSupport id={item?.id} onReload={() => onReload()} />
      </div>
    </div>
  );
}

function TaskItem({ item, contentId, type, onReload, onDelete }) {
  if (item.content_type === "yes_no") {
    return (
      //done
      <SupprtContentYN contentId={contentId} onReload={onReload} data={item} />
    );
  }
  if (item.content_type === "multiple_choice") {
    //done
    return <SupportQcm onReload={onReload} data={item} contentId={contentId} />;
  }
  //done
  if (item.content_type === undefined && type === "texte_trous") {
    return (
      <SupportContentTextATroux
        onReload={onReload}
        data={item}
        contentId={contentId}
      />
    );
  }
  //done
  if (item.content_type === "drag_drop") {
    return <SupportGlisseDepot data={item} onReload={() => onReload()} />;
  }
  // progress
  if (item.content_type === "upload") {
    return <SupportUpload onReload={onReload} data={item} />;
  }
  if (item.content_type === "image") {
    //done
    return <SupportImages onReload={onReload} data={item} />;
  }
  if (item.content_type === "text_field") {
    // done
    return <SupportContentTextField data={item} onReload={() => onReload()} />;
  }
  if (item.content_type === "click_reveal") {
    // done
    return (
      <SupportClickReaveal
        onReload={onReload}
        data={item}
        contentId={contentId}
      />
    );
  }
  if (type === "scale") {
    // done
    return <SupportContentScale data={item} onDeleteScale={onDelete} />;
  }

  return (
    <div className="session-content-container">
      <div
        className="col-md-12 offset-md-0 w-100 content-item"
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    </div>
  );
}
export function AddContenuButton({ onDone, sessionId, moduleId }) {
  const prefix = sessionId ? "Session_" : "Module_";
  const modalId = `${prefix}AddContenuButton${sessionId || moduleId}`;
  return (
    <>
      <button
        className="btn-light-medium-large m-t-30"
        data-toggle="modal"
        data-target={`#${modalId}`}
      >
        <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
        Ajouter le titre du contenu
      </button>

      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content card-dashboard">
            <div className="col-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <ModalAddTitleContenu
              onDone={() => {
                hideModal(`#${modalId}`);
                onDone();
              }}
              module_id={sessionId ? undefined : moduleId}
              session_id={sessionId}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export function hideModal(selector) {
  $(selector).hide();
  $(".modal-backdrop").hide();
  $("body").css({ overflow: "scroll" });
}

export const DeleteContent = ({ id, getContent }) => {
  const onDelete = useCallback(() => {
    Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer ce contenu ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: false,
      iconColor: "#f39200",
      preConfirm: () => {
        return axios
          .delete(`${api}contenu/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          })
          .then(async (response) => {
            await getContent();
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Erreur: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((results) => {
      if (results?.isConfirmed) {
        Swal.fire({
          title: "Contenu supprimé avec succès !",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          focusCancel: false,
          iconColor: "#f39200",
          timer: 3000,
        });
      }
    });
  }, [getContent, id]);
  return (
    <button
      className="btn-action-modul-session"
      title="Supprimer"
      onClick={() => onDelete()}
    >
      <FeatherIcon icon="trash-2" />
    </button>
  );
};

const EditBtnContent = ({ contenu, onDone, moduleId, sessionId }) => {
  const modalId = `editContenuModal${contenu?.id}`;
  return (
    <>
      <button
        className="btn-action-modul-session"
        data-toggle="modal"
        data-target={`#${modalId}`}
      >
        <FeatherIcon icon="edit" />
      </button>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content card-dashboard">
            <div className="col-2">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <ModalAddTitleContenu
              item={contenu}
              module_id={moduleId}
              session_id={sessionId}
              content={contenu}
              onDone={() => {
                hideModal(`#${modalId}`);
                onDone();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteSession = ({ id, getSessions }) => {
  const onDelete = useCallback(() => {
    Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer cette session ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: false,
      iconColor: "#f39200",
      preConfirm: () => {
        return axios
          .delete(`${api}session/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          })
          .then(async (response) => {
            await getSessions();
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Erreur: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((results) => {
      if (results?.isConfirmed) {
        Swal.fire({
          title: "Session supprimée avec succès !",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          focusCancel: false,
          iconColor: "#f39200",
          timer: 3000,
        });
      }
    });
  }, [getSessions, id]);
  return (
    <button
      className="btn-action-modul-session"
      title="Supprimer"
      onClick={() => onDelete()}
    >
      <FeatherIcon icon="trash-2" />
    </button>
  );
};

const EditBtnSession = ({ session, getSessions }) => {
  return (
    <>
      <button
        className="btn-action-modul-session"
        data-toggle="modal"
        data-target={`#editSessionModal${session?.id}`}
      >
        <FeatherIcon icon="edit" />
      </button>

      <div
        className="modal fade"
        id={`editSessionModal${session?.id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content card-dashboard">
            <ModalEditSession
              session={session}
              getSessions={() => getSessions()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const DeleteSupport = ({ id, onReload }) => {
  const onDelete = useCallback(() => {
    Swal.fire({
      title: "Êtes-vous sûr de vouloir supprimer cet item ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: false,
      iconColor: "#f39200",
      preConfirm: () => {
        return axios
          .delete(`${api}support/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          })
          .then(async (response) => {
            await onReload();
            return response;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Erreur: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((results) => {
      if (results?.isConfirmed) {
        Swal.fire({
          title: "Item supprimé avec succès !",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          focusCancel: false,
          iconColor: "#f39200",
          timer: 3000,
        });
      }
    });
  }, [onReload, id]);
  return (
    <button
      className="btn-action-modul-session"
      title="Supprimer"
      onClick={() => onDelete()}
    >
      <FeatherIcon icon="trash-2" />
    </button>
  );
};

const EditBtnSupport = ({ support, onReload }) => {
  return (
    <>
      <button
        className="btn-action-modul-session"
        data-toggle="modal"
        data-target={`#editSupportModal${support?.id}`}
      >
        <FeatherIcon icon="edit" />
      </button>

      <div
        className="modal fade "
        id={`editSupportModal${support?.id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content card-dashboard">
            <EditSlideModal support={support} onReload={() => onReload()} />
          </div>
        </div>
      </div>
    </>
  );
};
