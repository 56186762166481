import React, { Component } from "react";
// import FeatherIcon from "feather-icons-react";
// import { NavLink } from 'react-router-dom';
import NavbarDashboardMobile from "./components/NavbarDashboardMobile";
import HeaderDashboard from "./components/HeaderDashboard";
import FCNavbarVerticalAdmin from "./components/FCNavbarVertical";
// import MessageAllPage from './components/MessageAllPage';
import api from "../http/global-vars";
import axios from "axios";
import AdminContactMessagesSkeleton from "./AdminContactMessagesSkeleton";
import CardAdminContactMessages from "./components/CardAdminContactMessages";
import MessageAllPage from "./components/MessageAllPage";

export default class AdminContactMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
      countMessages: 0,
      messages: [],
      isPageLoading: false,
      user: null,
    };
  }

  componentDidMount() {
    this.setState({
      isPageLoading: true,
    });
    this.onGetContactMessages();
  }

  onGetUserInfo = (id) => {
    const url = api + "user/" + id + "/";
    axios.get(url, this.state.requestConfig).then((res) => {
      // console.log('user', res.data)
      this.setState({ user: res.data });
    });
  };

  onGetContactMessages = async () => {
    // this.setState({
    //   isPageLoading: true,
    // })
    var api = this.state.api;
    var config = this.state.requestConfig;

    await axios
      .get(`${api}contact/`, config)
      .then((response) => {
        // console.log(response)
        if (response.status === 200 || response.status === 201) {
          this.setState({
            countMessages: response.data.count,
            messages: response.data.results.reverse(),
            isPageLoading: false,
          });

          // console.log('messages ==>', this.state.messages)
        } //else {
        //   console.log(response)
        //   this.setState({
        //     isPageLoading: false,
        //   })
        // }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isPageLoading: false,
        });
      });
  };

  handleFiltre = (e) => {
    console.log("statut", e.target.value);
  };

  //   onUpdateContactStatus = async (id) => {
  //     var api = this.state.api
  //     var config = this.state.requestConfig
  //     let data = this.state.messages.filter((msg) => msg.id === id)[0]
  //     data.etat = 'en_cours'
  //     data.user = data.user?.id
  //     if (data.user) {
  //         this.onGetUserInfo(data.user)
  //     }
  //     console.log('data', data)
  //     axios
  //       .put(`${api}contact/${id}/`, data, config)
  //       .then((response) => {
  //         let pos = this.state.messages.map((e) => e.id).indexOf(response.data.id)

  //         setTimeout(() => {
  //           this.state.messages[pos].user = this.state.user ? this.state.user : null
  //           this.setState({
  //             messages: this.state.messages,
  //             user: null
  //           })
  //           //console.log('response', this.state.user, this.state.messages[pos])
  //         }, 1000)
  //       })
  //       .catch((error) => {
  //         console.log('error', error)
  //       })
  //   }

  onUpdateContactStatusDone = async (id, status) => {
    var api = this.state.api;
    var config = this.state.requestConfig;
    let data = this.state.messages.filter((msg) => msg.id === id)[0];
    data.etat = status;
    data.user = data.user?.id;
    if (data.user) {
      this.onGetUserInfo(data.user);
    }
    // console.log('data', data)
    await axios
      .put(`${api}contact/${id}/`, data, config)
      .then((response) => {
        let pos = this.state.messages
          .map((e) => e.id)
          .indexOf(response.data.id);

        setTimeout(() => {
          this.state.messages[pos].user = this.state.user
            ? this.state.user
            : null;
          this.setState({
            messages: this.state.messages,
            user: null,
          });
          //console.log('response', this.state.user, this.state.messages[pos])
        }, 1000);

        // setTimeout(() => {
        //     this.onGetContactMessages()
        // }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  render() {
    var { isPageLoading, countMessages, messages } = this.state;
    // console.log('message', messages.filter(f => f.etat === 'nouveau'))
    return (
      <div className="admin-contact-messages-component">
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobile />
            </div>
          </div>
          <HeaderDashboard />

          <div className="row">
            {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
            <div className="nav-container">
              <div
                className="navbar-vertical-customisation no-view-mobile"
                style={{ top: "40px", zIndex: 98 }}
              >
                <FCNavbarVerticalAdmin />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-12"></div>

            <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
              <div className="container-fluid">
                <h6 className="text-black f-f-work fw-500 m-b-15">
                  MESSAGES DE CONTACT
                </h6>
                <div className="card-dashboard m-b-20">
                  {isPageLoading ? (
                    <AdminContactMessagesSkeleton />
                  ) : (
                    <div className="messages-contact-container">
                      <CardAdminContactMessages
                        countMessages={countMessages}
                        messages={messages}
                        onUpdateContactStatusDone={
                          this.onUpdateContactStatusDone
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <MessageAllPage />
          </div>
        </div>
      </div>
    );
  }
}
