import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import PersonIcon from "@material-ui/icons/Person";

export default class HeaderAccueil extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-white nav-accueil py-4 px-sm-0 px-3 fixed-top">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <NavLink className="navbar-brand" to="/">
            <img
              src="img/defaru300_100px.png"
              className="logo-accueil-header"
              alt=""
              loading="lazy"
              style={{ height: 40, width: 100 }}
            />
          </NavLink>
          <NavLink className="user-login-for-mobile" to="/connexion">
            <PersonIcon className="icon-user-login-for-mobile" />
          </NavLink>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="ml-auto dis-flex my-lg-0 my-md-5">
              <NavLink
                className="btn-connexion-header-accueil-v2 mr-md-3"
                to="/connexion"
                style={{ color: "#009688" }}
              >
                Connexion
              </NavLink>
              <NavLink
                className="btn-inscription-header-accueil-v2"
                to="/inscription"
                style={{ backgroundColor: "#009688" }}
              >
                Inscription
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
