import React from "react";
import HeaderAccueil from "./components/HeaderAccueil";
import { NavLink } from "react-router-dom";
// import MessageAllPage from "../backend/components/MessageAllPage";
import ContactSupport from "./components/ContactSupport";

function WelcomeV3() {
  return (
    <div>
      <HeaderAccueil />
      <div className="bg-white p-t-10 p-b-40">
        <div className="container">
          <div className="row m-b-80">
            <div className="col-lg-6 dis-flex itm-center">
              <div>
                <h3 className="text-dark fw-700 f-f-poppins lh-1-5">
                  Bonjour et bienvenu(e) à la formation à distance sur les Soft
                  Skills! <br /> Nous sommes ravis que tu sois parmi nous !{" "}
                </h3>
                <p className="text-black fs-16 fw-300 m-b-60">
                  Sur cette plateforme d’apprentissage en ligne tu auras
                  l’occasion de développer tes capacités et tes connaissances
                  dans certaines compétences relationnelles – dites « Soft
                  Skills » – et de les appliquer dans ton orientation
                  professionnelle et ta recherche d’emploi.
                </p>
              </div>
            </div>
            <div className="col-lg-5 dis-flex justify-content-center itm-center offset-md-1">
              <img
                className="w-full"
                src="img/landing_page/3.png"
                alt=""
                style={{ width: 300 }}
              />
              {/* <img className="w-full" src="img/img-accueil.png" alt="" /> */}
            </div>
          </div>

          <h3 className="h-theme f-f-poppins fw-700 m-b-35">Objectifs</h3>
          <p className="content-text-welcome m-b-60">
            L’objectif de cette formation est de
            <span className="fw-600">
              {" "}
              t’outiller et de te préparer à chercher et à trouver un emploi
            </span>
            qui corresponde à tes intérêts et tes capacités et à{" "}
            <span className="fw-600">
              {" "}
              t’insérer facilement et durablement dans le milieu professionnel
            </span>
            .
          </p>
          <h3 className="h-theme f-f-poppins fw-700 m-b-35">
            Contenu de la formation
          </h3>
          <p className="content-text-welcome m-b-20">
            Pour atteindre cet objectif, nous t’invitons à participer à cette
            formation qui est structurée autour de trois modules. Chaque module
            traite d’un sujet différent et est composé de trois à quatre
            sessions distinctes :
          </p>

          <ul className="ul-welcome">
            <li className="li-welcome">
              Le <span className="fw-600">module 1</span> traite du sujet de la{" "}
              <span className="fw-600">communication</span>. Dans ce module tu
              découvriras les différents types de communication et tu apprendras
              pourquoi des bonnes capacités en matière de communication sont
              importantes pour ton avenir professionnel.
            </li>
            <li className="li-welcome">
              Dans le <span className="fw-600">module 2</span> tu apprendras la
              signification du terme «{" "}
              <span className="fw-600">éthique de travail</span> », son
              application dans la vie professionnelle et les règles qui en
              découlent.
            </li>
            <li className="li-welcome">
              Dans le <span className="fw-600">module 3</span>, tu seras
              amené(e) à te focaliser sur les aspects pratiques liés à la{" "}
              <span className="fw-600">gestion de ta carrière</span>. D’abord tu
              identifieras tes besoins, tes intérêts, et tes points forts pour
              ensuite approfondir les aspects relatifs à tes démarches de
              recherche d’emploi.
            </li>
          </ul>

          <p className="content-text-welcome">
            A l’issue de chaque module de formation complété, tu recevras{" "}
            <span className="fw-600">un badge.</span>
            Mais pour obtenir le badge, il faudra d’abord parcourir
            l’intégralité du module, réaliser tous les exercices qui te sont
            proposés et participer dans les forums de discussions comme demandé.
          </p>
          <p className="content-text-welcome">
            Une fois que tu auras obtenu les trois badges, tu seras alors
            éligible pour recevoir le
            <span className="fw-600"> certificat de participation</span> à la
            formation. Attention que cette attestation est seulement disponible
            pour les participants éligibles, à savoir les participants qui ont
            entre 15-35 ans et résident au Sénégal.
          </p>

          <p className="content-text-welcome">
            Tu as <span className="fw-600"> 8 semaines</span> pour compléter les
            trois modules à compter de la date de ton inscription. Après 8
            semaines, il ne te sera plus possible d’obtenir le certificat.
          </p>

          <p className="content-text-welcome">
            Nous te recommandons de commencer par le premier module, de
            continuer avec le deuxième, et enfin finir avec le troisième module.
            Toutefois, tu peux également sauter d’un sujet à l’autre si tu
            préfères.
          </p>
          <p className="content-text-welcome">
            Nous te souhaitons un excellent parcours d’apprentissage et nous
            espérons que tu profiteras pleinement de cette expérience en ligne.
          </p>
          <p className="content-text-welcome">
            Si tu as des difficultés de connexion ou des difficultés à visionner
            le contenu, surtout n’hésite pas à
            <button
              className="link-contact-support-welcome"
              data-toggle="modal"
              data-target=".contactSupportModalWelcome"
            >
              contacter le support
            </button>
            <div
              className="modal fade contactSupportModalWelcome"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content modal-card">
                  <ContactSupport />
                </div>
              </div>
            </div>
          </p>
          <p className="content-text-welcome">Bonne chance et au plaisir ! </p>

          <div className="row dis-flex m-t-60">
            <div className="col-md-5">
              <NavLink
                className="btn-theme fw-600 f-f-poppins dis-flex justify-content-center"
                to="/fiche-identification"
              >
                Commencer
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="">
        <MessageAllPage />
      </div> */}
    </div>
  );
}

export default WelcomeV3;
