import axios from "axios";
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../http/global-vars";
import CardModuleSkeleton from "./CardModuleSkeleton";
import ModalAddModule from "./ModalAddModule";
import { useDeleteItem } from "./supportContentAdmin/SupportQcm";
import baseUrl from "../../http/backend-base-url";
// import useGetUser from "../../hooks/useGetUser";

function QuizItem() {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = window.localStorage.getItem("userToken");
  // const { userId } = useGetUser();

  const navigate = useNavigate();

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const apiUrl = `${api}module_creator`;
  // const apiUrl = `${api}module`;

  useEffect(() => {
    // setLoading(true);
    // const timer = setTimeout(() => {
    // }, 2000);
    // return () => clearTimeout(timer);
    getModules();
  }, []);

  const getModules = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiUrl, requestConfig);
      const data = response?.data?.results;
      if (data) {
        setModules(data);
      }
      // console.log({ data });
    } catch (error) {
      console.log("Error fetching modules:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <CardModuleSkeleton />
      ) : (
        <div className="tw-grid md:tw-grid-cols-3 lg:tw-grid-cols-4 sm:tw-grid-cols-2 tw-grid-cols-1 tw-gap-2">
          {modules
            ? modules.map((module) => (
                <ModuleDisplayItem
                  key={module.id}
                  module={module}
                  onReload={getModules}
                  onCardClick={() => navigate(`/module-details/${module.id}`)}
                />
              ))
            : "pas de module"}
        </div>
      )}
      <AddModuleModal onReload={getModules} />
    </>
  );
}

function AddModuleModal({ onReload }) {
  return (
    <div
      className="modal fade modalAddModule"
      id="AddModuleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content card-dashboard">
          <ModalAddModule getModules={onReload} />
        </div>
      </div>
    </div>
  );
}

function ModuleDisplayItem({ module, onReload, onCardClick }) {
  const modalId = `UpdateModule${module.id}`;

  // const onDelete = useDeleteItem(
  //   onReload,
  //   "module",
  //   "Module supprimé avec succès",
  //   "Module non supprimé"
  // );

  return (
    <>
      <div
        className="tw-bg-white tw-rounded-md tw-overflow-hidden tw-cursor-pointer tw-flex-1 tw-flex tw-flex-col"
        key={module.id}
        onClick={onCardClick}
      >
        <div className="tw-h-[200px] tw-w-full">
          <img
            src={`${baseUrl}${module?.logo}`}
            alt=""
            className="tw-h-full tw-w-full tw-object-cover"
          />
        </div>
        <div className="tw-p-2 tw-flex tw-flex-col tw-justify-between tw-flex-1">
          <p
            className=""
            id={`module-name${module.id}`}
            data-module={module.name}
          >
            {module.name}
          </p>
          <div className="tw-flex tw-gap-2 tw-items-center tw-justify-between">
            <div className="tw-bg-[#9993] tw-py-1 tw-px-3 tw-rounded-full tw-flex tw-items-center tw-gap-2 tw-text-sm">
              <p className="">{module.number_session}</p>
              <p className="">sessions</p>
            </div>
            {/* <NavLink
              className="btn-details-module-item d-block"
              to={{
                pathname: `/module-details/${module.id}`,
                state: module,
              }}
            >
              Détails
            </NavLink> */}
            {/* <button
              onClick={() => onDelete(module.id)}
              className="btn-action-modul-session"
            >
              <FeatherIcon icon="trash-2" />
            </button> */}
            <button
              data-toggle="modal"
              data-target={`#${modalId}`}
              className="btn-action-modul-session tw-p-2"
            >
              <FeatherIcon icon="edit" />
            </button>
          </div>
          <h3 style={{ color: "#ffa500" }}>
            @ {module?.creator_id?.first_name} {module?.creator_id?.last_name}
          </h3>
        </div>
      </div>
      <AddModuleModal onReload={onReload} />
    </>
  );
}

// function ModuleDisplayItem({ module, onReload }) {
//   const modalId = `UpdateModule${module.id}`;
//   const onDelete = useDeleteItem(
//     onReload,
//     "module",
//     "Module supprimé avec succès",
//     "Module non supprimé"
//   );

//   return (
//     <>
//       <div className="module-item" key={module.id}>
//         <div className="row">
//           <div className="col-md-10 col-sm-9">
//             <p
//               className="titre-module-item"
//               id={`module-name${module.id}`}
//               data-module={module.name}
//             >
//               {module.name}
//             </p>
//             <div className="row">
//               <div className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12 dis-flex itm-center m-b-15">
//                 <img
//                   className="icon-module-item"
//                   src="img/icons/module-icon-card1.png"
//                   alt=""
//                 />
//                 <div className="align-right-in-small-device">
//                   <p className="chiffre-module-item">{module.number_session}</p>
//                   <p className="text-content-module-item">sessions</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-2 col-sm-3 text-center">
//             <NavLink
//               className="btn-details-module-item d-block"
//               to={{
//                 pathname: `/module-details/${module.id}`,
//                 state: module,
//               }}
//             >
//               Détails
//             </NavLink>
//             <button
//               onClick={() => onDelete(module.id)}
//               className="btn-action-modul-session"
//             >
//               <FeatherIcon icon="trash-2" />
//             </button>
//             <button
//               data-toggle="modal"
//               data-target={`#${modalId}`}
//               className="btn-action-modul-session"
//             >
//               <FeatherIcon icon="edit" />
//             </button>
//           </div>
//         </div>
//       </div>
//       <AddModuleModal onReload={onReload} />
//     </>
//   );
// }

export default QuizItem;
