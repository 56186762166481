import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import FCNavbarVerticalAdmin from "./components/FCNavbarVertical";
import ModalDetailsApprenant from "./components/ModalDetailsApprenant";
// import { NavLink } from 'react-router-dom';
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
  ButtonGroup,
} from "react-bootstrap-table";
import $ from "jquery";
import api from "./../http/global-vars";
import axios from "axios";
import MessageAllPage from "./components/MessageAllPage";
import ImportApprenant from "./components/ImportApprenant";
import { toast } from "react-toastify";
import ApprenantSkeleton from "./AdminSkeletons/ApprenantSkeleton";
import Skeleton from "react-loading-skeleton";
import SendPasswordModal from "./components/SendPasswordModal";

export default class QuizApprenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      apprenants: [],
      nbreHommes: 0,
      nbreFemmes: 0,
      ageMoyenne: 0,
      isErrorRegenerate: false,
      isSuccessRegenerate: false,
      isPageLoading: true,
      apprenantInfos: {},
      itemToActive: [],
    };

    this.onRegenerateApprenantPassword =
      this.onRegenerateApprenantPassword.bind(this);
  }

  componentDidMount() {
    $(".react-bs-table-search-form input.form-control").attr(
      "placeholder",
      "Chercher"
    );

    this.onGetApprenantsInfos();
  }

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  onGetApprenantsInfos = () => {
    // this.setState({
    //   isPageLoading: true,
    // })
    var api = this.state.api;
    var config = this.state.requestConfig;
    axios
      .get(api + "apprenants_info/", config)
      .then((response) => {
        console.log(response.data.data);

        this.setState({
          apprenants: response.data.data.list_student.sort(
            (a, b) => b?.id - a?.id
          ),
          nbreHommes: response.data.data.homme,
          nbreFemmes: response.data.data.femme,
          ageMoyenne: response.data.data.moyenne_age,
          //je teste pour voir
          // apprenantInfos: [],
          moduleInfos: [],
          itemToActive: [],
        });
        // console.log(response.data.data.list_student)
        this.state.apprenants.forEach((item) => {
          item.modules.forEach((it, indx) => {
            item["module_" + (indx + 1)] =
              it.module.name + " = " + it.progression + "%";
          });
        });
        // console.log(this.state.apprenants)
        this.setState({
          isPageLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isPageLoading: false,
        });
        console.log(error);
      });
  };

  onGetUserDoModules = (userID) => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(`${api}usermodules/${userID}/`, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          var infos = response.data.data;
          this.setState({
            moduleInfos: infos,
          });
        } else {
          //console.log(response)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  fullNameFilter = (cell, row) => {
    return row.first_name + " " + row.last_name;
  };
  fullNameFormatter = (cell, row) => {
    //console.table(row)
    return (
      <>
        <span
          data-toggle="modal"
          data-target={".modalDetailsApprenant" + row.id}
          onClick={(e) => this.onGetApprenantInfos(row)}
          className="apprenant_fullname linkable"
        >
          {" "}
          {row.first_name + " " + row.last_name}{" "}
        </span>
        <div
          className={"modal fade modalDetailsApprenant" + row.id}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className={"modal-dialog modal-xl"}>
            <div className={"modal-content card-dashboard p-b-100"}>
              <ModalDetailsApprenant
                id={this.state?.apprenantInfos?.id}
                apprenantInfos={this.state?.apprenantInfos}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  onGetApprenantInfos = (row) => {
    // console.log(row)
    this.setState({
      apprenantInfos: row,
    });
  };

  onActionFormatter = (cell, row) => {
    return (
      <div className="actions-buttons-container d-flex justify-content-between">
        <button
          className={
            "btn btn-primary mr-1 gfa-btn-action-primary spinnable " + row.id
          }
          data-toggle="modal"
          title="Génèrer un mot de passe"
          onClick={() => this.onRegenerateApprenantPassword(row)}
        >
          <FeatherIcon
            icon="hash"
            className="regenerate-apprenant-password-icon"
          />
        </button>
        <button
          className={
            "btn btn-primary mr-1 gfa-btn-action-primary spinnable " + row.id
          }
          data-toggle="modal"
          title="Créer un mot de passe"
          data-target={`#SendPasswordModal${row?.id}`}
          // onClick={() => this.onRegenerateApprenantPassword(row)}
        >
          <FeatherIcon
            icon="lock"
            className="regenerate-apprenant-password-icon"
          />
        </button>
        {!row?.active && !this.state.itemToActive?.includes(row?.id) && (
          <button
            className={
              "btn btn-primary gfa-btn-action-primary spinnable" + row.id
            }
            title="Activer ce compte"
            onClick={() => this.onActiveApprenant(row)}
          >
            <FeatherIcon
              icon="check-circle"
              className="regenerate-apprenant-password-icon"
            />
          </button>
        )}

        <div
          className="modal fade sendPasswordModal"
          id={`SendPasswordModal${row?.id}`}
          tabindex="-1"
          role="dialog"
          data-backdrop="static"
          aria-labelledby="SendPasswordModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content card-dashboard">
              <SendPasswordModal apprenant={row} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  onActiveApprenant = (apprenant) => {
    if (window.confirm("Confirmez l'action")) {
      // console.log(apprenant);
      let array = [];
      array.push(apprenant?.id);

      this.setState({ itemToActive: [...this.state.itemToActive, ...array] });
      // console.log("array", [...this.state.itemToActive, ...array]);
      var api = this.state.api;
      var config = this.state.requestConfig;
      var apprenantID = apprenant.id;
      $(".spinnable" + apprenant.id).html(
        '<span className="fas fa-spin fa-spinner"></span>'
      );

      axios
        .get(`${api}active_user/${apprenantID}/`, config)
        .then((response) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          if (response.status === 200 || response.status === 201) {
            toast.success("Le compte de l'apprenant a été bien activé.");
            console.log("response", response);
            this.onGetApprenantsInfos();
            this.setState({
              isSuccessRegenerate: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessRegenerate: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          console.log(error);
          toast.error("Une erreur est survnue. Veuillez réessayer plus tard.");
          this.setState({
            isErrorRegenerate: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorRegenerate: false,
            });
          }, 5000);
        });
    }
  };

  onRegenerateApprenantPassword = (apprenant) => {
    if (window.confirm("Confirmez l'action")) {
      console.log(apprenant);
      var api = this.state.api;
      var config = this.state.requestConfig;
      var apprenantID = apprenant.id;
      $(".spinnable" + apprenant.id).html(
        '<span className="fas fa-spin fa-spinner"></span>'
      );

      axios
        .get(`${api}regeneratepassword/${apprenantID}/`, config)
        .then((response) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          if (response.status === 200 || response.status === 201) {
            toast.success(
              "Le mot de passe de l'apprenant a été bien regénéré."
            );
            this.setState({
              isSuccessRegenerate: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessRegenerate: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          var icon =
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-hash regenerate-apprenant-password-icon"><g><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></g></svg>';
          $(".spinnable" + apprenant.id).html(icon);
          console.log(error);
          toast.error("Une erreur est survnue. Veuillez réessayer plus tard.");
          this.setState({
            isErrorRegenerate: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorRegenerate: false,
            });
          }, 5000);
        });
    }
  };

  onProgressionModuleFormatter1 = (cell, row) => {
    var progression = 0;
    let module1 = row.modules?.filter(
      (item) => item.module.name === "Module 1 : La communication"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module1?.length && module1?.length === 1) {
      return (progression = module1[0]?.progression + "%");
    }
    if (module1?.length && module1?.length > 1) {
      return (progression =
        Math.max.apply(
          Math,
          module1.map(function (o) {
            return o.progression;
          })
        ) + "%");
    }
    // row.modules.forEach((item, index) => {

    //   if (item.module.name === "Module 1 : La communication") {
    //     progression = item.progression
    //   }
    // })
    return progression + "%";
  };
  onProgressionModuleFilter1 = (cell, row) => {
    var progression = 0;
    let module1 = row.modules?.filter(
      (item) => item.module.name === "Module 1 : La communication"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module1?.length && module1?.length === 1) {
      return (progression = module1[0]?.progression);
    }
    if (module1?.length && module1?.length > 1) {
      return (progression = Math.max.apply(
        Math,
        module1.map(function (o) {
          return o.progression;
        })
      ));
    }
    // row.modules.forEach((item, index) => {

    //   if (item.module.name === "Module 1 : La communication") {
    //     progression = item.progression
    //   }
    // })
    return progression;
  };
  onProgressionModuleFormatter2 = (cell, row) => {
    var progression = 0;
    let module2 = row.modules?.filter(
      (item) => item.module.name === "Module 2  : L'éthique de travail"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module2?.length && module2?.length === 1) {
      return (progression = module2[0]?.progression + "%");
    }
    if (module2?.length && module2?.length > 1) {
      return (progression =
        Math.max.apply(
          Math,
          module2.map(function (o) {
            return o.progression;
          })
        ) + "%");
    }
    // row.modules.forEach((item, index) => {
    //   if (index === row.modules?.length - 2) {
    //     progression = item.progression
    //   }
    // })
    return progression + "%";
  };
  onProgressionModuleFormatter3 = (cell, row) => {
    var progression = 0;
    let module3 = row.modules?.filter(
      (item) => item.module.name === "Module 3 : La gestion de carrière"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module3?.length && module3?.length === 1) {
      return (progression = module3[0]?.progression + "%");
    }
    if (module3?.length && module3?.length > 1) {
      return (progression =
        Math.max.apply(
          Math,
          module3.map(function (o) {
            return o.progression;
          })
        ) + "%");
    }
    return progression + "%";
  };

  onProgressionModuleFilter2 = (cell, row) => {
    var progression = 0;
    let module2 = row.modules?.filter(
      (item) => item.module.name === "Module 2  : L'éthique de travail"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module2?.length && module2?.length === 1) {
      return (progression = module2[0]?.progression);
    }
    if (module2?.length && module2?.length > 1) {
      return (progression = Math.max.apply(
        Math,
        module2.map(function (o) {
          return o.progression;
        })
      ));
    }
    // row.modules.forEach((item, index) => {
    //   if (index === row.modules?.length - 2) {
    //     progression = item.progression
    //   }
    // })
    return progression;
  };
  onProgressionModuleFilter3 = (cell, row) => {
    var progression = 0;
    let module3 = row.modules?.filter(
      (item) => item.module.name === "Module 3 : La gestion de carrière"
    );

    //console.log('module1', Math.max.apply(Math, module1.map(function(o) { return o.progression; })))
    if (module3?.length && module3?.length === 1) {
      return (progression = module3[0]?.progression);
    }
    if (module3?.length && module3?.length > 1) {
      return (progression = Math.max.apply(
        Math,
        module3.map(function (o) {
          return o.progression;
        })
      ));
    }
    return progression;
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
      </ButtonGroup>
    );
  };

  render() {
    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      window.location = "connexion";
    } else {
      const options = {
        clearSearch: true,
        clearSearchBtn: this.createCustomClearButton,
        noDataText: "Aucune donnée disponible",
        btnGroup: this.createCustomButtonGroup,
        exportCSVText: "Extraction",
      };

      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobileAdmin />
            </div>
          </div>

          <HeaderDashboardAdmin />

          <div className="row">
            {/* Navbar Desktop  */}
            {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
            <div className="nav-container">
              <div
                className="navbar-vertical-customisation no-view-mobile"
                style={{ top: "40px", zIndex: 98 }}
              >
                <FCNavbarVerticalAdmin />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-12"></div>

            <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
              <div className="container-fluid">
                <div className="row m-b-10">
                  <div className="col-xl-6">
                    <h5 className="text-black f-f-work fw-500 m-b-15">
                      Apprenant
                    </h5>
                  </div>
                  <div className="col-xl-6 dis-flex justify-content-end justify-left-tablette">
                    <button
                      className="btn-add-dashboard"
                      data-toggle="modal"
                      data-target=".import-apprenant-cv"
                    >
                      <FeatherIcon
                        className="icon-btn-add-dashboard"
                        icon="plus-circle"
                      />
                      Ajouter des apprenants
                    </button>
                  </div>
                </div>
                {this.state.isPageLoading ? (
                  <div className="row m-b-10">
                    <CardSkeleton />
                  </div>
                ) : (
                  <div className="row m-b-10">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                      <div className="card-dashboard w-full m-b-15">
                        <p className="chiffre-statistics-apprenant-page">
                          {this.state.nbreHommes}
                        </p>
                        <p className="legend-statistics-apprenant-page">
                          Total des apprenants homme{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                      <div className="card-dashboard w-full m-b-15">
                        <p className="chiffre-statistics-apprenant-page">
                          {this.state.nbreFemmes}
                        </p>
                        <p className="legend-statistics-apprenant-page">
                          Total des apprenants femme{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                      <div className="card-dashboard w-full m-b-15">
                        <p className="chiffre-statistics-apprenant-page">
                          {this.state.ageMoyenne
                            ? parseInt(this.state.ageMoyenne)
                            : 0}
                        </p>
                        <p className="legend-statistics-apprenant-page">
                          Âge moyen{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card-dashboard p-l-0 p-r-0">
                  <div className="col">
                    <div className="row m-b-50">
                      <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12">
                        <p className="titre-card-dashboard-sans-padding m-b-15">
                          Liste des apprenants{" "}
                          <span className="certified-count">
                            {" "}
                            {`( ${this.state.apprenants.length} )`}{" "}
                          </span>{" "}
                        </p>
                      </div>
                    </div>

                    {/* <form>
                                            <button className="btn-theme py-2 px-4">
                                                <FeatherIcon className="icon-btn-add-dashboard-orange fs-15 fw-400" icon="download" />
                                                Extraction
                                            </button>
                                        </form> */}
                  </div>
                  <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                    {this.state.isPageLoading && <ApprenantSkeleton />}
                    {!this.state.isPageLoading && (
                      <div className="table-container">
                        <BootstrapTable
                          data={this.state.apprenants}
                          striped={true}
                          hover={true}
                          condensed={true}
                          multiColumnSort={2}
                          exportCSV={true}
                          options={options}
                          pagination={true}
                          search={true}
                          version="4"
                          bordered={false}
                        >
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            dataField="id"
                            isKey={true}
                            hidden={true}
                          >
                            Numero
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                              padding: 15,
                            }}
                            width="100"
                            dataField="id"
                            dataFormat={this.onActionFormatter.bind(this)}
                          >
                            Action
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataField="first_name"
                            filterValue={this.fullNameFilter}
                            dataFormat={this.fullNameFormatter}
                          >
                            Prénom & Nom
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            filter={{
                              type: "NumberFilter",
                              defaultValue: { comparator: "=" },
                            }}
                            filterValue={this.onProgressionModuleFilter1.bind(
                              this
                            )}
                            width="350"
                            dataField="Module 1"
                            csvFormat={this.onProgressionModuleFormatter1.bind(
                              this
                            )}
                            dataFormat={this.onProgressionModuleFormatter1.bind(
                              this
                            )}
                          >
                            Module 1 : La Communication
                          </TableHeaderColumn> */}
                          {/* <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="350"
                            dataField="Module 2"
                            csvFormat={this.onProgressionModuleFormatter2.bind(
                              this
                            )}
                            filter={{
                              type: "NumberFilter",
                              defaultValue: { comparator: "=" },
                            }}
                            filterValue={this.onProgressionModuleFilter2.bind(
                              this
                            )}
                            dataFormat={this.onProgressionModuleFormatter2.bind(
                              this
                            )}
                          >
                            Module 2 : L'hétique au travail
                          </TableHeaderColumn> */}
                          {/* <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="350"
                            dataField="Module 3"
                            csvFormat={this.onProgressionModuleFormatter3.bind(
                              this
                            )}
                            dataFormat={this.onProgressionModuleFormatter3.bind(
                              this
                            )}
                            filter={{
                              type: "NumberFilter",
                              defaultValue: { comparator: "=" },
                            }}
                            filterValue={this.onProgressionModuleFilter3.bind(
                              this
                            )}
                          >
                            Module 3 : La gestion de carriére
                          </TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataField="civilite"
                          >
                            Civilité
                          </TableHeaderColumn>
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataField="phone"
                          >
                            Téléphone
                          </TableHeaderColumn>
                          {/* <TableHeaderColumn 
                                                        thStyle={{fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none'}}
                                                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }} 
                                                        width="300" dataField='certification'>Certification</TableHeaderColumn> */}
                          <TableHeaderColumn
                            thStyle={{
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#1F497D",
                              border: "none",
                            }}
                            tdStyle={{
                              fontWeight: 500,
                              fontSize: 14,
                              color: "rgba(0, 0, 0, 0.7)",
                              border: "none",
                            }}
                            width="300"
                            dataField="email"
                          >
                            Email
                          </TableHeaderColumn>
                        </BootstrapTable>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <MessageAllPage />
          </div>
          <ImportApprenant onGetApprenantsInfos={this.onGetApprenantsInfos} />

          {/* {this.state.isSuccessRegenerate || this.state.isErrorRegenerate ? (
            <ToastContainer />
          ) : (
            ""
          )} */}
        </div>
      );
    }
  }
}

export const CardSkeleton = () => {
  const renderModules = Array(3)
    .fill()
    .map((item, index) => (
      <div className="col-md-4" key={index}>
        <div className="card">
          <Skeleton variant="rect" width={332} height={160} />
        </div>
      </div>
    ));
  return (
    <React.Fragment>
      {/* <Skeleton className="row mb-3 px-2" height={255} width={590} /> */}
      {renderModules}
    </React.Fragment>
  );
};
