import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
// import CardSessionParcours from "../components/CardSessionParcours";
import CardSessionFavoris from "../components/CardSessionFavoris";
import api from "../../http/global-vars";
import MessageAllPage from "../../backend/components/MessageAllPage";
import axios from "axios";
import AllCourseSkeleton from "./../components/AllCourseSkeleton";

class MyCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      isPageLoading: false,
      encours: null,
      termine: null,
    };
  }

  componentDidMount() {
    this.onGetUserDoModules();
  }

  onGetUserDoModules = () => {
    this.setState({
      isPageLoading: true,
    });

    var userID = this.state.userID;
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(`${api}usermodules/${userID}/`, config)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        console.log(response);
        if (response.status === 200 || response.status === 201) {
          this.setState({
            modulesDoingInfos: response.data.data,
          });

          var doing = 0;
          var done = 0;
          // eslint-disable-next-line
          response.data.data.map((item) => {
            if (item.progression === 100) {
              done++;
            } else {
              doing++;
            }
          });
          this.setState({
            encours: doing,
            termine: done,
          });
          // console.log(this.state.modulesDoingInfos)
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      window.location = "connexion";
    } else {
      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobile />
            </div>
          </div>

          <HeaderDashboard />

          <div className="row">
            {/* Navbar Desktop  */}
            <div className="col-xl-2 col-lg-3 col-md-12">
              <div
                className="navbar-vertical-customisation no-view-mobile"
                style={{ backgroundColor: "#009688" }}
              >
                <NavbarVertical />
              </div>
            </div>

            <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
              <div className="container-fluid">
                <h4 className="titre-page-dashboard m-b-35">Mon parcours</h4>
                <ul className="nav nav-tabs b-b-0" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link nav-link-all-courses active"
                      id="programmation-tab"
                      data-toggle="tab"
                      href="#doing"
                      role="tab"
                      aria-controls="programmation"
                      aria-selected="true"
                    >
                      {`(${this.state.encours} en cours)`}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className="nav-link nav-link-all-courses"
                      id="design-tab"
                      data-toggle="tab"
                      href="#done"
                      role="tab"
                      aria-controls="design"
                      aria-selected="false"
                    >
                      {`(${this.state.termine} terminé(s))`}
                    </a>
                  </li>
                </ul>
                <div className="tab-content m-t-40" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="doing"
                    role="tabpanel"
                    aria-labelledby="programmation-tab"
                  >
                    <div className="row">
                      {this.state.isPageLoading && <AllCourseSkeleton />}
                      {this.state.encours === 0 && (
                        <div className="gfa-rowy">
                          <div className="alert alert-warning gfa-warning">
                            Aucun module en cours
                          </div>
                        </div>
                      )}
                      {this.state.encours > 0 &&
                        this.state.modulesDoingInfos.map((item, index) =>
                          item.progression > 0 && item.progression !== 100 ? (
                            <CardSessionFavoris
                              moduleInfos={item.module}
                              progression={item.progression}
                              key={`in-progress-item${index}`}
                            />
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="done"
                    role="tabpanel"
                    aria-labelledby="design-tab"
                  >
                    <div className="row">
                      {this.state.isPageLoading && <AllCourseSkeleton />}
                      {this.state.termine === 0 && (
                        <div className="gfa-rowy">
                          <div className="alert alert-warning gfa-warning">
                            Aucun module terminé
                          </div>
                        </div>
                      )}
                      {this.state.termine > 0 &&
                        this.state.modulesDoingInfos.map((item, index) =>
                          item.progression === 100 ? (
                            <CardSessionFavoris
                              moduleInfos={item.module}
                              progression={item.progression}
                              key={`in-progress-item${index}`}
                            />
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="">
            <MessageAllPage />
          </div> */}
        </div>
      );
    }
  }
}

export default MyCourses;
