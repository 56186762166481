import { round } from "lodash";
import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import api from "../../http/global-vars";
// import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import { data } from "jquery";
// const data = {
// 	labels: [
// 		'Terminé',
// 		'En cours',
// 	],
// 	datasets: [{
// 		data: [60, 40],
// 		backgroundColor: [
// 			'#F39200',
// 			'#1F497D'
// 		],
// 		hoverBackgroundColor: [
// 			'#F39200',
// 			'#1F497D'
// 		]
// 	}]
// };

export default class CertificationDoughnutChart extends Component {
	state = {
		data: [],
		config: {
			headers: {
				Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
			},
		},
		api: api,
		percent_certif: 0,
		percent_noCertif: 0,
	};


	componentWillReceiveProps(nextProps, prevProps) {
		if (prevProps?.list_student !== nextProps?.list_student) {
			let nb_student_certif = nextProps?.list_student.length;
			// console.log('nb_student=>', nb_student_certif)
			let eligible_user = JSON.parse(localStorage.getItem('eligible_user'));
			// console.log(`student certifier=>`, nb_student_certif.length)
			let percent_certif = (nb_student_certif * 100) / eligible_user?.fiches;
			let percent_noCertif = 100 - percent_certif;
			this.setState({
				percent_certif: round(percent_certif, 1),
				percent_noCertif: round(percent_noCertif, 1),
			});
			// console.log("percent certified=>", percent_certif);
			// console.log("percent non certified=>", percent_noCertif);
		}
	}



	render() {
		let { percent_certif, percent_noCertif } = this.state;
		let { load } = this.props
		// console.log('load', this.props.load)		
		const DoughnutData = {
			labels: [`${percent_certif ? percent_certif : 0}% apprenants éligibles qui ont terminés les modules`, `${percent_noCertif ? percent_noCertif : 0}% apprenants éligibles qui ont entamés les modules`],
			datasets: [
				{
					// data: [60, 40],
					data: [percent_certif, percent_noCertif],
					backgroundColor: ["#F39200", "#1F497D"],
					hoverBackgroundColor: ["#F39200", "#1F497D"],
				},
			],
		};
		return (
			<React.Fragment>
				{load && (
					<div
						className="d-flex justify-content-center"
						style={style.marginTop}
					>
						<DoughnutSkeleton />
					</div>
				)}
				<div className="chart-item-container">
					{!load && (
						<React.Fragment>
							<Doughnut
								data={DoughnutData}
								height={230}
								width={230}
								options={{
									tooltips: {
										titleFontSize: 10,
										bodyFontSize: 10,
									},
									responsive: true,
									cutoutPercentage: 85,
									maintainAspectRatio: false,
									legend: {
										display: true,
										fullWidth: true
									},
									elements: {
										arc: {
											borderWidth: [1, 1],
										},
									},
								}}
							/>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}

const DoughnutSkeleton = () => {
	return (
		<React.Fragment>
			<Skeleton
				className="chart-item-container"
				circle={true}
				height={270}
				width={270}
			/>
		</React.Fragment>
	);
};

const style = {
	marginTop: {
		marginTop: "-1em",
	},
	chart: {
		float: 'left',
		width: '70%',
	}
};

