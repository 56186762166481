/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import FeatherIcon from "feather-icons-react";
import MessageAllPage from "../../backend/components/MessageAllPage";
import { NavLink } from "react-router-dom";
import api from "./../../http/global-vars";
import axios from "axios";
import Doc from "./DocService";
import PdfContainer from "./PdfContainer";
import baseUrl from "../../http/backend-base-url";
// import { Document, Page } from 'react-pdf';
import _ from "lodash";

export default class MyCertificatesV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      userType: window.localStorage.getItem("userType"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      isPageLoading: false,
      certificatHtml: "",
      can_generate_certificat: false,
      userInfos: [],
      moduleInfos: [],
      countBadge: 0,
      moduleName: [],
      isDateOver: false,
      countEvaluation: 0,
      data: {},
      certicats: [],
    };
  }

  componentDidMount() {
    var userID = this.state.userID;
    this.onGetApprenantCertificat(userID);
    this.onGetEvaluationByUser(userID);
    this.onGetConnectedUserInfos();
    this.onGetUserDoModules();
    // console.log('ok')
    let _userId = JSON.parse(localStorage.getItem("userID"));
    this._getCertificat(_userId);
  }

  _getCertificat = async (userID) => {
    let { api, config } = this.state;
    let url = `${api}createpdfcertificate/${userID}/`;
    try {
      const req = await axios.get(url, config);
      console.log("response certificat pdf==>", req);
      this.setState({ data: req["data"] });
      // console.log(this.state.data)
    } catch (error) {
      console.log(error.message);
    }
  };

  onGetApprenantCertificat = (userID) => {
    var api = this.state.api;
    var config = this.state.requestConfig;
    this.setState({
      isPageLoading: true,
    });

    axios
      .get(`${api}generatecertificate/${userID}/`, config)
      .then((response) => {
        console.log(response, "certificat");
        if (response.status === 200) {
          // console.log("response generate certificat",response)
          if (
            response.data.status === "success" &&
            response.data.message ===
              "il peut pas acceder au certificat pas assez de badges"
          ) {
            this.setState({
              can_generate_certificat: false,
            });
          } else if (
            response.data.status === "failure" &&
            response.data.message ===
              "Vous ne pouvez pas acceder au certificat la date de fin de la cohorte est passée"
          ) {
            this.setState({
              isDateOver: true,
            });
          } else {
            this.setState({
              can_generate_certificat: true,
              certificatHtml: response.data,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCertificatModule = async (id) => {
    let vals = [];
    let url = `${api}createpdfcertificatemodule/${this.state.userID}/${id}/`;
    await axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        vals.push(response?.data?.data);
        // console.log('all certificat', [...this.state.certicats, ...vals],_.uniqBy(
        //     [...this.state.certicats, ...vals],
        //     'id',
        //   ))
        this.setState({
          certicats: _.uniqBy([...this.state.certicats, ...vals], "id"),
        });
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };

  createPdf = (html) => Doc.createPdf(html);

  onGetConnectedUserInfos = () => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(api + "me/", config)
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            userInfos: response.data.data,
          });
        }
        // console.log(response.data.data)
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isPageLoading: false,
        });
      });
  };

  onGetUserDoModules = () => {
    var userID = this.state.userID;
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(`${api}usermodules/${userID}/`, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          var infos = response.data.data;
          this.setState({
            moduleInfos: infos,
          });

          var countBadge = 0;
          var moduleName = [];
          let moduleCertificate = infos?.filter(
            (item) => item?.module?.has_certicate === true
          );
          console.log("infos", moduleCertificate);
          if (moduleCertificate?.length) {
            moduleCertificate.forEach((item) => {
              this.getCertificatModule(item?.module?.id);
            });
          }
          infos.forEach((item) => {
            if (item.progression === 100) {
              if (item?.module?.has_certicate === false) {
                countBadge += 1;
                moduleName.push(item.module.name);
              }
            }
          });
          // this.setState({
          //     countBadge: 3,
          //     moduleName: ['Module 1 : Communication', 'Module 2 : L\'ethique au travail', 'Module 3 : La gestion de carriere']
          // })
          this.setState({
            countBadge: countBadge,
            moduleName: moduleName,
          });
          if (this.state.userInfos?.badge < countBadge) {
            const url = api + "user/" + parseInt(this.state.userID) + "/";
            axios
              .put(url, { badge: countBadge }, this.state.requestConfig)
              .then((response) => {
                console.log("response", response);
                this.onGetConnectedUserInfos();
              })
              .catch((err) => {
                console.log("err", err?.response);
              });
          }
          // console.log('count badge ',countBadge)
          // console.log('modules', moduleName)
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onGetEvaluationByUser = (userID) => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(`${api}evaluationbyuser/${userID}/`, config)
      .then((response) => {
        // console.log(response)
        if (response.status === 200 || response.status === 201) {
          this.setState({
            countEvaluation: response.data.length,
          });
          // console.log(response.data.length)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    var { moduleName, userInfos, isDateOver, certicats, data } = this.state;
    var countBadge = userInfos.badge;
    let Url = `${baseUrl}${data?.data?.url}`;
    let renderCertif = (
      <div className="row pdf-created-row">
        {/* <div style={{ marginBottom: '3em', marginTop: '3em' }}> */}
        <div className="col-md-6 offset-md-3 pdf-created-link-container">
          {/* <a style={style.download_pdf_} target="_blank" rel="noopener noreferrer" href={Url}> */}
          <a
            className="pdf-created-link"
            target="_blank"
            rel="noopener noreferrer"
            href={Url}
          >
            Télécharger votre attestation
          </a>
        </div>
        <div className="col-md-12 pdf-created-viewr-container">
          <iframe src={Url} style={style.ifram_} />
        </div>
      </div>
    );
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobile />
          </div>
        </div>

        <HeaderDashboard />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="col-xl-2 col-lg-3 col-md-12">
            <div
              className="navbar-vertical-customisation no-view-mobile"
              style={{ backgroundColor: "#009688" }}
            >
              <NavbarVertical />
            </div>
          </div>

          <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 content-position-after-header pdf-viewer-container">
            <div className="row content-routing-url m-b-20">
              <div className="lh-0">
                <NavLink className="routing-view-url" to="/tous-les-modules">
                  Modules
                </NavLink>
                <FeatherIcon
                  className="icon-routing-view-url"
                  icon="chevron-right"
                />
                <NavLink className="routing-view-url" to="#">
                  Mon Certificat
                </NavLink>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="container-fluid">
                  <div className="item-profile-badge-container">
                    <p className="text-header-quiz-card p-l-40 m-b-50">
                      Mes badges
                    </p>
                    <div className="row p-l-40">
                      <div className="col-md-12 dis-flex badges text-center">
                        {countBadge === 0 ? (
                          <div className="alert alert-info gfa-alert-info empty-badge-alert">
                            <div className="row empty-alert-row">
                              <div className="col-md-2 empty-icon-col">
                                <FeatherIcon
                                  icon="alert-circle"
                                  className="m-b-3 empty-alert-icon rotateIn"
                                />
                              </div>
                              <div className="col-md-10 empty-textual-col">
                                <p>
                                  Vous n'avez acquis aucun badge pour le moment.
                                </p>
                                <p className="info-label">
                                  Pour rappel chaque module de formation terminé
                                  vous donne droit à un badge.
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : countBadge === 1 ? (
                          <div className="row badge-row">
                            <div className="col-md-12 badge-col">
                              {moduleName.map((item, index) => (
                                <div
                                  className="badge-infos-container"
                                  key={`badgery${index}`}
                                >
                                  <div className="badge-module-name">
                                    {" "}
                                    {item}{" "}
                                  </div>
                                  <div className="badge-icon-container">
                                    <FeatherIcon
                                      icon="award"
                                      className="m-b-3 badge rotateIn badge-blue"
                                    />
                                  </div>
                                </div>
                              ))}
                              <div className="badge-motivation">
                                <span
                                  className="badge-emoticon"
                                  role="img"
                                  aria-label="force"
                                >
                                  &#128170;
                                </span>
                                <br />
                                <span className="badge-motivation-label">
                                  Du courage! Il ne vous reste plus que 2
                                  modules à terminer.
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : countBadge === 2 ? (
                          <div className="row badge-row">
                            {moduleName.map((item, index) => (
                              <div
                                className={"col-md-6 badge-col " + index}
                                key={`badgery${index}`}
                              >
                                <div className="badge-infos-container">
                                  <div className="badge-module-name">
                                    {" "}
                                    {item}{" "}
                                  </div>
                                  <div className="badge-icon-container">
                                    {index === 0 ? (
                                      <FeatherIcon
                                        icon="award"
                                        className={
                                          "m-b-3 badge rotateIn badge-blue"
                                        }
                                      />
                                    ) : (
                                      <FeatherIcon
                                        icon="award"
                                        className={
                                          "m-b-3 badge rotateIn badge-orange"
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                            <div className="badge-motivation">
                              <span
                                className="badge-emoticon"
                                role="img"
                                aria-label="reverse"
                              >
                                &#128579;
                              </span>
                              <br />
                              <span className="badge-motivation-label">
                                C'est renversant, quelle performance! Plus qu'un
                                seul module à boucler pour disposer de vos 3
                                badges.
                              </span>
                            </div>
                          </div>
                        ) : (
                          countBadge === 3 && (
                            <div className="row badge-row">
                              {moduleName.map((item, index) => (
                                <div
                                  className="col-md-4 badge-col"
                                  key={`badgery${index}`}
                                >
                                  <div className="badge-infos-container">
                                    <div className="badge-module-name">
                                      {" "}
                                      {item}{" "}
                                    </div>
                                    <div className="badge-icon-container">
                                      {index === 0 ? (
                                        <FeatherIcon
                                          icon="award"
                                          className={
                                            "m-b-3 badge rotateIn badge-blue"
                                          }
                                        />
                                      ) : index === 1 ? (
                                        <FeatherIcon
                                          icon="award"
                                          className={
                                            "m-b-3 badge rotateIn badge-orange"
                                          }
                                        />
                                      ) : (
                                        index === 2 && (
                                          <FeatherIcon
                                            icon="award"
                                            className={
                                              "m-b-3 badge rotateIn badge-green"
                                            }
                                          />
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="badge-motivation">
                                <span
                                  className="badge-emoticon"
                                  role="img"
                                  aria-label="clap-hand"
                                >
                                  &#128079;
                                </span>
                                &nbsp;
                                <span
                                  className="badge-emoticon"
                                  role="img"
                                  aria-label="clap-hand"
                                >
                                  &#128079;
                                </span>
                                &nbsp;
                                <span
                                  className="badge-emoticon"
                                  role="img"
                                  aria-label="clap-hand"
                                >
                                  &#128079;
                                </span>
                                &nbsp;
                                <br />
                                <span className="badge-motivation-label">
                                  Bravo! Tu as bien mérité tes badges.
                                </span>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {data?.data?.url ? renderCertif : null}
              {certicats?.length
                ? certicats?.map((item) => (
                    <div className="row pdf-created-row" key={item?.id}>
                      {!item?.url ? null : (
                        <>
                          <div className="col-md-6 offset-md-3 pdf-created-link-container">
                            <a
                              className="pdf-created-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${baseUrl}${item?.url}`}
                            >
                              Télécharger votre attestation
                            </a>
                          </div>
                          <div className="col-md-12 pdf-created-viewr-container">
                            <iframe
                              src={`${baseUrl}${item?.url}`}
                              style={style.ifram_}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  ))
                : null}

              {/* <Document file={this.state?.data?.data?.url}></Document> */}
              {/* {countBadge === 3 && userInfos.certificate === true && countEvaluation !== 0 ?
                                <PdfContainer createPdf={this.createPdf}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.certificatHtml }} />
                                </PdfContainer>
                                :
                                countBadge === 3 && userInfos.certificate === true && countEvaluation === 0 ?
                                    <div className="row no-evaluation-row">
                                        <div className="col-md-12 date-over-col">
                                            <div className="alert alert-info gfa-alert-info empty-badge-alert">
                                                <div className="row empty-alert-row">
                                                    <div className="col-md-2 empty-icon-col">
                                                        <FeatherIcon
                                                        icon="alert-circle"
                                                        className="m-b-3 empty-alert-icon rotateIn"
                                                        />
                                                    </div>
                                                    <div className="col-md-10 empty-textual-col">
                                                        <p>Vous ne pouvez plus avoir un certificat de participation.</p>
                                                        <p className="info-label">Vous devez au préalable remplir la fiche d'identification</p>
                                                        <div className="row eval-link-row">
                                                            <div className="col-md-6 eval-link-col">
                                                                <NavLink to="/fiche-evaluation" className="link-to-evaluation btn btn-primary">
                                                                    <FeatherIcon
                                                                        icon="save"
                                                                        className="m-b-3 eval-icon rotateIn"
                                                                    />&nbsp;
                                                                    Remplir la fiche d'évaluation
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                :
                                isDateOver === true &&
                                    <div className="row date-over-row">
                                        <div className="col-md-12 date-over-col">
                                            <div className="alert alert-info gfa-alert-info empty-badge-alert">
                                                <div className="row empty-alert-row">
                                                    <div className="col-md-2 empty-icon-col">
                                                        <FeatherIcon
                                                        icon="alert-circle"
                                                        className="m-b-3 empty-alert-icon rotateIn"
                                                        />
                                                    </div>
                                                    <div className="col-md-10 empty-textual-col">
                                                        <p>Vous ne pouvez plus avoir un certificat de participation.</p>
                                                        <p className="info-label">Pour information, la date de fin de votre cohorte est passée.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            } */}
            </div>
          </div>
        </div>
        {/* <div className="">
          <MessageAllPage />
        </div> */}
      </div>
    );
  }
}
const style = {
  ifram_: {
    width: "100%",
  },
  // download_pdf_: {
  //     top: '0',
  //     padding: '20px',
  //     borderRadius: '50px',
  //     background: '#f39200',
  //     border: '1px solid #f39200',
  //     color: ' #fff',
  //     fontWeight: '600'
  // }
};
