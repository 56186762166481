import React, { useEffect } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import CoursStartInfos from "../components/SessionStartInfos";
import LayoutCours from "../components/LayoutCours";
import api from "../../http/global-vars";
import MessageAllPage from "../../backend/components/MessageAllPage";

const FCSessionStart = () => {
  useEffect(() => {
    console.log("lala");
    const token = window.localStorage.getItem("userToken");

    if (!token || token === "") {
      window.location = "connexion";
    }
  }, []);

  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>

        <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 p-t-25 content-position-after-header">
          <div className="container-fluid padding-right-web">
            <div className="row">
              <div className="col-xl-9">
                <div className="card bg-white b-r-10 ">
                  <div className="col p-t-25">
                    <p className="h-dark f-f-work fs-18 fw-500">
                      Développement Web
                    </p>
                  </div>
                  <div className="ligne"></div>
                  <div className="content-cours-start">
                    <div
                      id="carouselCoursStart"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <LayoutCours />
                        </div>
                        <div className="carousel-item">
                          <LayoutCours />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 padding-right-web">
                <CoursStartInfos />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};

export default FCSessionStart;
