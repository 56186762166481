import React, { Component } from "react";
import { NavLink } from "react-router-dom";
// import HeaderAccueil from './components/HeaderAccueil';
import FeatherIcon from "feather-icons-react";
import MessageAllPage from "../backend/components/MessageAllPage";
import Recaptcha from "react-recaptcha";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../http/global-vars";
import moment from "moment";
import { CountryDropdown } from "react-country-region-selector";
export default class Inscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      isVerified: false,
      fields: {},
      date: new Date(),
      errors: {},
      registerInProgress: false,
      isSuccessRegister: false,
      isErrorRegister: false,
      is_empty_adress: false,
      is_empty_email: false,
      is_empty_name: false,
      is_empty_phone: false,
      is_empty_website: false,
      is_empty_first_name: false,
      is_empty_last_name: false,
      is_empty_password_confirmation: false,
      is_invalid_email: false,
      not_same_password: false,
      is_empty_date: false,
      isVerifiedEnd: false,
      is_empty_sexe: false,
      isOtherRegisterError: false,
      is_empty_nationnality: false,
      is_empty_niveau_etude: false,
      country: "",
      is_empty_checked: false,
    };
  }

  componentDidMount() {
    // this.onValidateFormData()
  }

  selectCountry = (val) => {
    console.log("val", val);
    let fields = this.state.fields;
    fields["nationnality"] = val;
    this.setState({ country: val, fields });
  };

  handledateChange = (e) => {
    let date = this.state.date;
    date = e;

    this.setState({ date });
  };

  handleChange = (e) => {
    let fields = this.state.fields;
    // console.log("target", e.target.checked);
    fields[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      fields[e.target.name] = e.target.value.toLowerCase();
    }
    if (e.target.name === "date_naissance") {
      fields[e.target.name] = moment(e.target.value).format("DD/MM/yyyy");
    }

    if (e.target.name === "cpu") {
      fields[e.target.name] = e.target.checked;
    }
    this.setState({ fields });
    // console.log(this.state.fields);
  };

  recaptchaLoaded = () => {
    // console.log("capcha successfully loaded");
  };

  handleSubscribe = (e) => {
    e.preventDefault();
    // console.log('ok1')
    if (this.onValidateFormData()) {
      // console.log('ok2')
      this.setState({
        registerInProgress: true,
      });

      var api = this.state.api;
      var data = this.state.fields;

      axios
        .post(api + "auth/register/", data)
        .then((response) => {
          this.setState({
            registerInProgress: false,
          });

          // console.log(response);
          if (response.status === 201) {
            toast.success(
              "Merci pour votre inscription. Veuillez confirmer votre email pour finaliser votre inscription. !"
            );
            this.setState({
              isSuccessRegister: false,
            });
            setTimeout(() => {
              window.location = "/connexion";
            }, 5000);

            //window.location = "connexion";
          } else {
            toast.error("Une erreur est survenue lors de votre inscription !");
            this.setState({
              isErrorRegister: true,
            });
            setTimeout(() => {
              this.setState({
                isErrorRegister: false,
              });
            }, 5000);
          }
        })
        .catch((error) => {
          console.log(error?.response);
          if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
          } else if (
            error?.response?.data.error?.email[0] &&
            error?.response?.data?.error?.email[0] ===
              "user with this email already exists."
          ) {
            //   console.log("Utilisateur existant")
            toast.error(
              "Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte ou vous connecter."
            );
            this.setState({
              registerInProgress: false,
              isExistUser: true,
            });
            setTimeout(() => {
              this.setState({
                isExistUser: false,
              });
            }, 10000);
          } else {
            toast.error("Une erreur est survenue lors de votre inscription !");
            this.setState({
              registerInProgress: false,
              isOtherRegisterError: true,
            });
            setTimeout(() => {
              this.setState({
                isOtherRegisterError: false,
              });
            }, 10000);
          }
        });
    }
  };

  verifyCallback = (response) => {
    // console.log("verify");
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  };

  onValidateFormData = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["adresse"]) {
      isValidForm = false;
      this.setState({
        is_empty_adress: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_adress: false,
        });
      }, 5000);
      errors["adresse"] = "Ce champ est obligatoire";
    }
    if (!fields["phone"]) {
      isValidForm = false;
      this.setState({
        is_empty_phone: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_phone: false,
        });
      }, 5000);
      errors["phone"] = "Ce champ est obligatoire";
    }
    if (!fields["email"]) {
      isValidForm = false;
      this.setState({
        is_empty_email: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_email: false,
        });
      }, 5000);

      errors["email"] = "Ce champ est obligatoire";
    }
    if (typeof fields["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        isValidForm = false;
        this.setState({
          is_invalid_email: true,
        });
        setTimeout(() => {
          this.setState({
            is_invalid_email: false,
          });
        }, 5000);

        errors["invalid_email"] = "Votre adresse email n'est pas valide";
      }
    }
    if (!fields["first_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_first_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_first_name: false,
        });
      }, 5000);
      errors["first_name"] = "Ce champ est obligatoire";
    }
    if (!fields["last_name"]) {
      isValidForm = false;
      this.setState({
        is_empty_last_name: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_last_name: false,
        });
      }, 5000);
      errors["last_name"] = "Ce champ est obligatoire";
    }

    if (!fields["password"]) {
      isValidForm = false;
      this.setState({
        is_empty_password: true,
      });
      errors["password"] = "Ce champ est obligatoire";
      setTimeout(() => {
        this.setState({
          is_empty_password: false,
        });
      }, 5000);
    }
    if (!fields["cpassword"]) {
      isValidForm = false;
      this.setState({
        is_empty_password_confirmation: true,
      });
      errors["cpassword"] = "Ce champ est obligatoire";
      setTimeout(() => {
        this.setState({
          is_empty_password_confirmation: false,
        });
      }, 5000);
    }
    if (typeof fields["password"] !== "undefined") {
      if (fields["password"] !== fields["cpassword"]) {
        isValidForm = false;
        this.setState({
          not_same_password: true,
        });
        errors["not_same_password"] =
          "Les deux mots de passe ne sont pas identiques";
        setTimeout(() => {
          this.setState({
            not_same_password: false,
          });
        }, 5000);
      }
    }

    if (!fields["date_naissance"]) {
      isValidForm = false;
      this.setState({
        is_empty_date: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_date: false,
        });
      }, 5000);
      errors["date"] = "Ce champ est obligatoire";
    }

    if (!fields["sexe"]) {
      isValidForm = false;
      this.setState({
        is_empty_sexe: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_sexe: false,
        });
      }, 5000);
      errors["sexe"] = "Ce champ est obligatoire";
    }

    if (!fields["nationnality"]) {
      isValidForm = false;
      this.setState({
        is_empty_nationnality: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_nationnality: false,
        });
      }, 5000);
      errors["nationnality"] = "Ce champ est obligatoire";
    }

    if (!fields["cpu"]) {
      isValidForm = false;
      this.setState({
        is_empty_checked: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_checked: false,
        });
      }, 5000);
      errors["cpu"] =
        "Vous devez accepter les Conditions Générales d'Utilisation.";
    }

    // if (!this.state.isVerified) {
    //   isValidForm = false;
    //   this.setState({
    //     isVerifiedEnd: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       isVerifiedEnd: false,
    //     });
    //   }, 5000);
    //   errors["isVerified"] =
    //     "Vous devez confirmer via le Captcha que vous n'êtes pas un robot.";
    // }

    this.setState({
      errors: errors,
    });

    console.log(isValidForm, errors);

    return isValidForm;
  };

  _onFocus = (e) => {
    e.currentTarget.type = "date";
  };

  render() {
    const { country } = this.state;
    return (
      <div className="p-t-10">
        <div className="dis-flex">
          <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 ml-auto mr-auto">
            <div className="card-connexions py-5">
              <p className="titre-connexion mb-lg-5">INSCRIVEZ-VOUS</p>

              <form id="login-form">
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="user" />
                    </span>
                  </div>
                  <input
                    name="first_name"
                    onChange={(e) => this.handleChange(e)}
                    id="prenom"
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Prénom"
                  />
                  {this.state.is_empty_first_name === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.first_name}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="user" />
                    </span>
                  </div>
                  <input
                    name="last_name"
                    onChange={(e) => this.handleChange(e)}
                    id="nom"
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Nom"
                  />
                  {this.state.is_empty_last_name === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.last_name}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="mail" />
                    </span>
                  </div>
                  <input
                    name="email"
                    onChange={(e) => this.handleChange(e)}
                    id="email"
                    type="email"
                    className="form-control input-with-icon"
                    placeholder="Adresse mail"
                  />
                  {this.state.is_empty_email === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.email}{" "}
                    </div>
                  )}
                  {this.state.is_invalid_email === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.invalid_email}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="phone" />
                    </span>
                  </div>
                  <input
                    name="phone"
                    onChange={(e) => this.handleChange(e)}
                    id="telephone"
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Téléphone"
                  />
                  {this.state.is_empty_phone === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.phone}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="home" />
                    </span>
                  </div>
                  <input
                    name="adresse"
                    onChange={(e) => this.handleChange(e)}
                    id="adresse"
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Adresse"
                  />
                  {this.state.is_empty_adress === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.adresse}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="calendar" />
                    </span>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => this.handleChange(e)}
                    name="date_naissance"
                    className="form-control input-with-icon"
                    placeholder="Date de naissance"
                    onFocus={this._onFocus}
                  />

                  {this.state.is_empty_date === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.date}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="users" />
                    </span>
                  </div>
                  <select
                    name="sexe"
                    onChange={(e) => this.handleChange(e)}
                    className="form-control input-with-icon "
                    required
                  >
                    <option value="" selected>
                      -- Sexe --
                    </option>
                    <option value="homme">homme</option>
                    <option value="femme">femme</option>
                  </select>
                  {this.state.is_empty_sexe === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.sexe}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="globe" />
                    </span>
                  </div>
                  <CountryDropdown
                    className="form-control input-with-icon "
                    defaultOptionLabel="-- Pays de résidence --"
                    value={country}
                    name="nationnality"
                    onChange={(val) => this.selectCountry(val)}
                  />
                  {this.state.is_empty_nationnality === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.nationnality}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="home" />
                    </span>
                  </div>
                  <input
                    name="school"
                    onChange={(e) => this.handleChange(e)}
                    id="school"
                    type="text"
                    className="form-control input-with-icon"
                    placeholder="Votre école de formation"
                  />
                </div>
                {/* <div className="input-group my-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text span-input-icon">
                                            <FeatherIcon className="icon-input" icon="award" />
                                        </span>

                                    </div>
                                    <select
                                        name="niveau_etude"
                                        onChange={(e) => this.handleChange(e)}
                                        className="form-control input-with-icon "
                                        required
                                    >
                                        <option value="" selected>
                                            -- Niveau d'étude --
                                        </option>
                                        <option value="BFEM">BFEM</option>
                                        <option value="BAC">BAC</option>
                                        <option value="BAC+1">BAC+1</option>
                                        <option value="BAC+2">BAC+2</option>
                                        <option value="BAC+3">BAC+3</option>
                                        <option value="BAC+4">BAC+4</option>
                                        <option value="BAC+5">BAC+5</option>
                                        <option value=">BAC+5"> {'>'}BAC+5</option>
                                    </select>
                                    {this.state.is_empty_niveau_etude === true && (
                                        <div className="alert alert-danger gfa-alert-danger">
                                            {" "}
                                            {this.state.errors.niveau_etude}{" "}
                                        </div>
                                    )}
                                </div> */}

                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    name="password"
                    onChange={(e) => this.handleChange(e)}
                    id="password"
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Mot de passe"
                  />
                  {this.state.is_empty_password === true && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.password}{" "}
                    </div>
                  )}
                </div>
                <div className="input-group my-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text span-input-icon">
                      <FeatherIcon className="icon-input" icon="lock" />
                    </span>
                  </div>
                  <input
                    name="cpassword"
                    onChange={(e) => this.handleChange(e)}
                    id="confirmationPassword"
                    type="password"
                    className="form-control input-with-icon"
                    placeholder="Confirmer mot de passe"
                  />
                  {this.state.is_empty_password_confirmation && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {" "}
                      {this.state.errors.cpassword}{" "}
                    </div>
                  )}
                  {this.state.not_same_password && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {this.state.errors.not_same_password}{" "}
                    </div>
                  )}
                </div>

                <div className="input-group my-2 dis-flex justify-content-center">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      onChange={(e) => this.handleChange(e)}
                      className="custom-control-input"
                      id="cgu"
                      name="cpu"
                    />

                    <label
                      className="custom-control-label text-to-cgu"
                      htmlFor="cgu"
                    >
                      J'ai lu et j’accepte les{" "}
                      <NavLink
                        className="link-to-cgu"
                        to="/politique-de-confidentialite"
                      >
                        Conditions Générales d'Utilisation
                      </NavLink>
                      .{" "}
                    </label>
                    {this.state.is_empty_checked && (
                      <div className="alert alert-danger gfa-alert-danger">
                        {" "}
                        {this.state.errors.cpu}{" "}
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="input-group my-4">
                  <Recaptcha
                    sitekey="6Ldh3PgZAAAAACWS9aKYEQW8RoSbdPLQbicY6a_D"
                    render="explicit"
                    hl="fr"
                    onloadCallback={this.recaptchaLoaded}
                    verifyCallback={this.verifyCallback}
                  />
                  {this.state.isVerifiedEnd && (
                    <div className="alert alert-danger gfa-alert-danger">
                      {this.state.errors.isVerified}{" "}
                    </div>
                  )}
                </div> */}

                {!this.state.registerInProgress ? (
                  <button
                    onClick={(e) => this.handleSubscribe(e)}
                    className="btn-connexion mt-2"
                  >
                    INSCRIPTION
                  </button>
                ) : (
                  <button className="btn-connexion in-progress-btn mt-2">
                    INSCRIPTION EN COURS &nbsp;
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
              </form>
              <p className="mt-5 footer-text-form">
                Déjà inscrit ?{" "}
                <NavLink className="footer-link-form" to="/connexion">
                  Se connecter
                </NavLink>
              </p>
            </div>
          </div>
        </div>
        {this.state.isSuccessRegister ||
        this.state.isErrorRegister ||
        this.state.isOtherRegisterError ? (
          <ToastContainer />
        ) : (
          ""
        )}
        {/* <div className="">
          <MessageAllPage fullWidth={true} />
        </div> */}
      </div>
    );
  }
}
