import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import api from "./../../http/global-vars";
import axios from "axios";
import Suggestions from "./Suggestions";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      data: [],
      query: "",
      results: [],
      searching: false,
    };
  }

  componentDidMount() {
    //this.getInfos()
  }

  handleInputChange = () => {
    this.setState(
      {
        query: this.search.value,
      },
      () => {
        if (this.state.query && this.state.query.length > 1) {
          if (this.state.query.length % 2 === 0) {
            this.getInfos();
          }
        } else if (!this.state.query) {
          this.setState({
            results: [],
            searching: false,
          });
        }
      }
    );
  };

  getInfos = () => {
    let { api } = this.state;
    let url = api + `module/`;
    axios.get(`${url}`, this.state.requestConfig).then(({ data }) => {
      console.log(data);
      var results = [];
      data.results.forEach((item) => {
        results.push(item.name);
      });
      console.log(results);
      this.setState({
        results: data.results,
        searching: true,
      });
    });
  };

  render() {
    return (
      <div className="s130">
        <form action="" className="w-full">
          <div className="inner-form">
            <div className="input-field first-wrap">
              <div className="svg-wrapper">
                <FeatherIcon className="icon-search-dashboard" icon="search" />
              </div>
              <input
                id="search"
                type="text"
                placeholder="Chercher un module ..."
                ref={(input) => (this.search = input)}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
            </div>
          </div>
          {this.state.searching === true && (
            <Suggestions
              results={this.state.results}
              query={this.state.query}
            />
          )}
        </form>
      </div>
    );
  }
}
