import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Copyright from "./Copyright";

export default class MessageAllPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="tw-h-[100px]"></div>;
  }
}
