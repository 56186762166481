import React, { Component } from "react";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import NavbarDashboardMobileAdmin from "./../../backend/components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./../../backend/components/HeaderDashboard";
import FCNavbarVerticalAdmin from "../../backend/components/FCNavbarVertical";
import CardReponseForum from "../components/CardReponseForum";
import FeatherIcon from "feather-icons-react";
import api from "../../http/global-vars";
import { formatDate } from "../../utils";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import $ from "jquery";
import ResponseCard from "../components/ResponseCard";
import ForumDetailSkeleton from "../components/ForumDetailSkeleton";
import ResponseCardSkeleton from "../components/ResponseCardSkeleton";
import Skeleton from "react-loading-skeleton";
import MessageAllPage from "../../backend/components/MessageAllPage";
import baseUrl from "../../http/backend-base-url";
import PaginationComponent from "../../modules/Pagination/Pagination";

class ForumDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      userType: window.localStorage.getItem("userType"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      forumId: this.props.match.params.id,
      loading: false,
      forum: {
        created_at: "",
        creator_id: {},
        description: "",
        name: "",
        session: "",
      },
      fields: {},
      errors: {},
      sendMessageInProgress: false,
      isSuccessSendingMessage: false,
      isErrorSendingMessage: false,
      is_empty_message: false,
      messages: [],
      // messagePerpage: 5,
      // currentPage: 1,
      // indexOfLastAnnonce: 0,
      // indexOfFirstAnnonce: 0,
      data: [],
      per_page: 20,
      current_page: 1,
      total_data: "",
      currentList: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const timer = setTimeout(() => {
      this.getForumById();
      this.setState({ loading: false });
    }, 5000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

  getForumById = () => {
    const url = `${api}forum/${this.state.forumId}`;
    axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        // console.log("response", response.data);
        this.setState({
          forum: response.data,
        });
        this.loadResponseByForum(this.state.forumId);
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  loadResponseByForum = (id) => {
    const url = `${api}messageByForum/${id}`;
    axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        // console.log("message", response.data);
        this.setState({
          messages: response.data,
          data: response.data,
          total_data: response.data.length,
        });
        this.formatData();
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  handleChangeSendMessage = (e) => {
    let fields = this.state.fields;
    fields["creator_id"] = this.state.userID;
    fields["forum_id"] = this.state.forumId;
    fields[e.target.name] = e.target.value;

    this.setState({
      fields: fields,
    });

    // console.log(fields);
  };

  onValidateSendingMessageForm = () => {
    let fields = this.state.fields;
    let errors = this.state.errors;
    var isValidForm = true;

    if (!fields["message"]) {
      isValidForm = false;
      this.setState({
        is_empty_message: true,
      });
      setTimeout(() => {
        this.setState({
          is_empty_message: false,
        });
      }, 5000);
      errors["message"] = "Ce champ est obligatoire";
    }

    this.setState({
      errors: errors,
    });

    return isValidForm;
  };

  onSubmitResponseForm = (e) => {
    e.preventDefault();

    if (this.onValidateSendingMessageForm()) {
      this.setState({
        sendMessageInProgress: true,
      });

      var api = this.state.api;
      var config = this.state.requestConfig;
      var data = this.state.fields;

      axios
        .post(api + "message/", data, config)
        .then((response) => {
          this.setState({
            sendMessageInProgress: false,
          });

          // console.log(response);

          if (response.status === 201) {
            this.loadResponseByForum(this.state.forumId);
            toast.success("Votre message a été bien envoyé!");
            // $('.contactSupportModal').modal('hide')
            $(".close").click();
            this.setState({
              isSuccessSendingMessage: true,
            });
            setTimeout(() => {
              this.setState({
                isSuccessSendingMessage: false,
              });
            }, 5000);
          } else {
          }
        })
        .catch((error) => {
          toast.error(
            "Oupss! Une erreur est survenue lors de l'envoi de votre réponse. Veuillez réessayer plus tard."
          );
          console.log(error);
          this.setState({
            sendMessageInProgress: false,
            isErrorSendingMessage: true,
          });
          setTimeout(() => {
            this.setState({
              isErrorSendingMessage: false,
            });
          }, 5000);
        });
    }
  };

  paginate = (event) => {
    event.preventDefault();
    this.setState({ currentPage: Number(event.target.textContent) });
  };

  formatData() {
    const indexOfLastPost = this.state.current_page * this.state.per_page;
    const indexOfFirstPage = indexOfLastPost - this.state.per_page;

    const currentList = this.state.data.slice(
      indexOfFirstPage,
      indexOfLastPost
    );

    this.setState({ currentList });
  }

  handleClick = (number) => {
    this.setState(
      {
        current_page: number,
      },
      () => {
        this.formatData();
      }
    );
  };

  render() {
    const {
      token,
      forum,
      messages,
      per_page,
      total_data,
      current_page,
      currentList,
      loading,
    } = this.state;
    //console.log('detail', currentList)
    if (token === null || token === undefined || token === "") {
      window.location = "/connexion";
    } else {
      // console.log("params", formatDate(forum.created_at));
      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              {this.state.userType === "student" ? (
                <NavbarDashboardMobile />
              ) : (
                <NavbarDashboardMobileAdmin />
              )}
            </div>
          </div>

          {this.state.userType === "student" ? (
            <HeaderDashboard />
          ) : (
            <HeaderDashboardAdmin />
          )}

          <div className="row">
            {/* Navbar Desktop  */}
            {/* <div className="col-xl-2 col-lg-3 col-md-12">
              <div className="navbar-vertical-customisation no-view-mobile">
                {this.state.userType === 'student' ?
                  <NavbarVertical /> :
                  <NavbarVerticalAdmin />
                }
              </div>
            </div> */}

            {this.state.userType !== "student" && (
              <>
                {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
                <div className="nav-container">
                  <div
                    className="navbar-vertical-customisation no-view-mobile"
                    style={{ top: "40px", zIndex: 98 }}
                  >
                    <FCNavbarVerticalAdmin />
                  </div>
                </div>

                <div className="col-md-2"></div>
              </>
            )}

            {this.state.userType !== "student" && (
              <div className="col-xl-2 col-lg-3 col-md-12">
                <div className="navbar-vertical-customisation no-view-mobile">
                  <NavbarVertical />
                </div>
              </div>
            )}

            <div className="col-xl-10 col-lg-9 col-md-12 p-b-200 p-t-25 content-position-after-header">
              <div className="container-fluid">
                <div className="card bg-white b-r-10">
                  <div className="content-header-forum-details">
                    {/**Loading Skeleton */}
                    {loading ? (
                      <div className="row">
                        <div className="col-xl-9 col-lg-7 col-md-8 dis-flex">
                          <div className="avatar-header-forum-details">
                            <Skeleton circle={true} height={50} width={50} />
                          </div>
                          <div>
                            <h5 className="h-theme-light f-f-poppins">
                              <Skeleton height={26} width={90} />
                            </h5>
                            <div className="flex-row">
                              <p className="fs-12 fw-300 f-f-poppins m-r-15 text-black">
                                <Skeleton width={80} />
                                &nbsp;&nbsp;
                                <span className="fw-600">
                                  <Skeleton width={90} />
                                </span>
                              </p>
                              <p className="fs-12 fw-300 f-f-poppins text-black m-b-15">
                                <Skeleton width={80} />
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-5 col-md-4 dis-flex justify-content-end containt-mobile-center">
                          <div className="btn-add-forum m-b-22">
                            <Skeleton
                              className="icon-btn-add-forum"
                              width={20}
                            />
                            <Skeleton width={90} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-xl-9 col-lg-7 col-md-8 dis-flex">
                          <div>
                            <img
                              className="avatar-header-forum-details"
                              src={baseUrl + forum.creator_id.avatar}
                              alt=""
                            />
                          </div>
                          <div>
                            <h5 className="h-theme-light f-f-poppins">
                              {forum.name}
                            </h5>
                            <div className="flex-row">
                              <p className="fs-12 fw-300 f-f-poppins m-r-15 text-black">
                                Par{" "}
                                <span className="fw-600">
                                  {forum.creator_id.first_name}{" "}
                                  {forum.creator_id.last_name}
                                </span>
                              </p>
                              <p className="fs-12 fw-300 f-f-poppins text-black m-b-15">
                                Le {formatDate(forum.created_at)}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-5 col-md-4 dis-flex justify-content-end containt-mobile-center">
                          {this.state.userType === "student" && (
                            <button
                              type="button"
                              className="btn-add-forum m-b-22"
                              data-toggle="modal"
                              data-target=".modalAddCommentaire"
                            >
                              <FeatherIcon
                                className="icon-btn-add-forum"
                                icon="message-square"
                              />
                              Ajouter une réponse
                            </button>
                          )}
                          <div
                            className="modal fade modalAddCommentaire"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="myLargeModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-lg">
                              <div className="modal-content modal-card">
                                <div className="row m-b-35">
                                  <div className="col-10 itm-center">
                                    <p className="title-modal">Message</p>
                                  </div>
                                  <div className="col-2">
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">
                                        <FeatherIcon icon="x" />
                                      </span>
                                    </button>
                                  </div>
                                </div>

                                <form>
                                  <div className="form-group">
                                    <textarea
                                      className="input-modal p-t-15"
                                      rows="7"
                                      placeholder="Votre message ..."
                                      name="message"
                                      onChange={(e) =>
                                        this.handleChangeSendMessage(e)
                                      }
                                    ></textarea>
                                    {this.state.is_empty_message && (
                                      <div className="alert alert-danger gfa-alert-danger">
                                        {" "}
                                        {this.state.errors.message}{" "}
                                      </div>
                                    )}
                                  </div>

                                  <div className="dis-flex justify-content-end containt-mobile-center m-t-30">
                                    {this.state.sendMessageInProgress ===
                                    false ? (
                                      <button
                                        type="submit"
                                        onClick={(e) =>
                                          this.onSubmitResponseForm(e)
                                        }
                                        className="btn-theme p-l-90 p-r-90"
                                      >
                                        ENVOYER
                                      </button>
                                    ) : (
                                      <button className="btn-theme p-l-90 p-r-90">
                                        Envoi en cours &nbsp;
                                        <i className="fas fa-spin fa-spinner"></i>
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/**END Loading Skeleton */}
                  </div>

                  <div className="ligne m-t-0"></div>

                  <div className="content-body-forum-details">
                    {/**Forum Detail Skeleton */}
                    {loading ? (
                      <>
                        <ForumDetailSkeleton />
                        <ResponseCardSkeleton />
                      </>
                    ) : (
                      <>
                        <CardReponseForum
                          name={forum.name}
                          description={forum.description}
                          creator_id={forum.creator_id}
                          created_at={formatDate(forum.created_at)}
                        />
                        {currentList
                          .reverse()
                          .slice()
                          .map((message) => {
                            return (
                              <ResponseCard
                                key={message.id}
                                id={message.id}
                                message={message.message}
                                first_name={message.creator_id.first_name}
                                last_name={message.creator_id.last_name}
                                avatar={message.creator_id.avatar}
                                created_at={formatDate(message.created_at)}
                                response={message.responses}
                                forumId={this.state.forumId}
                                loadResponseByForum={this.loadResponseByForum}
                              />
                            );
                          })}
                        {/* <nav aria-label="Page navigation example">
                          <ul className="pagination pagination-circle pg-white">
                            {pageNumbers !== undefined
                              ? renderPageNumbers
                              : null}
                          </ul>
                        </nav> */}
                        <PaginationComponent
                          per_page={per_page}
                          current_page={current_page}
                          total_data={total_data}
                          handleClick={this.handleClick}
                        />
                      </>
                    )}
                    {/**END Forum Detail Skeleton */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.isSuccessSendingMessage ||
          this.state.isErrorSendingMessage ? (
            <ToastContainer />
          ) : (
            ""
          )}

          <div className="">
            <MessageAllPage />
          </div>
        </div>
      );
    }
  }
}

export default ForumDetail;
