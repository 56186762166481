import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../http/global-vars";
// import $ from 'jquery'
import axios from "axios";
// import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

toast.configure();

class ModalAddTitleContenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: api,
      fields: {
        download: props.item?.download || false,
        progression: props.item?.progression || 0,
        order: props.item?.order || 0,
        title: props.item?.title || "",
        content_type: props.item?.content_type || "",
        message: props.item?.message || "",
      },
      item: props.item,
      load: false,
      errors: [],
      module: this.props.module_id,
      session: this.props.session_id,
    };
  }

  handleChange = (e) => {
    let fields = { ...this.state.fields };
    if (["download"].includes(e.target.name)) {
      fields[e.target.name] = e.target.checked;
    } else {
      fields[e.target.name] = e.target.value;
    }
    this.setState({ fields: fields });
  };

  convertBool(bool) {
    if (bool === true) {
      return 1;
    } else {
      return 0;
    }
  }

  hasErrorFor = (field) => {
    return !!this.state.errors[field];
  };

  renderErrorFor = (field) => {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  };

  notify = () => {
    if (this.state.item) {
      this.setState({
        load: false,
      });
    } else {
      this.setState({
        load: false,
        fields: {
          download: false,
          progression: 0,
          order: 0,
          title: "",
          content_type: "",
          message: "",
        },
      });
    }
    if (this.props.onDone) {
      this.props.onDone();
    }

    Swal.fire({
      icon: "success",
      title: `Contenu ${this.state.item ? "modifié" : "ajouté"} avec succès`,
      iconColor: "green",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  isValid() {
    let msg = "Ce champ est requis";
    const errors = {};
    if (!this.state.fields.title) errors.title = [msg];
    if (!this.state.fields.content_type) errors.content_type = [msg];
    if (this.state.fields.progression === undefined) errors.progression = [msg];
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  }

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ load: true, errors: {}, redirect: false });
    let { api, fields } = this.state;
    let data = { ...fields };
    if (!this.isValid()) {
      this.setState({ load: false });
    } else {
      try {
        if (!this.props.session_id) {
          data.module = this.props.module_id;
        } else {
          data.module = null;
          data.session = this.props.session_id;
        }
        if (this.state.item) {
          await axios.put(`${api}contenu/${this.state.item.id}/`, data, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          });
        } else {
          await axios.post(api + "contenu/", data, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          });
        }
        this.notify();
      } catch (error) {
        this.setState({ load: !this.state.load });
        let err = error.response;
        if (err) {
          // console.log(error.response.data)
          let status = error.response.status;
          let data = error.response.data;
          if (status === 400) {
            let error = {};
            for (let [key, value] of Object.entries(data)) {
              error = {
                [key]: [value],
              };
              this.setState({ errors: error });
            }
            Swal.fire({
              icon: "error",
              title: "Oupps un probleme de connection!",
              iconColor: "red",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }
    }
  };

  render() {
    let { load } = this.state;
    return (
      <div>
        <h5 className="text-black m-b-50">
          {this.state.item ? "Modifier" : "Ajouter"} un contenu
        </h5>
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  name="title"
                  onChange={this.handleChange}
                  value={this.state.fields.title}
                  className={`form-control input-dashboard-backend text-black ${
                    this.hasErrorFor("title") ? "is-invalid" : ""
                  }`}
                  placeholder="Titre du contenu"
                />
                {this.renderErrorFor("title")}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  name="content_type"
                  onChange={this.handleChange}
                  value={this.state.fields.content_type}
                  className={`form-control input-dashboard-backend text-black ${
                    this.hasErrorFor("content_type") ? "is-invalid" : ""
                  }`}
                >
                  <option value={null}>--Choisir un type de contenu--</option>
                  <option value="slide">Slide</option>
                  <option value="doc">Document</option>
                  <option value="podcast">Podcast</option>
                  <option value="video">Video</option>
                  <option value="multiple_choice">Choix multiples</option>
                  <option value="yes_no">Vrai/Faux</option>
                  <option value="image">Quizz Image</option>
                  <option value="text_field">Champ de texte</option>
                  <option value="click_reveal">Click/Reveal</option>
                  <option value="upload">Upload</option>
                  <option value="texte_trous">Texte à trou</option>
                  <option value="drag_drop">Glisser/Déposer</option>
                  <option value="scale">Scale</option>
                </select>
                {this.renderErrorFor("content_type")}
              </div>
            </div>
            {this.renderNumberField("progression", "Taux de progression")}
            {this.renderNumberField("forum", "Forum (identifiant de sujet)")}
            {this.renderNumberField("order", "Ordre")}
            {this.renderNumberField(
              "a_afficher_sur",
              "A afficher sur ? (identifiant de contenu)"
            )}
            {this.renderBooldField("download", "Téléchargeable ?")}
            <div className="col-md-12">
              <textarea
                placeholder="Message en cas de succès"
                className="form-control mt-2 mb-2"
                value={this.state.fields.message}
                onChange={(e) => {
                  this.setState({
                    fields: { ...this.state.fields, message: e.target.value },
                  });
                }}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="row dis-flex justify-content-end">
                <div className="col-lg-6 col-md-12">
                  {!load ? (
                    <button className="btn-submit-form">
                      {this.state.item ? "Modifier" : "Ajouter"}
                    </button>
                  ) : (
                    <button className="btn-submit-form">
                      Chargement... &nbsp;
                      <i className="fas fa-spin fa-spinner"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
  renderBooldField(name, label) {
    return (
      <div className="col-sm-6 col-md-3 m-b-15">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            name={name}
            id={name}
            checked={this.state.fields[name]}
            onChange={this.handleChange}
            className=""
          />{" "}
          <label className="" htmlFor={name}>
            {label}
          </label>
        </div>
      </div>
    );
  }
  renderNumberField(name, label) {
    return (
      <div className="col-sm-6 col-md-4">
        <div className="form-group">
          <input
            type="number"
            min={0}
            name={name}
            onChange={this.handleChange}
            value={this.state.fields[name]}
            className={`form-control input-dashboard-backend text-black ${
              this.hasErrorFor(name) ? "is-invalid" : ""
            }`}
            placeholder={label}
          />
          {this.renderErrorFor(name)}
        </div>
      </div>
    );
  }
}

export default ModalAddTitleContenu;
// export default withRouter(ModalAddTitleContenu);
