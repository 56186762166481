import React, { Component } from "react";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarVertical from "../components/NavbarVertical";
// import FeatherIcon from "feather-icons-react";
// import MessageAllPage from "../../backend/components/MessageAllPage";
import SessionStartInfos from "../components/SessionStartInfos";
import IntroSliderContentDetailSession from "../components/IntroSliderContentDetailSession";
import TacheQuestionReponse from "../components/TacheQuestionReponse";
// import SliderComponentContentDetailSession from "../components/SliderComponentContentDetailSession ";
import TacheQuiz from "../components/TacheQuiz";
import TacheVraiFaux from "../components/TacheVraiFaux";
import TacheClickAndReveal from "../components/TacheClickAndReveal";
import TacheTextATroux from "../components/TacheTextATroux";
import TacheGlisserDeposer from "../components/TacheGlisserDeposer";
import TacheEvaluation from "../components/TacheEvaluation";
import ChooseImage from "../components/ChooseImage";
import TacheCarteHeuristiqueV2 from "../components/TacheCarteHeuristiqueV2";
import api from "../../http/global-vars";
import axios from "axios";
import LayoutCours from "../components/LayoutCours";
import TacheVideo from "../components/TacheVideo";
import TacheUpload from "../components/TacheUpload";
import SlideSkeleton from "../components/SlideSkeleton";
import $ from "jquery";
import ImageZoomableModal from "../components/ImageZoomableModal";

export default class SliderContentDetailsSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsId: this.props.match.params.id,
      paramsComponent: this.props.match.params.component,
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      selectedIndex: 0,
      contenu: [],
      moduleId: null,
      isEndedSlide: false,
      sessions: [],
      sessionsModule: [],
      modules: [],
      usermodules: [],
      usersessions: [],
      usercontenus: [],
      loading: false,
      contenu_done: [],
      todo_contenu: [],
      contenuByModule: [],
      id_contenu: "",
      store: JSON.parse(window.localStorage.getItem("resp_gfa")) || [],
      new_store: [],
      str: "",
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 60,
      left: 0,
    });
    this.setState({ loading: true });
    this.getAllModule();
    setTimeout(() => {
      this.ContenusByUser();
    }, 1500);

    /*  $('#carouselSliderContentDetailsSession').mouseover({
      scroll: true,
      scrollSensitivity: 40,
      scrollSpeed: 40
      }); */
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id_contenu !== this.state.id_contenu) {
      console.log("update data store");
      // console.log("effet update");
      if (this.state.new_store?.length) {
        this.setState({ store: this.state?.new_store });
      }
    }
    if (prevState.str !== this.state.str) {
      // console.log("update data store");
      this.setState({ store: this.state?.new_store });
    }
  }

  /* componentWillMount() {
    this.getContenuByParams();
  } */

  checkitem = () => {
    var $this = $("#carouselSliderContentDetailsSession");
    if ($(".carousel-inner .carousel-item:first").hasClass("active")) {
      console.log("first");
      $this.children(".left.carousel-control").hide();
    } else if ($(".carousel-inner .carousel-item:last").hasClass("active")) {
      console.log("last");
      $this.children(".right.carousel-control").hide();
    } else {
      $this.children(".carousel-control").show();
    }
  };

  NextSlide = (index) => {
    console.log("quizEnd", index);
  };

  getContenuByParams = async () => {
    const { paramsComponent, paramsId, requestConfig } = this.state;
    if (this.state.paramsComponent === "module") {
      const url = api + "contenubymodule/" + paramsId;
      // console.log("url module", url);
      await axios.get(url, requestConfig).then(async (response) => {
        //console.log('contenu By module', response.data);
        var result = response.data.map((a) => a);
        var result2 = this.state.usercontenus.map((a) => a.contenu.id);
        let todo_contenu = result.filter((val) => result2.indexOf(val?.id) < 0);
        if (!todo_contenu.length) {
          this.getSessionByModule(paramsId);
          this.setState({ contenuByModule: response.data });
        } else {
          console.log("object", todo_contenu);
          this.setState({ contenu: todo_contenu });
          this.checkContenu();
        }
        this.setState({ moduleId: paramsId });
        //this.getSessionByModule();
      });
    }
    if (paramsComponent === "session") {
      const url = api + "contenubysession/" + paramsId;
      //console.log("url session", url);
      await axios.get(url, requestConfig).then((response) => {
        //console.log("contenu By session", response.data);
        if (response.data) {
          // console.log("contenu By session", response.data);

          this.setState({ contenu: response.data, loading: false });

          axios
            .get(api + "session/" + paramsId, requestConfig)
            .then((data) => {
              console.log("data session", data);
              this.setState({
                contenu: response.data,
                moduleId: data.data.module_id,
              });
              this.getSessionByModule(data.data.module_id);
            })
            .catch((e) => {
              console.log("e", e.response);
            });
          this.getAllSession();
        }
        this.checkContenu();
      });
    }
    //this.checkContenu()
    this.getAllSession();
    this.userDoModule();
    this.userDoSession();
  };

  getContenuByAllSession = (id) => {
    const url = api + "contenubysession/" + id;
    axios.get(url, this.state.requestConfig).then((response) => {
      // console.log("contenu By session", response.data);
      var result = response.data.map((a) => a);
      var result2 = this.state.usercontenus.map((a) => a.contenu.id);
      let todo_contenu = result.filter((val) => result2.indexOf(val?.id) < 0);

      if (!todo_contenu.length) {
        if (this.state.selectedIndex !== this.state.sessions.length - 1) {
          this.setState((prevState) => ({
            selectedIndex: prevState.selectedIndex + 1,
          }));
          this.getSessionByModule();
        } else {
          // console.log('end', this.state.selectedIndex)
          this.setState({ contenu: this.state.contenuByModule });
          this.checkContenu();
        }
        // console.log(this.state.selectedIndex)
      } else {
        this.setState({ contenu: todo_contenu });
        this.checkContenu();
      }

      // this.checkContenu()

      // this.setState({ loading: false  });
      // for (let i = 0; i < response.data.length; i++) {
      //   const element = response.data[i];
      // }
    });
  };

  getSessionByModule = (id) => {
    if (this.state.paramsComponent === "module") {
      const url = api + "sessionByModule/" + this.state.paramsId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          this.setState({
            sessionsModule: response.data?.sort((a, b) => a?.id - b?.id),
          });
          // console.log('session by module', this.state.sessions)
          this.getContenuByAllSession(
            response.data?.sort((a, b) => a?.id - b?.id)[
              this.state.selectedIndex
            ]?.id
          );
          //this.checkContenu()
        })
        .catch((e) => {
          console.log("e", e);
        });
    } else {
      const url = api + "sessionByModule/" + id;
      if (id && id !== undefined) {
        axios
          .get(url, this.state.requestConfig)
          .then((response) => {
            this.setState({
              sessionsModule: response.data?.sort((a, b) => a?.id - b?.id),
            });
            console.log("session by module", response.data);
            this.getContenuByAllSession(
              response.data?.sort((a, b) => a?.id - b?.id)[
                this.state.selectedIndex
              ]?.id
            );
            //this.checkContenu()
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
    }
  };

  getAllSession = () => {
    const url = api + "session";
    axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        //console.log("session", response.data.results);
        this.setState({ sessions: response.data.results });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  getAllModule = () => {
    const url = api + "module";
    axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        //console.log("module", response.data.results);
        this.setState({ modules: response.data.results });
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  ContenusByUser = () => {
    const url = api + "usercontenus/" + parseInt(this.state.userID) + "/";
    axios
      .get(url, this.state.requestConfig)
      .then((data) => {
        this.setState({ usercontenus: data.data });
        // console.log("data", this.state.usercontenus);
        this.getContenuByParams();
        //this.userDoSession()
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  checkContenu = async () => {
    var result = await this.state.contenu.map((a) => a);
    // result.sort((a, b) => a.id -b.id);
    console.log("ok");
    var result2 = [];
    var contenu_done = [];
    var todo_contenu = [];
    if (this.state.usercontenus.length > 0) {
      result2 = await this.state.usercontenus.map((a) => a.contenu.id);
      //console.log('result',result.filter((val) => result2.indexOf(val.id) < 0))
      contenu_done = await result.filter((val) => result2.indexOf(val.id) >= 0);
      todo_contenu = await result.filter((val) => result2.indexOf(val.id) < 0);
    } else {
      //console.log('result')
      contenu_done = await this.state.contenu;
      todo_contenu = await this.state.contenu;
    }
    await todo_contenu.sort((a, b) =>
      a.session ? a.session - b.session : a.module - b.module
    );

    this.setState({ todo_contenu, contenu_done, loading: false });
    // console.log(todo_contenu, contenu_done)
    // return false;
  };

  userDoSession = () => {
    const url = api + "usersessions/" + parseInt(this.state.userID) + "/";
    axios
      .get(url, this.state.requestConfig)
      .then((data) => {
        //console.log('session user',data.data);
        this.setState({ usersessions: data.data });
        //this.userDoModule()
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  };

  userDoModule = () => {
    const url = api + "usermodules/" + parseInt(this.state.userID) + "/";
    axios
      .get(url, this.state.requestConfig)
      .then((data) => {
        //console.log("module user", data.data.data);
        this.setState({ usermodules: data.data.data });
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  };

  callSomeEffect = (store, id_contenu, str) => {
    console.log("effect component change");
    console.log(id_contenu);
    console.log(store);
    this.setState({ id_contenu: id_contenu, new_store: store, str: str });
    // console.log(this.state.id_contenu)
  };

  // callQuizEffect=(store,qz)=>{
  //   console.log( "effect component change");
  //   console.log(store);
  //   this.setState({ str_text: qz, new_store: store });
  // }

  render() {
    // console.log("contenuxxxxx", this.state.sessions.filter(s => s?.module_id === this.state.moduleId));
    // console.log("contenuxxxxx", this.state.contenu);
    // if (this.state.contenu.length !== 0) {
    //   console.log("contenu with a afficher", this.state.contenu.filter((f) => f.a_afficher_sur !== 0));
    // }

    let contenu = [];
    if (this.state.todo_contenu.length) {
      //console.log("contenuxxxxx");
      contenu = this.state.todo_contenu;
    } else {
      contenu = this.state.contenu_done;
    }
    // console.log(contenu)
    return (
      <div className="container-fluid body-background-dashboard">
        <div className="row">
          {/* Navbar Mobile */}
          <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
            <NavbarDashboardMobile />
          </div>
        </div>

        <HeaderDashboard />

        <div className="row">
          {/* Navbar Desktop  */}
          <div className="col-xl-2 col-lg-3 col-md-12">
            <div
              className="navbar-vertical-customisation no-view-mobile"
              style={{ backgroundColor: "#009688" }}
            >
              <NavbarVertical />
            </div>
          </div>

          <div className="col-xl-10 col-lg-9 col-md-12 content-position-after-header p-b-200">
            <div className="test">
              <div className="row">
                <div className="col-xl-9">
                  <div className="content-slider-details-session">
                    {!this.state.loading && contenu?.length ? (
                      <>
                        <div className="header-content-slider-details-session ">
                          {/* <a
                            className="position-btn-left-slider-details-session"
                            href="#carouselSliderContentDetailsSession"
                            role="button"
                            data-slide="prev"
                          >
                            <FeatherIcon
                              className="chevron-header-content-slider-details-session"
                              icon="chevron-left"
                            />
                            <span className="">Précédent</span>
                          </a> */}
                          <div className="dis-flex">
                            <img
                              className="icon-header-content-slider-details-session"
                              src="/img/icons/slider-icon-session.png"
                              alt=""
                            />
                            <p className="texte-header-content-slider-details-session">
                              Slide
                            </p>
                          </div>
                          {/* <a
                            className="position-btn-right-slider-details-session"
                            href="#carouselSliderContentDetailsSession"
                            role="button"
                            data-slide="next"
                          >
                            <span className="">Suivant</span>
                            <FeatherIcon
                              className="chevron-header-content-slider-details-session"
                              icon="chevron-right"
                            />
                          </a>  */}
                        </div>
                        <div className="body-content-slider-details-session">
                          <div
                            id="carouselSliderContentDetailsSession"
                            className="carousel slide"
                            data-ride="carousel"
                            data-interval="false"
                            data-wrap="false"
                          >
                            <div className="carousel-inner">
                              {contenu.map((item, index) =>
                                index === 0 ? (
                                  item.content_type === "doc" ||
                                  item.content_type === "slide" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <IntroSliderContentDetailSession
                                        data_storage={this.state?.store}
                                        isForumTask={item.forum}
                                        contenu={item}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        slide={item.list_supports}
                                        id_contenu={item.id}
                                        contentData={item}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        progression={item?.progression}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        modules={this.state.modules}
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        download={item.download}
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_supports
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "image" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <ChooseImage
                                        image={item.list_task}
                                        contenu={item}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "drag_drop" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <TacheGlisserDeposer
                                        drag_drop={item.list_task}
                                        contenu={item}
                                        contenuBySession={contenu}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "yes_no" ? (
                                    <div
                                      className="carousel-item active "
                                      key={item.id}
                                    >
                                      <TacheVraiFaux
                                        yes_no={item.list_task}
                                        contenu={item}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type ===
                                    "multiple_choice" ? (
                                    <div
                                      className="carousel-item active "
                                      key={item.id}
                                    >
                                      <TacheQuiz
                                        callBackQuiz={this.callSomeEffect}
                                        contenu={item}
                                        multiple_choice={item.list_task}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        id_contenu={item.id}
                                        a_afficher_sur={
                                          item?.a_afficher_sur !== 0
                                            ? item?.a_afficher_sur
                                            : null
                                        }
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "text_field" ? (
                                    <div
                                      className="carousel-item active "
                                      key={item.id}
                                    >
                                      <TacheQuestionReponse
                                        callBackFromParent={this.callSomeEffect}
                                        contenu={item}
                                        isForumTask={item.forum}
                                        text={item.list_task}
                                        id_contenu={item.id}
                                        a_afficher_sur={
                                          item?.a_afficher_sur !== 0
                                            ? item?.a_afficher_sur
                                            : null
                                        }
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "click_reveal" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <TacheClickAndReveal
                                        click_reveal={item?.list_task}
                                        sessionId={item?.session}
                                        contenu={item}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "texte_trous" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <TacheTextATroux
                                        texte_trous={item.list_task}
                                        contenu={item}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "podcast" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <LayoutCours
                                        podcast={item.list_supports}
                                        contenu={item}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_supports
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "video" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <TacheVideo
                                        video={item.list_supports}
                                        contenu={item}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_supports
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "heuristique" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <TacheCarteHeuristiqueV2
                                        heuristique={item.list_words}
                                        contenu={item?.contenu}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                      />
                                    </div>
                                  ) : item.content_type === "scale" ? (
                                    <div
                                      className="carousel-item active"
                                      key={item.id}
                                    >
                                      <TacheEvaluation
                                        callBackFromParent={this.callSomeEffect}
                                        contenu={item}
                                        evaluation={item.list_task}
                                        sessionId={item?.session}
                                        item={item}
                                        id_contenu={item.id}
                                        a_afficher_sur={
                                          item?.a_afficher_sur !== 0
                                            ? item?.a_afficher_sur
                                            : null
                                        }
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : item.content_type === "upload" ? (
                                    <div
                                      className="carousel-item active "
                                      key={item.id}
                                    >
                                      <TacheUpload
                                        contenu={item}
                                        upload={item.list_task}
                                        sessionId={item?.session}
                                        moduleId={item?.module}
                                        module_id={this.state.moduleId}
                                        sessions={this.state.sessions}
                                        sessionsModule={
                                          this.state.sessionsModule
                                        }
                                        progression={item?.progression}
                                        onGetUserDoModules={
                                          this.child.onGetUserDoModules
                                        }
                                        usermodules={this.state.usermodules}
                                        usersessions={this.state.usersessions}
                                        usercontenus={this.state.usercontenus}
                                        paramsComponent={
                                          this.props.match.params.component
                                        }
                                        last={
                                          contenu.filter(
                                            (d) =>
                                              d?.id ===
                                              contenu[contenu.length - 1]?.id
                                          )[0]?.list_task
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : item.content_type === "doc" ||
                                  item.content_type === "slide" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <IntroSliderContentDetailSession
                                      data_storage={this.state?.store}
                                      isForumTask={item.forum}
                                      contenu={item}
                                      slide={item.list_supports}
                                      contentData={item}
                                      id_contenu={item.id}
                                      checkitem={this.checkitem}
                                      sessionId={item?.session}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      download={item.download}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      modules={this.state.modules}
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_supports
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "image" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <ChooseImage
                                      image={item.list_task}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "drag_drop" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <TacheGlisserDeposer
                                      drag_drop={item.list_task}
                                      sessionId={item?.session}
                                      contenuBySession={contenu}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "yes_no" ? (
                                  <div className="carousel-item " key={item.id}>
                                    <TacheVraiFaux
                                      yes_no={item.list_task}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "multiple_choice" ? (
                                  <div className="carousel-item " key={item.id}>
                                    <TacheQuiz
                                      callBackQuiz={this.callSomeEffect}
                                      multiple_choice={item.list_task}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      id_contenu={item.id}
                                      a_afficher_sur={
                                        item?.a_afficher_sur !== 0
                                          ? item?.a_afficher_sur
                                          : null
                                      }
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu?.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0]?.list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "text_field" ? (
                                  <div
                                    className="carousel-item "
                                    key={item?.id}
                                  >
                                    <TacheQuestionReponse
                                      callBackFromParent={this.callSomeEffect}
                                      isForumTask={item.forum}
                                      text={item.list_task}
                                      id_contenu={item.id}
                                      a_afficher_sur={
                                        item?.a_afficher_sur !== 0
                                          ? item?.a_afficher_sur
                                          : null
                                      }
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "click_reveal" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <TacheClickAndReveal
                                      click_reveal={item.list_task}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "texte_trous" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <TacheTextATroux
                                      texte_trous={item.list_task}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "podcast" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <LayoutCours
                                      podcast={item.list_supports}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_supports
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "video" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <TacheVideo
                                      video={item.list_supports}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_supports
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "heuristique" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <TacheCarteHeuristiqueV2
                                      heuristique={item.list_words}
                                      contenu={item?.contenu}
                                      sessionId={item?.session}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                    />
                                  </div>
                                ) : item.content_type === "scale" ? (
                                  <div className="carousel-item" key={item.id}>
                                    <TacheEvaluation
                                      callBackFromParent={this.callSomeEffect}
                                      evaluation={item.list_task}
                                      sessionId={item?.session}
                                      contenu={item}
                                      item={item}
                                      id_contenu={item.id}
                                      a_afficher_sur={
                                        item?.a_afficher_sur !== 0
                                          ? item?.a_afficher_sur
                                          : null
                                      }
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : item.content_type === "upload" ? (
                                  <div className="carousel-item " key={item.id}>
                                    <TacheUpload
                                      upload={item.list_task}
                                      sessionId={item?.session}
                                      contenu={item}
                                      moduleId={item?.module}
                                      module_id={this.state.moduleId}
                                      sessions={this.state.sessions}
                                      sessionsModule={this.state.sessionsModule}
                                      progression={item?.progression}
                                      onGetUserDoModules={
                                        this.child.onGetUserDoModules
                                      }
                                      usermodules={this.state.usermodules}
                                      usersessions={this.state.usersessions}
                                      usercontenus={this.state.usercontenus}
                                      paramsComponent={
                                        this.props.match.params.component
                                      }
                                      last={
                                        contenu.filter(
                                          (d) =>
                                            d.id ===
                                            contenu[contenu.length - 1].id
                                        )[0].list_task
                                      }
                                    />
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row dis-flex justify-content-end m-b-80 slider-actions-btn"></div>
                      </>
                    ) : (
                      <>
                        <div className="header-content-slider-details-session ">
                          <div className="dis-flex"></div>
                        </div>
                        <div className="body-content-slider-details-session">
                          <div
                            id="carouselSliderContentDetailsSession"
                            className="carousel slide"
                            data-ride="carousel"
                            data-interval="false"
                            data-wrap="false"
                          >
                            <div className="carousel-inner">
                              <div className="carousel-item active">
                                <SlideSkeleton />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row dis-flex justify-content-end m-b-80 slider-actions-btn"></div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-xl-3 padding-right-web">
                  <SessionStartInfos
                    paramsId={this.props.match.params.id}
                    paramsComponent={this.props.match.params.component}
                    moduleId={this.state.moduleId}
                    onRef={(ref) => (this.child = ref)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="">
          <MessageAllPage />
        </div> */}

        <div
          className="modal fade image-zoomable-modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="imageZoomableModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content modal-card">
              <ImageZoomableModal />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
