import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import { NavLink } from "react-router-dom";
import api from "../../http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import AllCourseSkeleton from "./AllCourseSkeleton";
import baseUrl from "../../http/backend-base-url";

export default class CardSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      data: [],
      showEthik: false,
      showGestion: false,
      loading: false,
      baseUrl: baseUrl,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const timer = setTimeout(() => {
      this._getModules();
    }, 2000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

  _getModules = async () => {
    let { api } = this.state;
    let url = api + `module/`;
    try {
      let res = await axios.get(url, this.state.requestConfig);
      // console.log(res);
      let data = res.data["results"];
      // console.log(data);
      this.setState({ data: data, loading: false });
    } catch (error) {
      console.log(error.message);
      console.log(error.response);
      toast.error(
        "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.",
        { position: toast.POSITION.TOP_CENTER, autoClose: 5000 }
      );
    }
  };

  render() {
    const { data, loading, baseUrl } = this.state;
    if (loading) {
      return <AllCourseSkeleton />;
    } else {
      return data.map((item) => {
        return (
          <div
            className="col-md-3 col-sm-4 col-xs-6"
            key={item.id}
            style={{ padding: 4 }}
          >
            <div className="card" style={{}}>
              <div
                className=""
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  height: 200,
                  borderRadius: "10px 10px 0 0 ",
                }}
              >
                <img
                  // className="img-course-on-card-cours"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={baseUrl + item.logo}
                  alt=""
                />
              </div>
              <div className="content-course-on-card-cours">
                <NavLink
                  className="titre-course-on-card-cours"
                  to={`/cours-details/${item.id}`}
                >
                  {item.name}
                </NavLink>
                <div className="row m-t-15">
                  <div className="col-lg-12 col-md-12 col-sm-3 col-xs-6 col-12 flex-row info-content-course-on-card-cours itm-center">
                    <FeatherIcon
                      className="icon-content-course-on-card-cours"
                      icon="youtube"
                    />
                    {item.number_session} sessions
                  </div>
                  <div className="col">
                    <NavLink to={`/cours-details/${item.id}`}>
                      <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }
}
