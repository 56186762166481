import React, { useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import ContactSupport from "./ContactSupport";

const navLinks = [
  { to: "/tableau-de-bord", icon: "grid", label: "Tableau de bord" },
  { to: "/module", icon: "book", label: "Module" },
  { to: "/apprenants", icon: "user", label: "Apprenant" },
  {
    to: "/liste-des-identifications",
    icon: "user",
    label: "Apprenants Identifiés",
  },
  { to: "/certificats", icon: "award", label: "Certificats" },
  { to: "/performances", icon: "activity", label: "Performances" },
  { to: "/parametres", icon: "settings", label: "Paramètres" },
  { to: "/extraction", icon: "download", label: "Extraction" },
  {
    to: "/gestion-messages-contact",
    icon: "message-square",
    label: "Messages",
  },
];

const FCNavbarVerticalAdmin = () => {
  useEffect(() => {
    $(".contactSupportModal").appendTo($("body"));
  }, []);

  return (
    <div className="hoverable" style={{ backgroundColor: "#009688" }}>
      <ul className="nav nav-items-custom flex-column">
        {navLinks.map((link, index) => (
          <li className="nav-item nav-item-vertical-custom" key={index}>
            <NavLink
              className="nav-link nav-link-vertival-custom tw-flex tw-items-center tw-gap-2"
              to={link.to}
              activeClassName="actived"
            >
              <FeatherIcon className="icon-navbar-vertical" icon={link.icon} />
              <span className="hiddenable-navlink-label">{link.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="container-fluid">
        <p className="text-white f-f-inter fw-600 fs-14 m-b-20">
          Besoin d’aide ?
        </p>
        <button
          className="btn-icon-transparent"
          data-toggle="modal"
          data-target=".contactSupportModal"
        >
          <FeatherIcon className="icon-contact-support" icon="mail" /> Contacter
          le support
        </button>

        <div
          className="modal fade contactSupportModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content modal-card">
              <ContactSupport />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FCNavbarVerticalAdmin;
