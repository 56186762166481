import axios from 'axios'
import FeatherIcon from 'feather-icons-react'
import React, { Component } from 'react'
import api from '../../http/global-vars'
// import { Link } from "react-router-dom";
import baseUrl from '../../http/backend-base-url'
// import FileSaver from "file-saver";
// import { Link } from "react-router-dom";
import $ from 'jquery'

export default class IntroSliderContentDetailSession extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slide: this.props.slide[0],
      contentData: this.props.contentData,
      download: this.props.download,
      selectedIndex: 0,
      doc: this.props.doc,
      userID: window.localStorage.getItem('userID'),
      token: window.localStorage.getItem('userToken'),
      module_id: '',
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      },
      api: api,
      endSession: false,
      endModule: false,
      selectedId: null,
      session: [],
      badge: 0,
      baseUrl: baseUrl,
      contenuBySession: [],
      export_data: [],
      data_storage: this.props?.data_storage,
      check: false,
      evaluations: [],
      load: false,
      module: {},
    }
    this.dataStore = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    console.log('props')
    setTimeout(() => {
      this.getSessionByModule()
      this.getEvaluationByUser()
      this.onMakeImageZoomable()
    }, 1500)
    // console.log(this.props.last);
    // console.log(this.props);
    this._getCOntinu()

    //const node = this.dataStore.current;
    // console.log("node==>",typeof(node));
  }

  onMakeImageZoomable = () => {
    $('.slide-content-viewer img').addClass('zoomTarget')
    $('.slide-content-viewer img').attr('data-toggle', 'modal')
    $('.slide-content-viewer img').attr('data-target', '.image-zoomable-modal')
    var imageSource = ''
    $('.zoomTarget').click(function () {
      imageSource = $(this).attr('src')
      $('.image-zoomable-container').html(
        '<img src="' + imageSource + '" class="is-zoomed-image" />',
      )
    })
  }

  _getCOntinu = async () => {
    let { api, requestConfig } = this.state
    let url = api + `usercontenus/${localStorage.userID}/`
    try {
      let req = await axios.get(url, requestConfig)
      // console.log(req);
      let data = req['data']
      return data
      // console.log(data);
      // let filterModule=data.map((item)=>item.contenu).filter((c)=>c.session).filter((t)=>t.content_type==='doc' || t.content_type===);
      // console.log(filterModule);
    } catch (error) {
      console.log(error.response)
    }
  }

  _redirect = async () => {
    console.log('last', this.props.last[0]?.id, this.state.slide?.id)
    let lastcontenu = []
    lastcontenu = this.state.contenuBySession.filter(
      (d) =>
        d?.id ===
        this.state.contenuBySession[this.state.contenuBySession.length - 1]?.id,
    )[0]?.list_supports

    if (
      this.props.last[0]?.id === this.state.slide?.id ||
      lastcontenu[0]?.id === this.state.slide?.id
    ) {
      console.log('END Session', this.props.sessionsModule)
      var objectFound = null
      if (this.props.sessionId) {
        const Data = {
          user: parseInt(this.state.userID),
          session: this.props.sessionId,
        }
        console.log('data', Data)
        var last = this.props.sessionsModule.filter(
          (d) =>
            d?.id ===
            this.props.sessionsModule[this.props.sessionsModule.length - 1]?.id,
        )[0]

        var elementPos = this.props.sessionsModule
          .map(function (x) {
            return x?.id
          })
          .indexOf(this.props.sessionId)

        let checkerSession = (arr) =>
          arr.every((v) => parseInt(v?.session?.id) !== this.props.sessionId)
        if (this.props.sessionsModule.length && last !== undefined) {
          console.log('tested')
          if (this.props.sessionsModule[elementPos]?.id === last?.id) {
            /* let checker = (arr) =>
              arr.every((v) => parseInt(v?.module?.id) !== last?.module_id);
            console.log(this.props.usermodules);
            console.log("check", checkerSession(this.props.usersessions)); */
            this.setState({ endModule: true, load: true })
            console.log('test')

            if (checkerSession(this.props.usersessions) !== false) {
              axios
                .post(api + 'userdosession/', Data, this.state.requestConfig)
                .then((data) => {
                  console.log('data', data.data)
                })
                .catch((e) => {
                  console.log('e', e.response)
                })
            }

            this.updateUser()
          } else {
            if (checkerSession(this.props.usersessions) === false) {
              axios
                .post(api + 'userdosession/', Data, this.state.requestConfig)
                .then((data) => {
                  console.log('data', data.data)
                })
                .catch((e) => {
                  console.log('e', e.response)
                })
            }
            if (elementPos >= 0) {
              objectFound = this.props.sessionsModule.sort(
                (a, b) => a?.id - b?.id,
              )[elementPos + 1]
              console.log('end', elementPos, objectFound)
            }

            console.log('elementPos', objectFound)
            this.setState({ endSession: true, selectedId: objectFound?.id })
            if (objectFound?.id && objectFound?.id !== undefined) {
              window.location = `/session-start/session/${objectFound?.id}`
            } else {
              window.location = `/cours-details/${this.props.module_id}`
            }
          }
        } else {
          if (checkerSession(this.props.usersessions) === false) {
            axios
              .post(api + 'userdosession/', Data, this.state.requestConfig)
              .then((data) => {
                console.log('data', data.data)
              })
              .catch((e) => {
                console.log('e', e.response)
              })
          }
          // objectFound = this.props.sessions[elementPos + 1];
          if (elementPos >= 0) {
            objectFound = this.props.sessionsModule.sort(
              (a, b) => a?.id - b?.id,
            )[elementPos + 1]
            console.log('end', elementPos, objectFound)
          }
          console.log('end', elementPos, objectFound)
          this.setState({ endSession: true, selectedId: objectFound?.id })
          if (objectFound?.id && objectFound?.id !== undefined) {
            window.location = `/session-start/session/${objectFound?.id}`
          } else {
            window.location = `/cours-details/${this.props.module_id}`
          }
        }
      } else {
        console.log(
          'test',
          this.props.sessionsModule.sort((a, b) => a?.id - b?.id),
        )
        objectFound = this.props.sessionsModule.sort((a, b) => a?.id - b?.id)[0]
        if (objectFound !== undefined) {
          window.location = `/session-start/session/${objectFound?.id}`
        }
      }
    }
    //return (window.location = `/session-start/session/${this.state.selectedId}`);
  }

  updateProgres = async () => {
    const { api } = this.state
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id,
    )
    console.log('mod', mod, this.state.module_id)
    const url = api + `userdomodule/${mod[0]?.id}/`

    let progression
    if (this.props.progression) {
      progression = this.props.progression
    }
    let send = {
      progression: progression,
    }

    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig)
        console.log(res.data)
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  UserDoContenu = async () => {
    let checker = (arr) =>
      arr.every((v) => parseInt(v?.contenu?.id) !== this.state.slide?.contenu)
    //console.log("check contenu", checker(this.props.usercontenus));
    this.setState({ check: checker(this.props.usercontenus) })
    var export_ = JSON.parse(localStorage.getItem('resp_gfa'))
    if (export_ === null || export_ === undefined) {
      if (!(export_ instanceof Array)) {
        // console.log("no data in strorage");
        export_ = []
        export_.push(JSON.parse(localStorage.getItem('resp_gfa')))
        this.setState({ export_data: export_ })
        // console.log(this.state.export_data);
      }
    } else {
      // console.log("data in strorage")
      // if (!(export_ instanceof Array)) {
      // console.log("change etat");
      // export_ = [];
      // export_.push(JSON.parse(localStorage.getItem("resp_gfa")))
      // this.setState({ export_data: export_ })
      // console.log(this.state.export_data);
      // }
      // this.setState({ export_data: export_ })
      // console.log(this.state.export_data);
    }
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.state.slide?.contenu,
    }
    if (checker(this.props.usercontenus) !== false) {
      const url = api + 'userdocontenu/'
      await axios
        .post(url, sendData, this.state.requestConfig)
        .then((res) => {
          // console.log("data post", res);
          this.updateProgres()
        })
        .catch((e) => {
          console.log('e post', e.response.data)
        })
    }

    this.props.onGetUserDoModules()
    //this._redirect()
    window.scrollTo({
      top: 60,
      left: 0,
    })
  }

  getSessionByModule = async () => {
    if (this.props.sessionId) {
      const url = api + 'session/' + this.props.sessionId
      await axios
        .get(url, this.state.requestConfig)
        .then(async (response) => {
          const last = this.props.sessions.filter(
            (d) =>
              d.module_id?.id === response.data?.module_id ||
              d?.module_id === response.data?.module_id,
          )
          // console.log("session", last, response.data);
          let data = response['data']
          this.setState({ session: last, module_id: data?.module_id })
          this.getModuleItem(data?.module_id)
        })
        .catch((e) => {
          console.log('e', e)
        })
    }

    if (this.props.moduleId) {
      this.getModuleItem(this.props.moduleId)
      const url = api + 'sessionByModule/' + this.props.moduleId
      axios
        .get(url, this.state.requestConfig)
        .then(async (response) => {
          let data = await response['data']
          // console.log("session by module", data);
          this.setState({ session: data, module_id: this.props.moduleId })
        })
        .catch((e) => {
          console.log('e', e)
        })
      // this.setState({ module_id: this.props.moduleId });
    }
    this.contenuBySession()
  }

  updateUser = async () => {
    let checker = (arr) =>
      arr.every((v) => parseInt(v?.contenu?.id) !== this.state.slide?.contenu)
    /* var countBadge = 0
    var moduleName = []
    if (this.props.usermodules !== undefined && this.props.usermodules.length) {


      this.props.usermodules.forEach((item) => {
        if (item.progression === 100) {
          countBadge += 1
          moduleName.push(item.module.name)
        }
      })
      console.log('count', countBadge)
    } */
    const url = api + 'user/' + parseInt(this.state.userID) + '/'
    await axios
      .get(url, this.state.requestConfig)
      .then(async (val) => {
        console.log('user', val.data)
        let user = val.data
        console.log('ici')
        if (
          user.badge < 3 &&
          this.props.progression === 100 &&
          this.state.module?.has_certicate === false
        ) {
          await axios
            .put(url, { badge: user.badge + 1 }, this.state.requestConfig)
            .then((data) => {
              console.log('data', data.data)
              this.setState({ load: false })
              if (data) {
                if (data.data.badge === 3 && !this.state.evaluations.length) {
                  if (this.state.module?.has_certicate) {
                    return (window.location = '/mon-certificat')
                  }
                  return (window.location = `/fiche-evaluation/`)
                } else {
                  return (window.location = `/tous-les-modules/`)
                }
              }
            })
            .catch((e) => {
              console.log('e', e.response)
            })
        } else {
          console.log('here')
          if (
            this.props.progression === 100 &&
            this.state.module?.has_certicate
          ) {
            return (window.location = '/mon-certificat')
          }
          if (user.badge === 3 && !this.state.evaluations.length) {
            return (window.location = `/fiche-evaluation/`)
          } else {
            console.log('evaluation fait')
            return (window.location = `/tous-les-modules/`)
          }
        }
      })
      .catch((e) => {
        console.log('e', e.response.data)
      })
  }

  getEvaluationByUser = async () => {
    const url = api + 'evaluationbyuser/' + parseInt(this.state.userID) + '/'
    await axios.get(url, this.state.requestConfig).then((response) => {
      // console.log('evaluation', response.data)
      this.setState({ evaluations: response.data })
    })
  }

  download = (e, url) => {
    e.preventDefault()
    console.log(url)
    return window.open(url, 'download')
  }

  contenuBySession = async () => {
    const url = api + 'contenubysession/' + this.props.sessionId + '/'
    //console.log("url session", url);
    if (
      this.props.sessionId !== undefined &&
      this.props.moduleId === undefined
    ) {
      await axios.get(url, this.state.requestConfig).then((response) => {
        //console.log("contenu By session", response.data);
        if (response) {
          // console.log("contenu By session", response.data);

          this.setState({ contenuBySession: response.data })
        }
        // console.log("contenu By session", this.state.contenuBySession);
      })
    } else if (this.props.moduleId !== undefined) {
      await axios
        .get(
          api + 'contenubymodule/' + this.props.moduleId + '/',
          this.state.requestConfig,
        )
        .then((response) => {
          if (response) {
            // console.log("contenu By module", response.data);

            this.setState({ contenuBySession: response.data })
          }
          //console.log("contenu By module", this.state.contenuBySession);
        })
    }
  }

  getModuleItem = async (id) => {
    // console.log('module id', id)
    const url = api + 'module/' + id
    await axios
      .get(url, this.state.requestConfig)
      .then(async (response) => {
        // console.log('module item', response)
        this.setState({ module: response?.data })
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  render() {
    let lastcontenu = []

    // if (this.props?.id_contenu !== undefined) {
    //   console.log("id contenu question-response =>", this.props?.id_contenu)
    // }
    console.log('this', this.props)
    let end = false
    if (
      this.state.contenuBySession !== undefined &&
      this.state.contenuBySession.length
    ) {
      lastcontenu = this.state.contenuBySession.filter(
        (d) =>
          d?.id ===
          this.state.contenuBySession[this.state.contenuBySession.length - 1]
            ?.id,
      )[0]?.list_supports
      if (lastcontenu !== undefined) {
        if (lastcontenu[0]?.id === this.state.slide?.id) {
          end = true
        }
      }
    }

    const { slide, contentData } = this.state

    var last = this.state.session.filter(
      (d) => d?.id === this.state.session[this.state.session?.length - 1]?.id,
    )[0]

    var elementPos = this.state.session
      .map(function (x) {
        return x?.id
      })
      .indexOf(this.props.sessionId)

    /* const test = this.state.session.filter(
      (d) => d?.id === this.state.session[this.state.session.length - 1]?.id
    )[0]; */
    // console.log("test", test);
    // if (this.props?.data_storage.length !== 0) {
    //   console.log(this.props?.data_storage);

    // }

    let tab = []
    // eslint-disable-next-line no-unused-vars
    let export_data
    let test = JSON.parse(localStorage.getItem('resp_gfa'))
    let data_storage = this.props?.data_storage
    if (this.state.check !== undefined) {
      if (test === null || test === undefined) {
        export_data = this.state.export_data
      } else {
        export_data = test
      }
    }
    if (Array.isArray(data_storage) && data_storage.length) {
      if (data_storage !== null || data_storage.length !== 0) {
        for (let i = 0; i < data_storage?.length; i++) {
          const element = data_storage[i]
          if (element?.id_affiche === this.props?.id_contenu) {
            tab.push(element.text)
          }
        }
      }
    }

    let fullName =
      localStorage.getItem('userFirstName') +
      ' ' +
      localStorage.getItem('userLastName')
    let desc = slide?.description?.replace(/prenomnom/gi, fullName)
    if (tab.length > 0) {
      for (let i = 0; i < tab.length; i++) {
        const element = tab[i]
        desc = desc?.replace('responsegfa', element || '')
      }
    }
    desc = desc?.replace(/responsegfa/gi, '')

    return (
      <>
        {slide ? (
          <div>
            <div className="title">
              <div className="text-dark m-b-5 row">
                <h5>{slide.name}</h5>
              </div>
            </div>

            <div className="doc-viewer-container slide-content-viewer zoomContainer">
              <div
                ref={this.dataStore}
                id="id_gfa"
                className="fs-14 f-f-work fw-400 text-dark content_html"
                dangerouslySetInnerHTML={{ __html: desc }}
              />

              {contentData.download === true && (
                <div className="row download-item-row">
                  <div className="col-md-2 download-item-col">
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Télécharger le document"
                      className="download-item-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${baseUrl}${slide.url}`}
                      download
                    >
                      <FeatherIcon
                        color="white"
                        icon="download"
                        className="justify-content-end align-content-end download-icon"
                      />
                    </a>
                  </div>
                </div>
              )}
              {/* Goto Forum Button Condition */}
              {this.props.isForumTask !== 0 && (
                <div className="row goto-forum-item-row">
                  <div className="col-md-4 goto-forum-item-col">
                    <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Participez au forum"
                      className="goto-forum-item-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/details-forum/${this.props.isForumTask}`}
                    >
                      <FeatherIcon
                        icon="message-square"
                        className="justify-content-end align-content-end goto-forum-icon"
                      />
                      &nbsp;
                      <span className="goto-forum-link-label">
                        Allez au forum
                      </span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          'not found'
        )}
        <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10 left carousel-control">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600 left carousel-control"
              href="#carouselSliderContentDetailsSession"
              role="button"
              data-slide="prev"
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10 right carousel-control">
            {!this.props.last ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600 right carousel-control"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.UserDoContenu}
              >
                Suivant
              </button>
            ) : this.props?.last[0]?.id !== this.state.slide?.id && !end ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600 right carousel-control"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.UserDoContenu}
              >
                Suivant
              </button>
            ) : this.props.sessionId === undefined ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                data-slide="next"
                onClick={this.UserDoContenu}
                data-toggle="modal"
                data-target={`#endSessionSuccess${this.state.slide?.id}`}
              >
                Suivant
              </button>
            ) : this.state.session[elementPos]?.id !== last?.id ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                data-slide="next"
                data-toggle="modal"
                onClick={this.UserDoContenu}
                data-target={`#endSessionSuccess${this.state.slide?.id}`}
              >
                Suivant
              </button>
            ) : last !== undefined ? (
              <button
                className="btn-theme dis-flex justify-content-center fw-600 "
                onClick={this.UserDoContenu}
                data-slide="next"
                data-toggle="modal"
                data-target={`#endSuccess${slide?.id}`}
              >
                Suivant
              </button>
            ) : (
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                data-slide="next"
                data-toggle="modal"
                onClick={this.UserDoContenu}
                data-target={`#endSessionSuccess${this.state.slide?.id}`}
              >
                Suivant
              </button>
            )}
          </div>
        </div>
        {/* Module End Modal */}
        <div
          className="modal fade"
          id={`endSuccess${slide?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon icon="award" className="mb-3 badge rotateIn" />
                  <h4 className="pop-title fw-600">
                    Fin du Module !!!{' '}
                    {this.state.module?.has_certicate
                      ? 'Tu as obtenu ton certificat'
                      : 'Tu as obtenu un badge'}{' '}
                  </h4>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                {this.state.load === false ? (
                  <button
                    className="btn btn-theme-light fw-400 fs-15 justify-content-start"
                    onClick={this._redirect}
                  >
                    CONTINUER{' '}
                  </button>
                ) : (
                  <button className="btn btn-theme-light fw-400 fs-15 justify-content-start">
                    Redirection en cours
                    <i className="fas fa-spin fa-spinner"></i>
                  </button>
                )}
                {/* <a
                      type="button"
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      role="button"
                      data-slide="next"
                    >
                      CONTINUER
                    </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* END Modal */}
        {/* Session End Modal */}
        <div
          className="modal fade"
          id={`endSessionSuccess${this.state.slide?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="mb-3 animat rotateIn"
                  />
                  <h4 className="pop-title fw-600">
                    FIN DE{' '}
                    {this.props.moduleId === undefined
                      ? 'LA SESSION'
                      : "L'INTRODUCTION"}{' '}
                    !!!
                  </h4>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  data-dismiss="modal"
                  className="btn btn-theme fw-400 fs-15 justify-content-start"
                  onClick={this._redirect}
                >
                  CONTINUER{' '}
                </button>
                {/* <a
                      type="button"
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      role="button"
                      data-slide="next"
                    >
                      CONTINUER
                    </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* END Modal */}
      </>
    )
  }
}
