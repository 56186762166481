import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import HeaderAccueil from './components/HeaderAccueil';
import FeatherIcon from "feather-icons-react";
import API from "./../http/global-vars";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import MessageAllPage from "../backend/components/MessageAllPage";
import isAuthenticate from "./../modules/Secure/Secure";
import ResetPasswordModal from "./ResetPasswordModal";

const RFInscription = () => {
  const navigate = useNavigate();

  // const [api, setApi] = useState(api);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [isSuccessLogin, setIsSuccessLogin] = useState(false);
  const [isErrorLogin, setIsErrorLogin] = useState(false);
  const [is_empty_email, setIsEmptyEmail] = useState(false);
  const [is_empty_password, setIsEmptyPassword] = useState(false);
  const [isInvalidCredential, setIsInvalidCredential] = useState(false);
  const [token, setToken] = useState(window.localStorage.getItem("userToken"));
  const [requestConfig, setRequestConfig] = useState({
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  });
  const [userID, setUserID] = useState(window.localStorage.getItem("userID"));
  const [userType, setUserType] = useState(
    window.localStorage.getItem("userType")
  );

  // useEffect(() => {
  //   // onTestUserConnectedStatus();
  // }, []);

  const onTestUserConnectedStatus = () => {
    const isNotConnected = isAuthenticate();
    const userType = userType;
    // const isFirstConnexion = window.localStorage.getItem("userFirstConnexion");

    if (!isNotConnected && userType === "student") {
      // if (isFirstConnexion === true) {
      //   window.location = "/welcome";
      // } else {
      //   window.location = "/tous-les-modules";
      // }
      window.location = "/tous-les-modules";
    } else if (!isNotConnected && userType === "admin") {
      window.location = "/tableau-de-bord";
    }
  };

  const handleChange = (e) => {
    let newFields = { ...fields };
    newFields[e.target.name] = e.target.value;

    if (e.target.name === "email") {
      newFields[e.target.name] = e.target.value.toLowerCase();
    }

    setFields(newFields);
  };

  const onValidateLoginForm = () => {
    let newFields = { ...fields };
    let newErrors = { ...errors };
    let isValidForm = true;

    if (!newFields["email"]) {
      isValidForm = false;
      setIsEmptyEmail(true);
      setTimeout(() => {
        setIsEmptyEmail(false);
      }, 5000);

      newErrors["email"] = "Ce champ est obligatoire";
    }
    if (!newFields["password"]) {
      isValidForm = false;
      setIsEmptyPassword(true);
      setTimeout(() => {
        setIsEmptyPassword(false);
      }, 5000);

      newErrors["password"] = "Ce champ est obligatoire";
    }

    setErrors(newErrors);

    return isValidForm;
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();

    if (onValidateLoginForm()) {
      setIsLoginInProgress(true);

      const api = API;
      const data = fields;
      // return console.log({ data });
      const fd = new FormData();
      fd.append("first_name", data.first_name.toLowerCase());
      fd.append("last_name", data.last_name.toLowerCase());
      fd.append("email", data.email.toLowerCase());
      fd.append("password", data.password);

      axios
        .post(api + "auth/register/", fd)
        // .post("http://192.168.68.232:8000/api/auth/register/", fd)
        .then((response) => {
          console.log({ response });
          if (response.status === 201) {
            if (response.data.status === "failure") {
              toast.error(response.data.message);
            } else {
              navigate("/connexion", { replace: true });
              // const newToken = response.data.token;
              // window.localStorage.setItem("userToken", newToken);
              // let newRequestConfig = {
              //   headers: { Authorization: `Bearer ${newToken}` },
              // };
              // onGetConnectedUserInfos(newRequestConfig);
            }
          } else if (
            response.data.success === false &&
            response.data.invalid_credential === true
          ) {
            toast.error("Vos identifiants de connexion ne sont pas valides !");
          } else {
            toast.error("Vos identifiants de connexion ne sont pas valides !");
          }
        })
        .catch((error) => {
          console.log({ error });
          toast.error(
            "Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard."
          );
        })
        .finally(() => {
          setIsErrorLogin(false);
          setIsInvalidCredential(false);
          setIsLoginInProgress(false);
        });
    }
  };

  const onGetConnectedUserInfos = (config) => {
    const api = API;
    axios
      .get(api + "me/", config)
      .then((response) => {
        setIsLoginInProgress(false);
        if (response.status === 200) {
          window.localStorage.setItem("userID", response.data.data.id);
          window.localStorage.setItem(
            "userFirstName",
            response.data.data.first_name
          );
          window.localStorage.setItem(
            "userLastName",
            response.data.data.last_name
          );
          window.localStorage.setItem(
            "userFullname",
            response.data.data.first_name + " " + response.data.last_name
          );
          window.localStorage.setItem("userType", response.data.data.user_type);
          window.localStorage.setItem(
            "userFirstConnexion",
            response.data.data.first_connexion
          );
          const userType = response.data.data.user_type;
          // const isFirstConnexion = response.data.data.first_connexion;

          toast.success("Connexion réussie !");
          setIsSuccessLogin(true);

          if (userType === "student") {
            // if (isFirstConnexion === true) {
            //   window.location = "/welcome";
            // } else {
            //   window.location = "/tous-les-modules";
            // }
            window.location = "/tous-les-modules";
          } else {
            window.location = "/tableau-de-bord";
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Oupsss! Une erreur est survenue lors de votre connexion. Veuillez réessayer plus tard."
        );
        setIsErrorLogin(true);
        setIsLoginInProgress(false);

        setTimeout(() => {
          setIsErrorLogin(false);
        }, 5000);
      });
  };

  return (
    <div
      className="p-4"
      style={{
        backgroundColor: "#EBF1F8",
        minHeight: "100dvh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <img
        src="./img/auth/Rectangle.png"
        alt=""
        className="tw-absolute tw-top-0 tw-right-0 tw-w-[300px] tw-z-10"
      />
      <img
        className="tw-absolute tw-left-0 tw-bottom-0 tw-w-[300px] tw-z-10"
        src="./img/auth/Ellipse.png"
        alt=""
      />
      <div
        className="container row p-0"
        style={{
          backgroundColor: "#fff",
          borderRadius: 10,
          overflow: "hidden",
          zIndex: 10,
        }}
      >
        {/* Page info */}
        <div
          className="p-4 col-sm-12 col-md-6"
          style={{
            backgroundColor: "#009688",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            color: "#fff",
            gap: 25,
          }}
        >
          {/* <div className=""></div> */}
          <h2 className="tw-text-3xl" style={{ fontWeight: "800" }}>
            Content de te revoir!
          </h2>
          <p className="text-center px-4">
            Pour rester en contact avec nous, veuillez vous connecter avec vos
            informations personnelles
          </p>

          <button
            className=""
            style={{
              all: "unset",
              border: "1px solid #fff",
              borderRadius: 40,
              padding: "10px 20px",
              marginTop: 10,
              cursor: "pointer",
            }}
            onClick={() => navigate("/connexion")}
          >
            SE CONNECTER
          </button>
        </div>

        {/* Form */}
        <div
          className="col-sm-12 col-md-6"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 20,
            padding: "70px 10px",
          }}
        >
          <h2
            className="text-center tw-text-3xl"
            style={{ fontWeight: "800", color: "#009688" }}
          >
            Créer un compte
          </h2>
          <div className="mb-4">
            <div
              className="mb-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 15,
              }}
            >
              <div
                className=""
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  display: "grid",
                  placeItems: "center",
                  border: "1px solid #999",
                }}
              >
                G
              </div>
              <div
                className=""
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  display: "grid",
                  placeItems: "center",
                  border: "1px solid #999",
                }}
              >
                F
              </div>
              <div
                className=""
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 50,
                  display: "grid",
                  placeItems: "center",
                  border: "1px solid #999",
                }}
              >
                IN
              </div>
            </div>
            <p className="text-center">
              ou utilisez votre compte de messagerie
            </p>
          </div>
          <form id="login-form" className="px-4" style={{ width: "100%" }}>
            <div
              className=""
              style={{
                backgroundColor: "#F4F8F5",
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 20,
                height: 45,
                flex: 1,
              }}
            >
              <div className="p-2">
                <span className="" style={{}}>
                  <FeatherIcon icon="user" />
                </span>
              </div>
              <input
                name="first_name"
                id="first_name"
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Prenom"
                style={{
                  outline: "none",
                  border: "none",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  fontSize: 14,
                }}
              />
              {is_empty_password && (
                <div className="alert alert-danger gfa-alert-danger">
                  {" "}
                  {errors.password}{" "}
                </div>
              )}
            </div>

            <div
              className=""
              style={{
                backgroundColor: "#F4F8F5",
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 20,
                height: 45,
                flex: 1,
              }}
            >
              <div className="p-2">
                <span className="" style={{}}>
                  <FeatherIcon icon="user" />
                </span>
              </div>
              <input
                name="last_name"
                id="last_name"
                onChange={(e) => handleChange(e)}
                type="text"
                placeholder="Nom"
                style={{
                  outline: "none",
                  border: "none",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  fontSize: 14,
                }}
              />
              {is_empty_password && (
                <div className="alert alert-danger gfa-alert-danger">
                  {" "}
                  {errors.password}{" "}
                </div>
              )}
            </div>

            <div
              className=""
              style={{
                backgroundColor: "#F4F8F5",
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 20,
                height: 45,
              }}
            >
              <div className="p-2">
                <span className="" style={{}}>
                  <FeatherIcon icon="mail" />
                </span>
              </div>
              <input
                name="email"
                id="email"
                onChange={(e) => handleChange(e)}
                type="text"
                // className="form-control input-with-icon"
                style={{
                  outline: "none",
                  border: "none",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  fontSize: 14,
                }}
                placeholder="Adresse mail"
              />
              {is_empty_email && (
                <div className="alert alert-danger gfa-alert-danger">
                  {" "}
                  {errors.email}{" "}
                </div>
              )}
            </div>

            <div
              className=""
              style={{
                backgroundColor: "#F4F8F5",
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 20,
                height: 45,
                flex: 1,
              }}
            >
              <div className="p-2">
                <span className="" style={{}}>
                  <FeatherIcon icon="lock" />
                </span>
              </div>
              <input
                name="password"
                id="password"
                onChange={(e) => handleChange(e)}
                type="password"
                placeholder="Mot de passe"
                style={{
                  outline: "none",
                  border: "none",
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                  fontSize: 14,
                }}
              />
              {is_empty_password && (
                <div className="alert alert-danger gfa-alert-danger">
                  {" "}
                  {errors.password}{" "}
                </div>
              )}
            </div>
            {/* <div className="input-group ">
              <div className="input-group-prepend">
                <span
                  className="input-group-text span-input-icon"
                  id="basic-addon1"
                >
                  <FeatherIcon className="icon-input" icon="lock" />
                </span>
              </div>
              <input
                name="password"
                id="password"
                onChange={(e) => handleChange(e)}
                type="password"
                placeholder="Mot de passe"
                className="form-control input-with-icon"
              />
              {is_empty_password && (
                <div className="alert alert-danger gfa-alert-danger">
                  {" "}
                  {errors.password}{" "}
                </div>
              )}
            </div> */}

            <div
              className="text-center m-b-10 mdpOublie"
              // className="mdpOublie"
              data-toggle="modal"
              data-target=".open-reset-password-modal"
            >
              <button
                onClick={(e) => e.preventDefault()}
                type="button"
                className="mdpOublie"
                data-toggle="modal"
                data-target=".open-reset-password-modal"
              ></button>
              Mot de passe oublié ?
            </div>

            <ResetPasswordModal />

            {isLoginInProgress === false ? (
              <button
                type="submit"
                onClick={(e) => onSubmitLogin(e)}
                className="btn-connexion"
                style={{ backgroundColor: "#F44336", height: 40 }}
              >
                S’INSCRIRE
              </button>
            ) : (
              <button
                className="btn-connexion"
                style={{ backgroundColor: "#F44336", height: 40 }}
              >
                Connexion en cours &nbsp;
                <i className="fas fa-spin fa-spinner"></i>
              </button>
            )}
          </form>
          {/* <p className="mt-5 footer-text-form">
                Pas encore de compte ?{" "}
                <NavLink className="footer-link-form" to="/inscription">
                  S’inscrire
                </NavLink>
              </p> */}
        </div>
      </div>

      {isSuccessLogin || isErrorLogin || isInvalidCredential ? (
        <ToastContainer />
      ) : (
        ""
      )}
    </div>
  );
};

export default RFInscription;
