import React, { Component } from 'react'
import api from '../../http/global-vars';
import CertificateCard from './CertificateCard'
import axios from 'axios'
export default class ModalCertificat extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            config: {
                headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
            },
            api: api,
        }
    }

    async componentWillReceiveProps(nextProps) {
        console.log("next props==>", nextProps?.apprenantInfos);
        let data = nextProps?.apprenantInfos
        await this._getCreateCertificat(data?.id)
    }

    _getCreateCertificat = async (userID) => {
        let { api, config } = this.state;
        let url = `${api}createpdfcertificate/${userID}/`;
        try {
            const req = await axios.get(url, config);
            console.log("response certificat pdf==>", req['data']);
            this.setState({ data: req['data'] })
        } catch (error) {
            console.log(error.message);
        }
    }

    render() {
        return (
            <div>
                <p className="titre-modal-details-apprenant"> {this.props.apprenantInfos.first_name + ' ' + this.props.apprenantInfos.last_name} </p>
                <ul className="nav nav-tabs" id={`myTab-${this.props?.id}`} role="tablist">
                    <li className="nav-item">
                        <a name='certificat' className={`nav-link nav-link-modal-details-apprenant active`} id={`certificat-tab-${this.props?.id}`} data-toggle="tab" href={`#certificat-${this.props?.id}`} role="tab" aria-controls="certificat" aria-selected="false">Certificat</a>
                    </li>
                </ul>
                <div className="tab-content tab-content-custom" id={`myTabContent-${this.props?.id}`}>
                    <div className={`tab-pane fade show active`} id={`certificat-${this?.props?.apprenantInfos?.id}`} role="tabpanel" aria-labelledby={`certificat-tab-${this?.props?.apprenantInfos?.id}`}>
                        <CertificateCard
                            data={this.state?.data}
                            nb_certif={this?.props?.apprenantInfos?.certification}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
