import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default class AllCourseSkeleton extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const renderModules = Array(4)
      .fill()
      .map((item, index) => (
        <div className="col-md-4" key={index}>
          <div className="card">
            <Skeleton variant="rect" width={340} height={200} />
          </div>
        </div>
      ));

    return (
      <SkeletonTheme color="#E0E4E2" highlightColor="#D1D5D3">
        <div className="row">{renderModules}</div>
      </SkeletonTheme>
    );
  }
}
