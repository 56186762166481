import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { NavLink, useParams, useLocation } from "react-router-dom";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import ItemIntroModule from "./components/ItemIntroModule";
import ItemSessionModule from "./components/ItemSessionModule";
import MessageAllPage from "./components/MessageAllPage";
import ModalAddSession from "./components/ModalAddSession";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import FCNavbarVerticalAdmin from "./components/FCNavbarVertical";

const FCModuleDetailsV2 = () => {
  const { id } = useParams();
  const location = useLocation();
  const [moduleState, setModuleState] = useState(null);

  useEffect(() => {
    console.log("id module=>", id);
    setModuleState(location.state);
  }, [id, location.state]);

  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobileAdmin />
        </div>
      </div>

      <HeaderDashboardAdmin />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container">
          <div
            className="navbar-vertical-customisation no-view-mobile"
            style={{ top: "40px" , zIndex: 98}}
          >
            <FCNavbarVerticalAdmin />
          </div>
        </div>

        <div className="col-xl-2 col-lg-3 col-md-12"></div>

        <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
          <div className="container-fluid">
            <p className="m-b-30">
              <NavLink className="routing-view-url" to="/module">
                Module
              </NavLink>
              <FeatherIcon
                className="icon-routing-view-url"
                icon="chevron-right"
              />
              <NavLink className="routing-view-url" to="#">
                {moduleState?.name}
              </NavLink>
            </p>

            <ItemIntroModule module_id={id} module={moduleState} />
            <ItemSessionModule module_id={id} module={moduleState} />
            <button
              className="btn-light-extra-large tw-flex tw-justify-center tw-items-center"
              data-toggle="modal"
              data-target=".modalAddSession"
            >
              <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
              Ajouter une session
            </button>
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};

export default FCModuleDetailsV2;
