/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import QuizCorrection from './QuizCorrection'
import FeatherIcon from 'feather-icons-react'
import axios from 'axios'
import api from '../../http/global-vars'
// import { Link, Redirect } from "react-router-dom";

export default class TacheQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: this.props.multiple_choice,
      selectedQstIndex: 0,
      selectedIndex: 0,
      userAnswer: [], //current users answer
      validChoice: null, //True if it's the last question
      score: 0, //the Score
      answers: [],
      responses: [],
      items: {},
      rightAnswerCount: 0,
      totalScore: 0,
      totalQuestions: 0,
      isEnded: false,
      isEndedQuiz: false,
      userID: window.localStorage.getItem('userID'),
      token: window.localStorage.getItem('userToken'),
      module_id: '',
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      },
      api: api,
      endSession: false,
      selectedId: 0,
      endModule: false,
      session: [],
      isDone: false,
      checked: [],
      rightCount: 0,
      pretestScore: 0,
      isDoing: false,
      contenubysession: [],
    }
  }

  componentDidMount() {
    this.setState({
      data: this.state.fields[this.state.selectedQstIndex]?.list_question.map(
        (item) => {
          return {
            content: item.content,
            id: item.id,
            score: item.score,
            list_responses: item.list_responses.map((r) => {
              return {
                id: r.id,
                content: r.content,
                is_right_choice: r.is_right_choice,
              }
            }),
          }
        },
      ),
    })

    setTimeout(() => {
      this.getSessionByModule()
      this.contenuBySession()
      this.checkContenu()
    }, 1000)
  }

  checkContenu = () => {
    const contenususer = this.props?.usercontenus.filter(
      (v) => v?.contenu?.id === this.state.fields[0]?.contenu,
    )
    let checker = this.props?.usercontenus.every(
      (v) => parseInt(v?.contenu?.id) !== contenususer[0]?.contenu?.id,
    )
    if (checker === false) {
      return this.setState({ isDoing: true })
    }
  }

  getSessionByModule = () => {
    if (this.props.sessionId) {
      const url = api + 'session/' + this.props.sessionId
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) =>
              d.module_id?.id === response.data?.module_id ||
              d?.module_id === response.data?.module_id,
          )
          let data = response['data']
          this.setState({ session: last, module_id: data.module_id })
          //console.log("session", last);
        })
        .catch((e) => {
          console.log('e', e)
        })
    }

    if (this.props.moduleId) {
      // console.log("url module");
      const url = api + 'sessionByModule/' + this.props.moduleId
      axios
        .get(url, this.state.requestConfig)
        .then(async (response) => {
          let data = await response['data']

          this.setState({ session: data, module_id: this.props.moduleId })
          // console.log("session by module", this.state.session);
        })
        .catch((e) => {
          console.log('e', e)
        })
      // this.setState({ module_id: this.props.moduleId });
    }

    let test = this.state.fields[this.state.selectedQstIndex]?.list_question[
      this.state.selectedIndex
    ]?.list_responses.filter((q) => q.is_right_choice === true)
    this.setState({ rightCount: test.length })
  }

  updateProgres = async () => {
    const { api } = this.state
    const mod = this.props.usermodules.filter(
      (v) => v?.module?.id === this.state.module_id,
    )
    //console.log('mod', mod)
    const url = api + `userdomodule/${mod[0]?.id}/`

    let progression
    if (this.props.progression) {
      progression = this.props.progression
    }
    let send = {
      progression: progression,
    }

    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig)
        console.log(res.data)
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  setUserAnswer = () => {
    const {
      userAnswer,
      fields,
      selectedQstIndex,
      selectedIndex,
      score,
    } = this.state
    let checker = (arr) => arr.every((v) => v.is_right_choice === true)
    var items = null
    if (checker(userAnswer) === false) {
      items = Object.assign({}, this.state.items, {
        question: fields[selectedQstIndex]?.list_question[selectedIndex],
        responses: userAnswer,
        is_right_choice: false,
      })
      let responses = [...this.state.responses, items]
      this.setState({
        responses,
        validChoice: false,
        checked: [],
      })
      // toast.error("Mauvaise réponse !");
    } else {
      items = Object.assign({}, this.state.items, {
        question: fields[selectedQstIndex]?.list_question[selectedIndex],
        responses: userAnswer,
        is_right_choice: true,
      })
      let responses = [...this.state.responses, items]
      this.setState({
        responses: responses,
        validChoice: true,
        score:
          score + fields[selectedQstIndex]?.list_question[selectedIndex].score,
        answers: [],
        isEnded: false,
        rightAnswerCount: this.state.rightAnswerCount + 1,
        checked: [],
      })
      // toast.success("Bonne réponse !");
    }

    this.setState({
      totalScore:
        this.state.totalScore +
        this.state.fields[this.state.selectedQstIndex]?.list_question[
          this.state.selectedIndex
        ].score,
      totalQuestions: this.state.fields[this.state.selectedQstIndex]
        ?.list_question.length,
    })
    // console.log("test responses=>", this.state.responses)
    if (
      this.state.selectedIndex !==
      this.props.multiple_choice[this.state.selectedQstIndex].list_question
        .length -
        1
    ) {
      this._ToggleNext()
    } else {
      this.sendData()
    }
  }

  onStoreData = () => {
    var export_ = JSON.parse(window.localStorage.getItem('resp_gfa'))
    let { responses } = this.state
    // console.log("tab user response==>", responses)

    for (const rep of this.state.responses) {
      for (const r of rep.responses) {
        // console.log("item=>", r);
        // console.log("item=>", rep);
        let send = {
          id: r.question,
          id_contenu: this.props?.id_contenu,
          id_affiche: this.props?.a_afficher_sur,
          text: r.content,
        }
        console.log('affiche ', this.props?.a_afficher_sur)
        if (this.props?.a_afficher_sur !== null) {
          export_ = JSON.parse(window.localStorage.getItem('resp_gfa'))
          if (send.text?.length !== 0) {
            if (export_ === null) {
              // if (send.text?.length) {
              if (!(export_ instanceof Array)) export_ = []
              console.log(send)
              export_.push(send)
              localStorage.setItem('resp_gfa', JSON.stringify(export_))
              // }
            } else {
              let index = export_.findIndex((f) => f.id === send.id)

              if (index === -1) {
                if (!(export_ instanceof Array)) export_ = []
                console.log(send)
                export_.push(send)
                localStorage.setItem('resp_gfa', JSON.stringify(export_))
                console.log(
                  'after setting resp_gfa=>',
                  JSON.parse(localStorage.getItem('resp_gfa')),
                )

                //test callback
                let store = localStorage.setItem(
                  'resp_gfa',
                  JSON.stringify(export_),
                )
                //  this.props.callBackFromParent(export_);
                //end test callback
              } else {
                console.log(send)
                export_[index] = send
                localStorage.setItem('resp_gfa', JSON.stringify(export_))
                console.log(
                  'after setting resp_gfa=>',
                  JSON.parse(localStorage.getItem('resp_gfa')),
                )

                //test callback
                let store = JSON.parse(localStorage.getItem('resp_gfa'))
                //  this.props.callBackFromParent(export_);
                //end test callback
              }
            }
          }
        }
      }
    }
    let qz = 'quiz'
    setTimeout(() => {
      this.props.callBackQuiz(export_, qz, this.props?.id_contenu)
    }, 2000)
  }

  sendData = async () => {
    //console.log(this.state.responses)
    let checker = (arr) =>
      arr.every(
        (v) =>
          parseInt(v?.contenu?.id) !==
          this.state.fields[this.state.selectedQstIndex].contenu,
      )
    const sendData = {
      score: this.state.score,
      user: parseInt(this.state.userID),
      contenu: this.state.fields[this.state.selectedQstIndex].contenu,
    }

    console.log('data', sendData)
    if (checker(this.props.usercontenus) !== false) {
      const url = api + 'userdocontenu/'
      await axios
        .post(url, sendData, this.state.requestConfig)
        .then((res) => {
          console.log('data post', res)
          //let data = res["data"];
          this.updateProgres()
        })
        .catch((e) => {
          console.log('e post', e.response)
        })
    }

    if (this.state.fields[this.state.selectedQstIndex]?.is_testfinal === true) {
      let pretestScore = parseInt(window.localStorage.getItem('pretest'))
      this.setState({ pretestScore })
    }

    if (this.props.last && this.props.multiple_choice) {
      if (this.props.last[0].id === this.props.multiple_choice[0].id) {
        // console.log("END");
        var objectFound = null
        if (this.props.sessionId) {
          const Data = {
            progression: this.props.progression,
            user: parseInt(this.state.userID),
            session: this.props.sessionId,
          }
          var last = this.state.session.filter(
            (d) =>
              d.id === this.state.session[this.state.session.length - 1].id,
          )[0]

          var elementPos = this.state.session
            .map(function (x) {
              return x.id
            })
            .indexOf(this.props.sessionId)
          objectFound = this.state.session[elementPos + 1]
          this.setState({ endSession: true, selectedId: objectFound?.id })
          // console.log(
          //   "last",
          //   last,
          //   this.state.session[elementPos],
          //   this.props.sessionId
          // );
          if (this.state.session[elementPos]?.id === last?.id) {
            console.log('end')
            this.setState({ endModule: true })
            axios
              .post(api + 'userdosession/', Data, this.state.requestConfig)
              .then((data) => {
                console.log('data', data.data)
              })
              .catch((e) => {
                console.log('e', e.response)
              })
            this.updateUser()
            // window.location = `/tous-les-modules/`;
          } else {
            axios
              .post(api + 'userdosession/', Data, this.state.requestConfig)
              .then((data) => {
                console.log('data', data.data)
              })
              .catch((e) => {
                console.log('e', e.response)
              })
            objectFound = this.state.session[elementPos + 1]
            this.setState({ endSession: true, selectedId: objectFound?.id })
            //window.location = `/session-start/session/${objectFound?.id}`;
          }
        } else {
          objectFound = this.props.sessions[0]
          window.location = `/session-start/session/${objectFound?.id}`
        }
      }
    }
    if (this.props?.a_afficher_sur !== null) {
      setTimeout(() => {
        this.onStoreData()
      }, 2000)
    }

    this.props.onGetUserDoModules()
  }

  updateUser = () => {
    const url = api + 'user/' + parseInt(this.state.userID) + '/'
    axios
      .get(url, this.state.requestConfig)
      .then((val) => {
        //console.log('user', val.data)
        let user = val.data
        if (user.badge < 3) {
          axios
            .put(url, { badge: user.badge + 1 }, this.state.requestConfig)
            .then((data) => {
              //console.log("data", data.data);
              // if (data) {
              //   return (window.location = `/tous-les-modules/`);
              // }
            })
            .catch((e) => {
              console.log('e', e.response)
            })
        } else {
          console.log('badge complet', user.badge)
        }
      })
      .catch((e) => {
        console.log('e', e.response.data)
      })
  }

  _ToggleNext = () => {
    if (this.state.userAnswer.length) {
      if (
        this.state.selectedIndex !==
        this.props.multiple_choice[this.state.selectedQstIndex].list_question
          .length -
          1
      ) {
        this.setState((prevState) => ({
          selectedIndex: prevState.selectedIndex + 1,
          isEnded: false,
          answers: [],
        }))
      } else {
        this.setState({
          isEnded: true,
        })

        return
      }

      this.setState({
        validChoice: true,
        userAnswer: [],
      })
    } else {
      toast.error('Veuillez sélectionner une réponse avant de continuer !')
    }

    //if (this.state.selectedIndex == this.props.multiple_choice[this.state.selectedQstIndex].list_question.length - 1) return;
  }

  _redirect = () => {
    let lastcontenu = []
    if (
      this.props.sessionId !== undefined &&
      this.state.contenubysession.length
    ) {
      lastcontenu = this.state.contenubysession.filter(
        (d) =>
          d?.id ===
          this.state.contenubysession[this.state.contenubysession.length - 1]
            .id,
      )[0]?.list_task
    }
    var last = this.state.session.filter(
      (d) => d.id === this.state.session[this.state.session.length - 1].id,
    )[0]

    var elementPos = this.state.session
      .map(function (x) {
        return x.id
      })
      .indexOf(this.props.sessionId)
    const objectFound = this.state.session[elementPos + 1]
    //this.setState({ endSession: true, selectedId: objectFound?.id });
    if (this.state.session[elementPos]?.id === last?.id) {
      return (window.location = `/tous-les-modules/`)
    } else {
      if (
        this.props.last[0].id === this.props.multiple_choice[0]?.id ||
        lastcontenu[0]?.id === this.props.multiple_choice[0]?.id
      ) {
        return (window.location = `/session-start/session/${objectFound?.id}`)
      }
    }
    this.ScrollTop()
  }

  ScrollTop = () => {
    window.scrollTo(0, 0)
  }

  handleCheckboxChange = (event, data) => {
    let test = this.state.fields[this.state.selectedQstIndex]?.list_question[
      this.state.selectedIndex
    ]?.list_responses.filter((q) => q.is_right_choice === true)
    let checked = [...this.state.checked, data?.id]
    let newArray = [...this.state.answers, event.target.value]
    let resp = [...this.state.userAnswer, data]
    if (this.state.answers.includes(event.target.value)) {
      // console.log("tee");
      newArray = newArray.filter((day) => day !== event.target.value)
      resp = resp.filter((dat) => dat.id !== parseInt(event.target.value))
      test = test.filter((tes) => tes.id !== parseInt(event.target.value))
      checked = checked.filter((tes) => tes !== parseInt(event.target.value))
      console.log('test', test)
      console.log('checked', checked)
    }
    this.setState({
      answers: newArray,
      userAnswer: resp,
      checked,
      rightCount: test.length,
    })

    // this.setUserAnswer(this.state.userAnswer)
  }

  isDisabled = (id) => {
    //console.log(this.state.rightCount);
    if (this.state.rightCount > 0) {
      return (
        this.state.checked.length === this.state.rightCount &&
        this.state.checked.indexOf(id) === -1
      )
    }
  }

  UserDoContenu = () => {
    let checker = (arr) =>
      arr.every((v) => parseInt(v?.contenu?.id) !== this.state.fields?.contenu)
    console.log(
      'check contenu',
      checker(this.props.usercontenus),
      this.state.fields?.contenu,
    )
    // eslint-disable-next-line
    const sendData = {
      user: parseInt(this.state.userID),
      contenu: this.state.fields?.contenu,
    }
    // if (checker(this.props.usercontenus) !== false) {
    //   const url = api + "userdocontenu/";
    //   axios.post(url, sendData, this.state.requestConfig)
    //     .then((data) => {
    //       console.log("data post", data);
    //     })
    //     .catch((e) => {
    //       console.log("e post", e.response.data);
    //     });
    // }
    window.scrollTo(0, 0)
  }

  contenuBySession = async () => {
    const url = api + 'contenubysession/' + this.props.sessionId + '/'
    //console.log("url session", url);
    if (
      this.props.sessionId !== undefined &&
      this.props.moduleId === undefined
    ) {
      await axios.get(url, this.state.requestConfig).then((response) => {
        //console.log("contenu By session", response.data);

        const contenubysession = response.data
        this.setState({ contenubysession })
        //console.log("contenu By session", this.state.contenuBySession);
      })
    } else if (this.props.moduleId !== undefined) {
      await axios
        .get(
          api + 'contenubymodule/' + this.props.moduleId + '/',
          this.state.requestConfig,
        )
        .then((response) => {
          if (response) {
            // console.log("contenu By module", response.data);

            this.setState({ contenubysession: response.data })
          }
          //console.log("contenu By module", this.state.contenuBySession);
        })
    }
  }

  render() {
    //console.log('check', this.state.rightCount);
    // console.log(" answers==>", this.state.responses)
    // if (this.props?.id_contenu !== undefined) {
    //   console.log("id contenu quizz=>", this.props?.id_contenu)
    //   console.log("a afficher sur =>", this.props?.a_afficher_sur);
    // }

    let lastcontenu = []
    let end = false
    // console.log(this.props.sessionId !== undefined, this.state.contenubysession)
    if (
      this.props.sessionId !== undefined &&
      this.state.contenubysession.length
    ) {
      // console.log(this.state.contenubysession, this.props.sessionId)
      lastcontenu = this.state.contenubysession.filter(
        (d) =>
          d?.id ===
          this.state.contenubysession[this.state.contenubysession.length - 1]
            .id,
      )[0]?.list_task
      // console.log(this.state.contenuBySession.filter((d) => d?.id === this.state.contenuBySession[this.state.contenuBySession.length - 1].id)[0]?.list_task, '+', this.props.multiple_choice[0]?.id)
    }
    if (lastcontenu !== undefined) {
      if (lastcontenu[0]?.id === this.props.multiple_choice[0]?.id) {
        end = true
      }
    }
    // console.log('end', this.props.last)

    if (this.state.fields[this.state.selectedQstIndex]?.is_pretest === true) {
      window.localStorage.setItem('pretest', this.state.score)
    }
    const {
      fields,
      selectedIndex,
      selectedQstIndex,
      score,
      totalScore,
      totalQuestions,
      responses,
      // eslint-disable-next-line
      isEndedQuiz,
    } = this.state

    if (fields) {
      return (
        <>
          <div>
            {/* <div className="col-md-10 m-b-5">
              <p className="text-dark fs-14 fw-400 f-f-work">
                {fields[selectedQstIndex]?.title}
              </p>
            </div> */}
            <div className="title">
              <div className="text-dark m-b-5 row">
                <h5 className="quizz-label">
                  {fields[selectedQstIndex]?.title}
                </h5>
              </div>
            </div>

            <div className="content-choices-response-quiz">
              {this.state.isEnded ? (
                <QuizCorrection
                  quiz={fields[selectedQstIndex]}
                  userAnswer={responses}
                  score={score}
                  rightAnswerCount={this.state.rightAnswerCount}
                  totalScore={totalScore}
                  totalQuestions={totalQuestions}
                />
              ) : (
                <>
                  <div className="row m-b-10">
                    {!this.state.isEnded ? (
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 dis-flex justify-content-center itm-center">
                        <p className="fs-14 fw-400 text-black f-f-work m-b-5 question-counter">
                          Questions {selectedIndex + 1}/
                          {fields[selectedQstIndex]?.list_question.length}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <h5 className="h-theme-light m-b-10">
                    {
                      fields[selectedQstIndex]?.list_question[selectedIndex]
                        ?.content
                    }
                  </h5>
                  {fields[selectedQstIndex]?.list_question[
                    selectedIndex
                  ]?.list_responses.map((response, index) => {
                    return (
                      <React.Fragment key={response?.id}>
                        <div className="custom-control custom-radio custom-control-inline input-radio-quiz col-md-12">
                          <input
                            type={
                              this.state.rightCount === 1 &&
                              this.state.rightCount > 0
                                ? 'radio'
                                : 'checkbox'
                            }
                            className="custom-control-input"
                            id={`${response.id}`}
                            value={response.id}
                            name={
                              this.state.rightCount === 1 &&
                              this.state.rightCount > 0
                                ? `${fields[selectedQstIndex]?.list_question[selectedIndex]?.id}`
                                : 'example'
                            }
                            onChange={(e) =>
                              this.handleCheckboxChange(e, response)
                            }
                          />

                          <label
                            className="custom-control-label"
                            htmlFor={`${response.id}`}
                          >
                            {response.content}
                          </label>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </>
              )}

              <div className="dis-flex justify-content-start m-t-10 m-b-5">
                {this.state.selectedIndex !==
                this.props.multiple_choice[this.state.selectedQstIndex]
                  ?.list_question.length -
                  1 ? (
                  this.state.answers.length && !this.state.isEnded ? (
                    <button
                      className="btn-theme"
                      onClick={this.setUserAnswer}
                      data-slide="next"
                    >
                      Valider
                    </button>
                  ) : (
                    <button
                      className="btn-theme disabled"
                      onClick={this.setUserAnswer}
                      data-slide="next"
                      disabled
                    >
                      Valider
                    </button>
                  )
                ) : this.state.answers.length && !this.state.isEnded ? (
                  <button
                    className="btn-theme"
                    onClick={this.setUserAnswer}
                    data-toggle="modal"
                    data-target={`#quizSuccess${fields[selectedQstIndex]?.id}`}
                  >
                    Valider
                  </button>
                ) : (
                  <button
                    className="btn-theme disabled"
                    onClick={this.setUserAnswer}
                    data-slide="next"
                    disabled
                  >
                    Valider
                  </button>
                )}
                {/* {this.state.answers.length && !this.state.isEnded ? (
                  <button
                    className="btn-theme"
                    onClick={this.setUserAnswer}
                    data-slide="next"
                  >
                    Valider
                  </button>
                ) : null} */}
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id={`quizSuccess${fields[selectedQstIndex]?.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-notify modal-success"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                    <FeatherIcon
                      color="green"
                      icon="check-circle"
                      className="mb-3 animat rotateIn"
                    />
                    <h4 className="parag fs-20 fw-600">
                      {totalScore !== 0 ? (
                        <>
                          Tu as obtenu{' '}
                          <span className="scores">
                            {this.state.score} / {totalScore} points
                          </span>
                        </>
                      ) : null}
                    </h4>
                    <p className="pop-title fw-600">
                      {totalScore !== 0
                        ? !fields[selectedQstIndex]?.is_pretest &&
                          !fields[selectedQstIndex]?.is_testfinal
                          ? (this.state.score * 100) / totalScore <= 49
                            ? 'Oups ! Peut-être que tu dois répéter certains contenus ? Mais ne t’en fais pas, tu as encore le temps de t’améliorer !'
                            : (this.state.score * 100) / totalScore >= 50 &&
                              (this.state.score * 100) / totalScore <= 59
                            ? 'Bravo ! Tu peux encore t’améliorer, mais c’est un bon début ! '
                            : (this.state.score * 100) / totalScore >= 60 &&
                              (this.state.score * 100) / totalScore <= 90
                            ? 'Félicitations ! Tu as déjà beaucoup appris. Continue comme ça !'
                            : (this.state.score * 100) / totalScore === 100
                            ? this.props.contenu?.message ||
                              'Wow, génial ! Tu as atteint le meilleur score. Félicitations !'
                            : 'Wow, génial ! Tu as atteint le meilleur score. Félicitations !'
                          : fields[selectedQstIndex]?.is_pretest
                          ? (this.state.score * 100) / totalScore <= 49
                            ? 'Ne t’inquiète pas ! Tu vas encore apprendre beaucoup de choses sur le sujet durant cette session et tu pourras ensuite repasser le test. D’ici là, tu en sauras déjà plus !'
                            : (this.state.score * 100) / totalScore >= 50 &&
                              (this.state.score * 100) / totalScore <= 59
                            ? " Bravo ! Tu as déjà des connaissances dans ce domaine. Lors de cette session, il te sera possible d'approfondir tes connaissances et d'améliorer encore tes compétences. "
                            : (this.state.score * 100) / totalScore >= 60 &&
                              (this.state.score * 100) / totalScore <= 90
                            ? 'Félicitations ! Tu en sais déjà beaucoup sur le sujet. Profite bien de cette session pour devenir un expert dans le domaine.'
                            : (this.state.score * 100) / totalScore === 100
                            ? this.props.contenu?.message ||
                              'Super, bien joué! Tu en sais déjà beaucoup sur le sujet. Voyons ce que tu vas encore apprendre dans cette session.'
                            : 'Super, bien joué! Tu en sais déjà beaucoup sur le sujet. Voyons ce que tu vas encore apprendre dans cette session.'
                          : (this.state.score * 100) / totalScore <= 49
                          ? this.state.score < this.state.pretestScore
                            ? "Oups ! Que s'est-il passé ? Tu as obtenu moins de points que lors du pré-test. Peut-être devrais-tu revenir en arrière et répéter certaines parties de la session et ressayer le test ?"
                            : this.state.score === this.state.pretestScore
                            ? 'Comme au prétest ! Peut-être souhaites-tu répéter certaines parties du module pour essayer d’améliorer ton score ?'
                            : 'C’est bien, tu as déjà amélioré ton score par rapport au pré-test! Mais peut-être souhaites-tu répéter certaines parties du module pour essayer d’encore améliorer ton score ?'
                          : (this.state.score * 100) / totalScore >= 50 &&
                            (this.state.score * 100) / totalScore <= 59
                          ? this.state.score < this.state.pretestScore
                            ? "Oups ! Que s'est-il passé ? Tu as obtenu moins de points que lors du pré-test. Peut-être devrais-tu revenir en arrière et répéter certaines parties de la session et ressayer le test ?"
                            : this.state.score === this.state.pretestScore
                            ? 'Comme au prétest ! Peut-être souhaites-tu répéter certaines parties du module pour essayer d’améliorer ton score ?'
                            : 'C’est bien, méliorer ton score ?'
                          : (this.state.score * 100) / totalScore >= 60 &&
                            (this.state.score * 100) / totalScore <= 90
                          ? this.props.contenu?.message
                            ? this.props.contenu?.message
                            : this.state.score < this.state.pretestScore
                            ? "Oups ! Que s'est-il passé ? Tu as obtenu moins de points que lors du pré-test. Cependant tu sembles déjà bien maitriser le sujet. Si tu veux essayer d’améliorer ton score, tu peux peut-être réviser certaines parties de la session."
                            : this.state.score === this.state.pretestScore
                            ? 'Comme au prétest ! Tu sembles bien maitriser le sujet. Si tu veux essayer d’améliorer ton score, tu peux peut-être réviser certaines parties de la session.'
                            : 'Félicitations, bien joué ! Tu as amélioré ton score et tu as démontré que tu es devenu un vrai expert sur le sujet. Continue comme ça !'
                          : this.state.score < this.state.pretestScore ||
                            this.state.score === this.state.pretestScore
                          ? 'Comme au prétest ! Tu es un expert confirmé dans la matière ! Bravo, continue comme ça !'
                          : 'Félicitations, tu es à présent un expert dans la matière ! Bravo, continue comme ça !'
                        : this.props.contenu?.message || 'Tes réponses ont été soumises ! Bravo pour avoir terminé cet exercice ! '}
                    </p>
                  </div>
                </div>
                <div className="modal-footer justify-content-center slider-actions-btn">
                  {fields[selectedQstIndex]?.is_pretest ? null : totalScore !==
                    0 ? (
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-theme fw-400 fs-15 justify-content-start"
                      onClick={this._ToggleNext}
                    >
                      VOIR LE RESULTAT{' '}
                    </button>
                  ) : null}
                  {!this.state.endSession ? (
                    <a
                      type="button"
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      role="button"
                      data-slide="next"
                    >
                      CONTINUER
                    </a>
                  ) : (
                    <button
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target={`#end${fields[selectedQstIndex]?.id}`}
                    >
                      CONTINUER
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row dis-flex justify-content-end m-b-10 m-t-1 slider-actions-btn">
            <div className="col-md-3 col-sm-6 m-b-10">
              <a
                className="btn-theme-light dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                role="button"
                data-slide="prev"
              >
                Précédent
              </a>
            </div>
            <div className="col-md-3 col-sm-6 m-b-10">
              {this.state.isDoing === true || this.state.isEnded === true ? (
                this.props.last && this.props.multiple_choice ? (
                  this.props.last[0]?.id !==
                    this.props.multiple_choice[0]?.id && !end ? (
                    <button
                      type="button"
                      className="btn-theme dis-flex justify-content-center fw-600"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      data-slide="next"
                    >
                      Suivant
                    </button>
                  ) : (
                    <button
                      className="btn-theme dis-flex justify-content-center fw-600"
                      data-toggle="modal"
                      data-target={`#end${fields[selectedQstIndex]?.id}`}
                    >
                      Suivant
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className="btn-theme dis-flex justify-content-center fw-600"
                    data-dismiss="modal"
                    href="#carouselSliderContentDetailsSession"
                    data-slide="next"
                  >
                    Suivant
                  </button>
                )
              ) : (
                <button
                  className="btn-theme disabled dis-flex justify-content-center fw-600"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  disabled
                >
                  Suivant
                </button>
              )}
            </div>
          </div>

          {/* Session End Modal */}
          <div
            className="modal fade"
            id={`end${fields[selectedQstIndex]?.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-notify modal-success"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                    <FeatherIcon
                      color="green"
                      icon="check-circle"
                      className="mb-3 animat rotateIn"
                    />
                    <h4 className="pop-title fw-600">FIN DE LA SESSION !!!</h4>
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <button
                    data-dismiss="modal"
                    className="btn btn-theme fw-400 fs-15 justify-content-start"
                    onClick={this._redirect}
                  >
                    CONTINUER{' '}
                  </button>
                  {/* <a
                      type="button"
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      role="button"
                      data-slide="next"
                    >
                      CONTINUER
                    </a> */}
                </div>
              </div>
            </div>
          </div>
          {/* END Modal */}
          {/* Module End Modal */}
          <div
            className="modal fade"
            id={`endMSuccess${fields[selectedQstIndex]?.id}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-notify modal-success"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="text-center">
                    {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                    <FeatherIcon icon="award" className="mb-3 badge rotateIn" />
                    <h4 className="pop-title fw-600">
                      Fin du Module !!! Tu as obtenu un badge{' '}
                    </h4>
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a
                    className="btn btn-theme-light fw-400 fs-15 justify-content-start"
                    href="/tous-les-modules"
                  >
                    CONTINUER{' '}
                  </a>
                  {/* <a
                      type="button"
                      className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                      data-dismiss="modal"
                      href="#carouselSliderContentDetailsSession"
                      role="button"
                      data-slide="next"
                    >
                      CONTINUER
                    </a> */}
                </div>
              </div>
            </div>
          </div>
          {/* END Modal */}
        </>
      )
    }
  }
}
