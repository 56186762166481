import React, { Component } from 'react';
import FeatherIcon from 'feather-icons-react';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import api from '../../http/global-vars';
import axios from 'axios'
import FicheEvaluationSkeleton from '../AdminSkeletons/FicheEvaluationSkeleton';
import { getMonth } from '../../utils';
import moment from 'moment';

let order = 'desc';
const products = [
  {
    id: 1,
    fullname: 'Moumadjad Ahmed Abdou',
    email: 'moumadjad.a@gmail.com',
    formation_attentes: 'Oui, en partie',
    questions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    reponses: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    genre: 'H',
    design_graphique: '08',
    facilite_utilisation: '10',
    accessibilite: '02',
    clarte_contenu: '06',
    clarte_contenu_pratique: '03',
    qualite_support: '10',
    clarte_consigne: '05'
  },
  {
    id: 2,
    fullname: 'Moumadjad Ahmed Abdou',
    email: 'moumadjad.a@gmail.com',
    formation_attentes: 'Oui, en partie',
    questions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    reponses: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    genre: 'H',
    design_graphique: '04',
    facilite_utilisation: '01',
    accessibilite: '01',
    clarte_contenu: '06',
    clarte_contenu_pratique: '03',
    qualite_support: '10',
    clarte_consigne: '05'
  },
  {
    id: 3,
    fullname: 'Moumadjad Ahmed Abdou',
    email: 'moumadjad.a@gmail.com',
    formation_attentes: 'Oui, en partie',
    questions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    reponses: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    genre: 'H',
    design_graphique: '06',
    facilite_utilisation: '09',
    accessibilite: '02',
    clarte_contenu: '06',
    clarte_contenu_pratique: '03',
    qualite_support: '10',
    clarte_consigne: '05'
  },
  {
    id: 4,
    fullname: 'Moumadjad Ahmed Abdou',
    email: 'moumadjad.a@gmail.com',
    formation_attentes: 'Oui, en partie',
    questions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    reponses: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim massa eu mattis egestas.',
    genre: 'H',
    design_graphique: '01',
    facilite_utilisation: '09',
    accessibilite: '04',
    clarte_contenu: '06',
    clarte_contenu_pratique: '03',
    qualite_support: '10',
    clarte_consigne: '05'
  }
]

export default class FicheEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem('userToken'),
      requestConfig: {
        headers: { Authorization: `Bearer ${window.localStorage.getItem('userToken')}` }
      },
      api: api,
      userID: window.localStorage.getItem('userID'),
      isPageLoading: true,
      listEvaluated: [],
    }
  }

  componentDidMount() {
    this.getEvaluatedList()
  }

  getEvaluatedList = async () => {
    const url = api + 'evaluation';
    await axios.get(url, this.state.requestConfig).then((response) => {
      /* listIdentified: _.uniqBy(response.data.results, function (e) {
        return e.user.id;
      }) */
      // console.log("response evaluation"
      //   , response.data.results)
      const data = response.data.results.filter(q => q.user?.id !== 1 && q.user?.id !== 18
        && q.user?.id !== 22 && q.user?.id !== 33 && q.user?.id !== 39 && q.user?.id !== 160 && q.user?.id !== 168 && q.user?.id !== 169
        && q.user?.id !== 170 && q.user?.id !== 170 && q.user?.id !== 171 && q.user?.id !== 172 && q.user?.id !== 173 && q.user?.id !== 173 && q.user?.id !== 174
        && q.user?.id !== 1315 && q.user?.id !== 1319 && q.user !== null);
      // console.log('eval', data)
      this.setState({
        listEvaluated: data,
        isPageLoading: false
      })

      /*  setTimeout(() => {
         this.setState({
           isPageLoading: false
         })
       }, 2000); */
    }).catch((e) => {
      console.log('error', e)
    })
  }


  handleBtnClick = () => {
    if (order === 'desc') {
      this.refs.table.handleSort('asc', 'name');
      order = 'asc';
    } else {
      this.refs.table.handleSort('desc', 'name');
      order = 'desc';
    }
  }

  showFullname = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user?.first_name + ' ' + row.user?.last_name;
  }

  showGender = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user?.sexe;
  }

  showEmail = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user?.email;
  }

  createCustomButtonGroup = props => {
    return (
      <ButtonGroup className='export-btn fs-14 fw-400 py-2 px-4' sizeClass='btn-group-md'>
        {props.exportCSVBtn}
      </ButtonGroup>
    );
  }

  showDateNaissance = (cell, row) => {
    return row.user?.date_naissance
  }

  showMonth = (cell, row) => {
    console.log('row', row)
    let formatter = new Intl.DateTimeFormat("FR", {
      month: "long",
    })
    return formatter.format(Date.parse(row?.user?.date_joined))
  }

  showYear = (cell, row) => {
    return moment(row?.user?.date_joined).format("YYYY")
  }

  showAdresse = (cell, row) => {
    return row.user?.adresse
  }

  render() {
    const { listEvaluated, isPageLoading } = this.state;
    // console.log('fiche evaluation=> ', listEvaluated)
    const options = {
      noDataText: 'Aucune donnée disponible',
      btnGroup: this.createCustomButtonGroup,
      exportCSVText: 'Extraction'
    };
    return (
      <div className="item-card-extraction">
        <img className="icon-item-card-extraction" src="img/icons/paper.png" alt="" />
        <p className="text-item-card-extraction">Fiche d’évaluation</p>
        <button type="button" class="btn-item-card-extraction" data-toggle="modal" data-target="#ficheEvaluation">
          voir
        </button>

        <div class="modal fade" id="ficheEvaluation" tabindex="-1" aria-labelledby="ficheEvaluationLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content pt-5 pb-3">
              <div className="px-md-4 px-3">
                <p className="titre-card-dashboard-sans-padding m-b-15">Liste des apprenants ayant rempli la fiche d’evaluation <span className="certified-count"> ({listEvaluated.length}) </span> </p>
                <div className="row mt-5">
                  {/* <div className="col-xl-4 col-lg-6 mb-3">
                    <from>
                      <button className="btn-theme fs-14 fw-400 py-2 px-4">
                        <FeatherIcon className="icon-btn-add-dashboard-orange" icon="download" />
                        Extraction
                      </button>
                    </from>
                  </div> */}
                  <div className="col-xl-4 col-lg-6 mb-3">
                    {/* <div className="row">
                      <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                        <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                          <option selected>2020</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        </div>
                        <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                          <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                            <option selected>Décembre</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                    </div> */}
                  </div>
                  <div className="col-xl-4 mb-3">

                  </div>
                </div>
              </div>

              <div className="table-container">
                {isPageLoading ?
                  (
                    <FicheEvaluationSkeleton />
                  ) :
                  (
                    <BootstrapTable ref='table'
                      data={listEvaluated}
                      striped={true}
                      hover={true}
                      condensed={true}
                      multiColumnSort={2}
                      pagination
                      exportCSV
                      options={options}
                      version='4'
                      bordered={false}>

                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                        width="80" row='0' rowSpan='2' dataField='id' isKey={true}>N°</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal' }}
                        width="230" row='0' rowSpan='2' dataAlign="center"
                        dataField="FullName" csvFormat={this.showFullname} dataSort={true} dataFormat={this.showFullname}>Prénom & Nom</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal' }}
                        width="230" row='0' rowSpan='2' dataAlign="center"
                        csvFormat={this.showDateNaissance} dataField="date_de_naissance" dataSort={true} dataFormat={(row, cell) => this.showDateNaissance(row, cell)}>Date de naissance</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', whiteSpace: 'normal' }}
                        width="230" row='0' rowSpan='2' dataAlign="center"
                        csvFormat={this.showAdresse} dataField="adresse" dataSort={true} dataFormat={(row, cell) => this.showAdresse(row, cell)}>Adresse</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                        width="250" row='0' rowSpan='2' dataField="email" csvFormat={this.showEmail} dataAlign="center" dataSort={true} dataFormat={this.showEmail}>Adresse Mail</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', textAlign: 'center' }}
                        width="140" row='0' rowSpan='2' dataAlign="center" dataField="sexe" csvFormat={this.showGender} dataSort={true} dataFormat={this.showGender}>Genre</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', textAlign: 'center' }}
                        filter={{ type: 'TextFilter' }} dataAlign="center" filterValue={this.showMonth} csvFormat={this.showMonth}
                        width="230" row='0' dataField="Mois" rowSpan='2' dataSort={true} dataFormat={(row, cell) => this.showMonth(row, cell)}>Mois</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none', textAlign: 'center' }}
                        filter={{ type: 'TextFilter' }} dataAlign="center" filterValue={this.showYear} csvFormat={this.showYear}
                        width="230" row='0' dataField="Mois" rowSpan='2' dataSort={true} dataFormat={(row, cell) => this.showYear(row, cell)}>Année</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none', whiteSpace: 'normal', lineHeight: 1.2 }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                        width="300" row='0' rowSpan='2' dataField='attente'>La formation a-t-elle répondu à vos attentes ?</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 15, color: '#1F497D', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', lineHeight: 1.2, background: '#EEEEEE', padding: '10px 5px', textAlign: 'center' }}
                        row='0' colSpan='3'>Comment évaluez-vous la plateforme www.defaru.sn ?</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 300, fontSize: 12, color: '#000000', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', textAlign: 'center', padding: '10px 5px' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', textAlign: 'center' }}
                        width="200" row='1' dataAlign="center" dataField='easy_to_use'>Facilité d’utilisation</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 300, fontSize: 12, color: '#000000', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', textAlign: 'center', padding: '10px 5px' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', textAlign: 'center' }}
                        width="230" row='1' dataAlign="center" dataField='design_quality'>Qualité du design graphique  </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 300, fontSize: 12, color: '#000000', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', textAlign: 'center', padding: '10px 5px' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', textAlign: 'center' }}
                        width="230" row='1' dataAlign="center" dataField='platform_access'>Accessibilité de la plateforme</TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 15, color: '#1F497D', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', lineHeight: 1.2, background: '#EEEEEE', padding: '10px 5px', textAlign: 'center' }}
                        row='0' colSpan='4'>Comment évaluez-vous la formation dont vous avez bénéficié ?</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 300, fontSize: 12, color: '#000000', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', textAlign: 'center', padding: '10px 5px' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', textAlign: 'center' }}
                        width="200" row='1' dataAlign="center" dataField='formation_clarity'>Clarté du contenu</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 300, fontSize: 12, color: '#000000', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', textAlign: 'center', padding: '10px 5px' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', textAlign: 'center' }}
                        width="230" row='1' dataAlign="center" dataField='exercise_clarity'>Clarté du contenu pratique  </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 300, fontSize: 12, color: '#000000', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', textAlign: 'center', padding: '10px 5px' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', textAlign: 'center' }}
                        width="230" row='1' dataAlign="center" dataField='support_quality'>Qualité des supports </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 300, fontSize: 12, color: '#000000', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', borderBlock: 'none', textAlign: 'center', padding: '10px 5px' }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', borderLeft: '1px solid #C4C4C4', borderRight: '1px solid #C4C4C4', textAlign: 'center' }}
                        width="230" row='1' dataAlign="center" dataField='instruction_clarity'>Clarté des consignes</TableHeaderColumn>

                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none', whiteSpace: 'normal', lineHeight: 1.2 }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                        width="800" row='0' rowSpan='2' dataAlign="center" dataField='agr'>4.	Pensez-vous que cette formation vous a assuré des possibilités d’insertion professionnelle ou de création d’activité génératrice de revenu (AGR) ?  </TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none', whiteSpace: 'normal', lineHeight: 1.2 }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                        width="300" row='0' rowSpan='2' dataAlign="center" dataField='project_future'>Projets concrets d’avenir</TableHeaderColumn>
                      <TableHeaderColumn
                        thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none', whiteSpace: 'normal', lineHeight: 1.2 }}
                        tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                        width="300" row='0' rowSpan='2' dataAlign="center" dataField='example_project'>Exemples concrets</TableHeaderColumn>
                    </BootstrapTable>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
