import React from "react";

const ModuleCard = ({ moduleNumber, moduleName, evolutionData }) => {
  return (
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2 mb-3">
      <div className="item-card-performances mb-4">
        <p className="titre-item-card-performances mb-5">
          <img
            className="icon-item-card-performances"
            alt=""
            src="img/icons/Icon-performances-item.png"
          />
          {moduleName}
        </p>
        <div className="row px-2">
          {evolutionData.map((session, index) => (
            <div
              key={index}
              className={`col-xl-6 col-md-6 col-sm-4 px-2 mb-2 ${
                index > 2 ? "p-t-6" : ""
              } text-center`}
            >
              <p className="chiffre-item-card-performances">
                {session.evolue}
                <span className="child-chiffre-item-card-performances">
                  ({session.pourcentage}%)
                </span>
              </p>
              <p className="p-item-card-performances">Session {index + 1}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModuleCard;
