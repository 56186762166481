import React, { Component } from 'react';
// import FeatherIcon from 'feather-icons-react';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
import api from '../../http/global-vars';
// import { calcTime } from '../../utils';
import Skeleton from "react-loading-skeleton";

export default class SessionStartInfos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleId: window.localStorage.getItem('moduleId'),
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      sessions: [],
      modulesDoingInfos:[],
      progression: 0,
      moduleName: '',
      loading: false,
      moduleInfos: [],
      sessionsByModule: [],

    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.onRef(this);
    const timer = setTimeout(() => {
       this.getModuleById();
       this.onGetSessionsByModule()
       this.onGetUserDoModules();
    }, 3000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

  onGetUserDoModules = () => {
    this.setState({
      isPageLoading: true,
    });

    var userID = this.state.userID;
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(`${api}usermodules/${userID}/`, config)
      .then((response) => {
        this.setState({
          isPageLoading: false,
        });
        if (response.status === 200 || response.status === 201) {
        
          var infos = response.data.data
          // console.log('infos', infos)
          for (let i = 0; i < infos.length; i++) {
              const element = infos[i];
              //console.log(element);
              if (element.module.id === parseInt(this.state.moduleId)) {                
                this.setState({
                  progression: element.progression,
                  moduleInfos: element.module
                })
                // console.log(this.state.moduleInfos);
              }
          }
          this.setState({ loading: false });
        } else {
          // console.log(response)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getModuleById = () => {
    // console.log(this.props.moduleId)
      if (this.state.moduleId) {
        const url = api + "module/" + this.state.moduleId;
        axios
          .get(url, this.state.requestConfig)
          .then((response) => {
            // console.log("response session", response.data);
            //console.log(response)
            if (response.data) {
              //this.getSessionByModule(response.data.id);
              this.setState({ moduleName: response.data.name });
            }
            
          })
          .catch((e) => {
            console.log("e", e.response);
          });
      }
  }

  onGetSessionsByModule = () => {
    var api = this.state.api
    var config = this.state.requestConfig
    var moduleId = this.state.moduleId

    axios.get(`${api}sessionByModule/${moduleId}/`, config)
      .then(response => {
        if(response.status === 200 || response.status === 201){
          this.setState({
            sessionsByModule: response.data.sort((a,b) => a?.id - b?.id)
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  reload = () => {
      window.location.reload(false)
  }

  render() {
    return (
      <>
        {!this.state.loading ? (
          <div className="card-info-component-cours-start">
            <p className="f-f-inter fs-18 fw-600 text-black m-b-30">
              Progression
            </p>
            <div className="row dis-flex m-b-100">
              <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2">
                <img
                  className="icon-cours-info-component-cours-start"
                  src="/img/icons/icon-cours.png"
                  alt=""
                />
              </div>

              <div className="col-xl-9 col-lg-10 col-md-10 col-sm-10">
                <p className="f-f-inter text-black fs-16 fw-600">
                  {" "}
                  {this.state.moduleInfos.name}
                </p>
                {/* <p className="f-f-inter fs-14 fw-400">1H 30</p> */}
                <div className="row m-t-5">
                  <div className="col-9">
                    <div className="progress">
                      <div
                        className="progress-bar bg-theme-light"
                        role="progressbar"
                        style={{ width: `${this.state.progression}%` }}
                        // eslint-disable-next-line 
                        aria-valuenow={`${this.state.progression}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="porcentage-progressbar-module-details-page-cours">{`${this.state.progression}%`}</p>
                  </div>
                </div>
              </div>
            </div>

            <p className="f-f-inter fs-18 fw-600 text-black m-b-30">
              Sessions à venir
            </p>
            <ol className="ol-menu-lecon-a-venir">
              {this.state.sessionsByModule.map((item, i) => {
                return (
                  <li className="b-b-1" key={item.id} onClick={this.reload}>
                    <NavLink to={`/session-start/${"session"}/${item.id}`} className="link-menu-lecon-a-venir">
                      <span className="elipsis p-r-10 nowrap sessionTitle">
                        {i + 1}- {item.name}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
            </ol>
          </div>
        ) : (
          <div className="card-info-component-cours-start">
            <p className="f-f-inter fs-18 fw-600 text-black m-b-30">
              <Skeleton height={30} width={`50%`} />
            </p>
            <div className="row dis-flex m-b-100">
              <div className="col-xl-3 col-lg-2 col-md-2 col-sm-2">
                <Skeleton height={30} width={`50%`} />
              </div>

              <div className="col-xl-9 col-lg-10 col-md-10 col-sm-10">
                <p className="f-f-inter text-black fs-16 fw-600">
                  {" "}
                  <Skeleton height={33} width={`50%`} />
                </p>
                {/* <p className="f-f-inter fs-14 fw-400">1H 30</p> */}
                <div className="row m-t-5">
                  <div className="col-9">
                    <div className="progress">
                      <div
                        className="progress-bar bg-theme-light"
                        role="progressbar"
                        style={{ width: `${this.state.progression}%` }}
                        // eslint-disable-next-line 
                        aria-valuenow={`${this.state.progression}`}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p className="porcentage-progressbar-module-details-page-cours">
                      <Skeleton height={10} width={`80%`} />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p className="f-f-inter fs-18 fw-600 text-black m-b-30">
              <Skeleton height={30} width={`50%`} />
            </p>
            <ol className="ol-menu-lecon-a-venir">
              {Array(4)
                .fill()
                .map((item, i) => {
                  return (
                    <li className="b-b-1" key={i}>
                      <NavLink to="#" className="link-menu-lecon-a-venir">
                        <span className="elipsis p-r-10 nowrap sessionTitle">
                          <Skeleton height={30} width={`50%`} />
                        </span>
                        <span className="position-time-menu-lecon-a-venir">
                          <Skeleton height={30} width={`10%`} />
                        </span>
                        {/* <img
                                    className="icon-after-time-menu-lecon-a-venir"
                                    src="/img/icons/valide-cours.png"
                                    alt=""
                                  /> */}
                      </NavLink>
                    </li>
                  );
                })}
            </ol>
          </div>
        )}
      </>
    );
  }
}
