import React, { Component } from 'react';
import ItemProgressionModalDetailsApprenant from './ItemProgressionModalDetailsApprenant';
import CertificatCard from './CertificateCard';
import api from './../../http/global-vars'
import axios from 'axios'
import Doc from '../../frontend/dashboards/DocService';
import { formatDateHistoriq } from '../../utils'
import FeatherIcon from 'feather-icons-react';

export default class ModalDetailsApprenant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newProps: {},
            is_active_fch: true,
            is_active_prg: false,
            is_active_hist: false,
            is_active_certif: false,
            api: api,
            config: {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
                },
            },
            isDateOver: false,
            certificatHtml: '',
            historiq: [],
            data: {},
            historiqPerpage: 5,
            currentPage: 1,
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps?.apprenantInfos !== this.props?.apprenantInfos) {
            // console.log("next props==>", nextProps?.apprenantInfos);           
            let data = nextProps?.apprenantInfos
            // console.log(data);
            this.setState({
                newProps: data,
            })
            // console.log(this.state.data)            
            await this.getUserHistorique(nextProps?.apprenantInfos?.id)
            await this._getCertificat(nextProps?.apprenantInfos?.id)
            this.setState({
                is_active_fch: true,
                is_active_prg: false,
                is_active_hist: false,
                is_active_certif: false
            })
        }
    }

    getUserHistorique = async (userID) => {
        let { api, config } = this.state;
        let url = `${api}loginbyuser/${userID}/`;
        try {
            const req = await axios.get(url, config);
            // console.log("request response user historique==>", req);
            this.setState({ historiq: req['data'] })
        } catch (error) {
            console.log(error.message);
        }
    }

    _getCertificat = async (userID) => {
        let { api, config } = this.state;
        let url = `${api}createpdfcertificate/${userID}/`;
        try {
            const req = await axios.get(url, config);
            // console.log("response certificat pdf==>", req['data']);
            this.setState({ data: req['data'] })
        } catch (error) {
            console.log(error.message);
        }
    }

    onGetApprenantCertificat = async (userID) => {
        let { api, config } = this.state;
        let url = `${api}generatecertificate/${userID}/`;
        try {
            const req = await axios.get(url, config);
            // console.log("request response==>", req);
            if (req['status'] === 200 || req['status'] === 201) {
                if (req['data'].status === 'failure' && req['data'].message === 'Vous ne pouvez pas acceder au certificat la date de fin de la cohorte est passée') {
                    this.setState({
                        isDateOver: true
                    })
                } else if (req['data'].status === 'success' && req['data'].message === 'il peut pas acceder au certificat pas assez de badges') {
                    this.setState({ text: req['data'].message })
                } else {
                    this.setState({
                        certificatHtml: req['data']
                    })
                }
            }
        } catch (error) {
            console.log(error.message)
            console.log(error.response)
        }
    }

    createPdf = (html) => Doc.createPdf(html);


    onTabClick = (e) => {
        if (e.target.name === 'fiche') {
            this.setState({
                is_active_fch: true,
                is_active_prg: false,
                is_active_hist: false,
                is_active_certif: false
            })
        }
        if (e.target.name === 'progression') {
            this.setState({
                is_active_fch: false,
                is_active_prg: true,
                is_active_hist: false,
                is_active_certif: false
            })
        } else if (e.target.name === 'historique') {
            this.setState({
                is_active_fch: false,
                is_active_prg: false,
                is_active_hist: true,
                is_active_certif: false
            })
        } else if (e.target.name === 'certificat') {
            this.setState({
                is_active_certif: true,
                is_active_fch: false,
                is_active_prg: false,
                is_active_hist: false,
            })
        }
    }

    paginate = (event) => {
        event.preventDefault();
        this.setState({ currentPage: Number(event.target.textContent) });
    }


    render() {
        // console.log("infos apprenant", this.props?.list_apprenants)
        // let filter = this.props?.list_apprenants.filter((f) => f.id === this.props?.apprenantInfos.id);
        // console.log(filter)
        // console.log("apprenantInfos==>", this.props?.apprenantInfos)
        // console.log("response historique==>", this.state?.historiq?.count)
        // console.log("new props=>",this.state?.newProps)
        let { is_active_fch, is_active_hist, is_active_prg, is_active_certif, historiq, historiqPerpage, currentPage } = this.state
        const indexOfLast = currentPage * historiqPerpage;
        const indexOfFirst = indexOfLast - historiqPerpage;
        let current_historiq = [];
        let renderHistoriq = [];

        current_historiq = historiq !== undefined ? historiq?.results?.slice(indexOfFirst, indexOfLast) : null;
        renderHistoriq = current_historiq?.map(h => (
            <tr key={h.id}>
                <td className="historique-apprenant fw-500">Connexion {h?.device === "" ? ("Inconnue") : h?.device}</td>
                <td className="historique-apprenant text-right">{formatDateHistoriq(h?.created_at)}</td>
            </tr>
        ))

        const pageNumbers = [];
        let tab_historiq = this.state.historiq?.results;
        if (tab_historiq !== undefined) {
            for (let i = 1; i <= Math.ceil(tab_historiq?.length / historiqPerpage); i++) {
                pageNumbers.push(i);
            }
        }

        const renderPageNumbers = pageNumbers.map((number) => {
            return (
                <li key={number}
                    className={`page-item ${this.state.currentPage === number ? `at-active` : ""}`}>
                    <a href="!#" onClick={this.paginate} className="page-link">{number}</a>
                </li>
            );
        });

        return (
            <div>
                <p className="titre-modal-details-apprenant"> {this.props.apprenantInfos.first_name + ' ' + this.props.apprenantInfos.last_name} </p>
                <ul className="nav nav-tabs" id={`myTab-${this.props?.id}`} role="tablist">
                    <li className="nav-item">
                        <a onClick={this.onTabClick} name="fiche" className={`nav-link nav-link-modal-details-apprenant ${is_active_fch ? 'active' : ''}`} id={`fiche-identite-tab-${this?.props?.apprenantInfos?.id}`} data-toggle="tab" href={`#fiche-identite-${this?.props?.apprenantInfos?.id}`} role="tab" aria-controls="fiche-identite" aria-selected="true">Fiche d’identité</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={this.onTabClick} className={`nav-link nav-link-modal-details-apprenant ${is_active_prg ? 'active' : ''}`} name='progression' id={`progression-tab-${this?.props?.apprenantInfos?.id}`} data-toggle="tab" href={`#progression-${this?.props?.apprenantInfos?.id}`} role="tab" aria-controls="progression" aria-selected="false">Progresssion</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={this.onTabClick} name='certificat' className={`nav-link nav-link-modal-details-apprenant ${is_active_certif ? 'active' : ''}`} id={`certificat-tab-${this?.props?.apprenantInfos?.id}`} data-toggle="tab" href={`#certificat-${this?.props?.apprenantInfos?.id}`} role="tab" aria-controls="certificat" aria-selected="false">Certificat</a>
                    </li>
                    <li className="nav-item">
                        <a onClick={this.onTabClick} className={`nav-link nav-link-modal-details-apprenant ${is_active_hist ? 'active' : ''}`} name='historique' id={`historique-tab-${this?.props?.apprenantInfos?.id}`} data-toggle="tab" href={`#historique-${this?.props?.apprenantInfos?.id}`} role="tab" aria-controls="historique" aria-selected="false">Historique</a>
                    </li>
                </ul>
                <div className="tab-content tab-content-custom" id={`myTabContent-${this.props?.id}`}>
                    <div className={`tab-pane fade ${is_active_fch ? 'show active' : ''}`} id={`fiche-identite-${this?.props?.apprenantInfos.id}`} role="tabpanel" aria-labelledby={`fiche-identite-tab-${this?.props?.apprenantInfos?.id}`}>
                        <ul className="ul-modal-details-apprenant">
                            <li className="li-modal-details-apprenant">
                                <p className="text-content-modal-details-apprenant">Civilité</p>
                                <p className="text-content-modal-details-apprenant fw-500 fs-18"> {this.props.apprenantInfos.civilite} </p>
                            </li>
                            <li className="li-modal-details-apprenant">
                                <p className="text-content-modal-details-apprenant">Prénom & Nom</p>
                                <p className="text-content-modal-details-apprenant fw-500 fs-18"> {this.props.apprenantInfos.first_name + ' ' + this.props.apprenantInfos.last_name} </p>
                            </li>
                            <li className="li-modal-details-apprenant">
                                <p className="text-content-modal-details-apprenant">Adresse mail</p>
                                <p className="text-content-modal-details-apprenant fw-500 fs-18"> {this.props.apprenantInfos.email} </p>
                            </li>
                            <li className="li-modal-details-apprenant">
                                <p className="text-content-modal-details-apprenant">Numéro de téléphone</p>
                                <p className="text-content-modal-details-apprenant fw-500 fs-18"> {this.props.apprenantInfos.phone} </p>
                            </li>
                        </ul>
                    </div>
                    <div className={`tab-pane fade ${is_active_prg ? 'show active' : ''}`} id={`progression-${this?.props?.apprenantInfos?.id}`} role="tabpanel" aria-labelledby={`progression-tab-${this?.props?.apprenantInfos?.id}`}>
                        <ItemProgressionModalDetailsApprenant apprenant_Infos={this?.props.apprenantInfos} />
                    </div>
                    <div className={`tab-pane fade ${is_active_certif ? 'show active' : ''}`} id={`certificat-${this?.props?.apprenantInfos?.id}`} role="tabpanel" aria-labelledby={`certificat-tab-${this?.props?.apprenantInfos?.id}`}>

                        {/* <p className="f-f-work fs-14 fw-500 m-b-50 text-black">Nombre de certificat obtenu  <span className="nbre-certificat-obtenu">{this?.props?.apprenantInfos?.certification}</span></p> */}
                        <CertificatCard
                            data={this.state?.data}
                            test={this?.props?.apprenantInfos}
                            nb_certif={this?.props?.apprenantInfos?.certification}
                            createPdf={this.createPdf}
                            certificatHtml={this.state.certificatHtml} />
                        {/* <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <CertificatCard
                                    data={this.state.data}
                                    nb_certif={this?.props?.apprenantInfos?.certification}
                                    createPdf={this.createPdf}
                                    certificatHtml={this.state.certificatHtml} />
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                                <CertificatCard />
                            </div>                            
                        </div> */}
                    </div>
                    <div className={`tab-pane fade ${is_active_hist ? 'show active' : ''}`} id={`historique-${this?.props?.apprenantInfos?.id}`} role="tabpanel" aria-labelledby={`historique-tab-${this?.props?.apprenantInfos?.id}`}>
                        <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm">
                            {historiq?.count === 0 ?
                                <React.Fragment>
                                    <div className="col-xl-12 col-lg-12 col-md-12 p-b-200 content-position-after-header pdf-viewer-container">
                                        <div className="container">
                                            <div className="alert alert-info gfa-alert-info empty-badge-alert">
                                                <div className="row empty-alert-row">
                                                    <div className="col-md-2 empty-icon-col">
                                                        <FeatherIcon
                                                            icon="alert-circle"
                                                            className="m-b-3 empty-alert-icon rotateIn"
                                                        />
                                                    </div>
                                                    <div className="col-md-10 empty-textual-col">
                                                        <p>Pas d'historique pour ce utilisateur</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <table className="table table-striped">
                                        <tbody>
                                            {renderHistoriq}
                                        </tbody>
                                    </table>
                                    <div className="text-xs-center">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination pagination-circle pg-blue" style={{ justifyContent: 'center' }}>
                                                {pageNumbers !== undefined ? renderPageNumbers : null}
                                            </ul>
                                        </nav>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
