import React from "react";
import { NavLink, Navigate } from "react-router-dom";
import {
  PlayArrow,
  PlayCircleFilled,
  PostAdd,
  Straighten,
  VerifiedUser,
} from "@material-ui/icons";

function RFWelcome() {
  // console.log('tok', window.localStorage.getItem('userToken'))
  const userType = window.localStorage.getItem("userType");
  //console.log(userType)
  if (!window.localStorage.getItem("userToken")) {
    return (
      <div>
        {/* <HeaderAccueilV2 /> */}
        {/* Header */}
        <div className="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-z-10 tw-bg-white tw-shadow-sm">
          <div className="tw-container tw-mx-auto tw-py-3 tw-flex tw-justify-between tw-items-center">
            <NavLink className="" to="/">
              <img
                src="img/defaru300_100px.png"
                className=""
                alt=""
                loading="lazy"
                style={{ height: 50, width: 80 }}
              />
            </NavLink>

            <div className="tw-flex tw-gap-3">
              <NavLink
                // className="btn-connexion-header-accueil-v2 mr-md-3"
                className={
                  "tw-border tw-border-[#009688] tw-rounded-full tw-h-[40px] tw-px-6 tw-flex tw-items-center"
                }
                to="/connexion"
                style={{ color: "#009688" }}
              >
                Connexion
              </NavLink>
              <NavLink
                // className="btn-inscription-header-accueil-v2"
                className={
                  "tw-border tw-border-[#009688] tw-rounded-full tw-h-[40px] tw-px-6 tw-flex tw-items-center tw-text-white"
                }
                to="/inscription-fc"
                style={{ backgroundColor: "#009688" }}
              >
                Inscription
              </NavLink>
            </div>
          </div>
        </div>
        <div className="tw-pt-20">
          <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-gap-14">
            {/* Header Section */}
            <div className="tw-grid tw-grid-cols-2 sm:tw-grid-cols-3 tw-gap-4">
              {/* Première section */}
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-5 tw-justify-center">
                {/* Contenu gauche */}
                <p className="">Plus de 3000 cours disponibles</p>
                <h1 className="tw-text-2xl tw-my-4 tw-font-bold">
                  Améliorez votre apprentissage et vos Compétences avec{" "}
                  <span className="tw-text-[#009688]">Bakeli Learning</span>
                </h1>
                <p className="">
                  Plateforme innovante offrant une variété de cours interactifs,
                  conçus pour stimuler l'apprentissage et répondre aux besoins
                  individuels des apprenants.
                </p>

                <StartSessionButton />
              </div>
              <div className="">
                {/* Image à droite */}
                <img src="./img/landing_page/2t.png" alt="user" className="" />
              </div>
            </div>

            {/* Section Une */}
            <div className="tw-bg-[#FF9800] tw-rounded-lg tw-p-2 tw-text-white tw-grid tw-grid-cols-2 sm:tw-grid-cols-4 tw-gap-4">
              {[1, 2, 3, 4].map((_, index) => (
                <div
                  key={index}
                  className="tw-flex tw-items-center tw-justify-center tw-flex-col tw-p-12 tw-font-bold"
                >
                  <h3 className="tw-text-2xl">OK</h3>
                  <p className="">Etudiants</p>
                </div>
              ))}
            </div>

            {/* Section Deux */}
            <div className="tw-my-10 tw-px-0 md:tw-px-[5rem]">
              <div className="tw-flex tw-flex-col tw-items-center tw-mb-10">
                <h2 className="tw-text-2xl tw-font-bold">Cours populaires</h2>
                <p className="">
                  Découvrez votre programme parfait dans nos cours.
                </p>
              </div>
              <div className="tw-rounded-lg tw-p-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 tw-gap-5">
                {[
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#006258",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#FF9800",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                ].map((card, index) => (
                  <div
                    key={index}
                    className="tw-shadow-lg tw-rounded-lg tw-overflow-hidden"
                  >
                    <div
                      className={`tw-bg-[${card?.bg}] tw-flex tw-items-center tw-justify-center tw-h-[250px]`}
                      style={{ backgroundColor: card?.bg }}
                    >
                      <h3 className="tw-text-xl">{card?.module}</h3>
                    </div>
                    <div className="tw-px-4 tw-py-6 tw-text-black">
                      <h3 className="tw-text-xl">{card?.description}</h3>
                      <p className="tw-mt-2">Etudiants</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="tw-flex tw-justify-center tw-mt-10 ">
                <StartSessionButton />
              </div>
            </div>
          </div>

          {/* Session */}
          <div className="tw-bg-[#F5F5F5] tw-pt-20">
            <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-items-center tw-gap-10">
              <div className="">
                <h2 className="tw-text-2xl">
                  Catégories que vous souhaitez apprendre
                </h2>
                <p className="">
                  Découvrez des domaines de cours variés pour une expérience
                  d'apprentissage enrichissante et accessible à tous.
                </p>
              </div>

              <div className="tw-rounded-lg tw-pt-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-4 tw-gap-5">
                <div className="">
                  {/* Image à droite */}
                  <img
                    src="./img/landing_page/4.png"
                    alt="user"
                    className="img-fluid"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="tw-rounded-lg tw-col-span-3 tw-p-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-5">
                  {[
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "#006258",
                    },
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "#F44336",
                    },
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "#274EB9",
                    },
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "#FF9800",
                    },
                  ].map((card, index) => (
                    <div
                      key={index}
                      className="tw-shadow-lg tw-rounded-lg tw-overflow-hidden tw-w-full tw-p-5 tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-[200px] "
                      style={{ backgroundColor: card?.bg }}
                    >
                      <div className="tw-bg-white tw-text-black tw-h-20 tw-w-20 tw-flex tw-justify-center tw-items-center tw-rounded-full">
                        <PostAdd />
                      </div>
                      <h3 className="tw-text-md tw-text-center tw-mt-3">
                        {card?.title}
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Section 3 */}
          <div className="tw-bg-[#fff] tw-py-20">
            <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-gap-10">
              <div className="">
                <h2 className="tw-text-2xl">
                  Commencez votre voyage avec nous{" "}
                </h2>
                <p className="">
                  Débutez votre parcours d'apprentissage avec nous, où chaque
                  étape vous rapproche de votre objectif.{" "}
                </p>
              </div>
              <div className="tw-rounded-lg tw-p-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-4 tw-gap-5">
                <div className="tw-rounded-lg tw-col-span-3 tw-p-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-5">
                  {[
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "#006258",
                    },
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "red",
                    },
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "blue",
                    },
                    {
                      title: "Developpement Web et Mobile",
                      module: "Programmation",
                      description: "Comprendre les bases en JavaScript ",
                      bg: "orange",
                    },
                  ].map((card, index) => (
                    <div
                      key={index}
                      className="tw-p-5 tw-flex tw-gap-5 "
                      // style={{ backgroundColor: card?.bg }}
                    >
                      <div className="tw-bg-[#009688] tw-h-12 tw-min-w-12 tw-flex tw-justify-center tw-items-center tw-rounded-full">
                        O
                      </div>
                      <div className="tw-text-black tw-mt-2">
                        <h3 className="tw-text-md tw-font-bold">
                          {card?.title}
                        </h3>
                        <p className="tw-text-sm tw-mt-3">
                          Explorez des sujets passionnants et enrichissez vos
                          connaissances dès votre inscription.
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="tw-bg-gray-100 tw-rounded-md tw-flex tw-items-center tw-justify-center">
                  {/* Image à droite */}
                  {/* <img
                    src="./img/landing_page/4.png"
                    alt="user"
                    className="img-fluid"
                    style={{ width: "100%" }}
                  /> */}
                  <div className="tw-flex tw-items-center tw-justify-center tw-bg-[#FF9800] tw-h-[80px] tw-w-[80px] tw-rounded-full">
                    <PlayArrow />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section 4 */}
          <div className="tw-bg-[#F4F7FC] tw-py-20">
            <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-items-center tw-gap-10">
              <div className="tw-flex tw-flex-col tw-items-center tw-mb-10">
                <h2 className="tw-text-2xl tw-font-bold">Cours populaires</h2>
                <p className="">
                  Découvrez votre programme parfait dans nos cours.
                </p>
              </div>
              <div className="tw-rounded-lg tw-p-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-5">
                {[
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#006258",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#FF9800",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                ].map((card, index) => (
                  <div
                    key={index}
                    className="tw-shadow-lg tw-rounded-lg tw-overflow-hidden tw-bg-white"
                  >
                    <div
                      className={`tw-bg-[${card?.bg}] tw-flex tw-items-center tw-justify-center tw-h-[250px]`}
                      style={{ backgroundColor: card?.bg }}
                    >
                      <h3 className="tw-text-xl">{card?.module}</h3>
                    </div>
                    <div className="tw-px-4 tw-py-6 tw-text-black">
                      <h3 className="tw-text-xl">{card?.description}</h3>
                      <p className="tw-mt-2">Etudiants</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="tw-flex tw-justify-center tw-mt-10">
                <StartSessionButton />
              </div>
            </div>
          </div>

          {/* Section 5 */}
          <div className="tw-bg-[#fff] tw-py-20">
            <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-items-center tw-gap-10">
              <div className="tw-flex tw-flex-col tw-items-center tw-mb-10">
                <h2 className="tw-text-2xl tw-font-bold">Cours populaires</h2>
                <p className="">
                  Découvrez votre programme parfait dans nos cours.
                </p>
              </div>
              <div className="tw-rounded-lg tw-p-2 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-5">
                {[
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#006258",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#FF9800",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                ].map((card, index) => (
                  <div
                    key={index}
                    className="tw-overflow-hidden tw-flex tw-flex-col tw-items-center tw-gap-2"
                  >
                    <div className="tw-p-4">
                      <VerifiedUser />
                    </div>
                    <h3 className="tw-text-xl tw-font-bold tw-text-[#14133B] tw-text-center">
                      Développement personnel
                    </h3>
                    <p className="tw-mt-2 tw-text-center tw-text-sm">
                      Développement personnel pour une vie épanouie et
                      enrichissante au quotidien.
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Section 6 */}
          <div className="tw-bg-[#fff] tw-py-20">
            <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-gap-10">
              <div className="tw-flex tw-flex-col tw-items-center tw-mb-10">
                <h2 className="tw-text-3xl tw-mb-2 tw-font-bold">Nos Coachs</h2>
                <p className="">
                  Découvrez votre programme parfait dans nos cours avec nos
                  coachs.{" "}
                </p>
              </div>
              <div className="tw-rounded-lg tw-p-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-10 md:tw-gap-3">
                {[
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#006258",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#FF9800",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                ].map((card, index) => (
                  <div
                    key={index}
                    className="tw-border-2 tw-border-[#9995] tw-p-2 tw-rounded-lg tw-text-black tw-mb-10"
                  >
                    <div
                      className={`tw-flex tw-items-center tw-justify-center tw-flex-col`}
                      // style={{ backgroundColor: card?.bg }}
                    >
                      <img
                        src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww"
                        alt=""
                        className="tw-w-[150px] tw-h-[150px] tw-rounded-full -tw-mt-16"
                      />
                    </div>
                    <div className="tw-px-4 tw-text-center tw-mt-6 tw-mb-4">
                      <h3 className="tw-text-xl tw-font-bold">Harish Ham</h3>
                      <h3 className="tw-text-sm">SEO Expert</h3>
                      {/* <p className="tw-mt-2">Etudiants</p> */}
                    </div>

                    <div className="tw-h-[1px] tw-mx-4 tw-my-6 tw-bg-[#9995]"></div>
                    <div className="tw-flex tw-items-center tw-justify-center tw-gap-5 md:tw-gap-2 tw-text-xs">
                      <div className="tw-flex tw-items-center tw-gap-1">
                        <VerifiedUser />
                        <p className="tw-text-nowrap">20 etudiants</p>
                      </div>
                      <div className="tw-flex tw-items-center tw-gap-1">
                        <PlayCircleFilled />
                        <p className="tw-text-nowrap">2 cours</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Section 7 */}
          <div className="tw-bg-[#F4F7FC] tw-py-20">
            <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-gap-10">
              <div className="tw-flex tw-flex-col tw-items-center tw-mb-10">
                <h2 className="tw-text-3xl tw-mb-2 tw-font-bold">
                  Nos étudiants disent
                </h2>
                <p className="">
                  Expérience d'apprentissage enrichissante, transformationnelle
                  et inspirante pour les étudiants.
                </p>
              </div>
              <div className="tw-rounded-lg tw-p-2 tw-text-white tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-10 md:tw-gap-3">
                {[
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#006258",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#FF9800",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                ].map((card, index) => (
                  <div
                    key={index}
                    className="tw-border-2 tw-border-[#9995] tw-bg-white tw-shadow-md tw-p-4 tw-rounded-lg tw-text-black tw-mb-10"
                  >
                    <div className="">
                      <Straighten />
                    </div>
                    <div className="tw-px-2 tw-mt-6 tw-mb-4">
                      <h3 className="tw-text-sm">
                        Ils sont assez créatifs. Beaucoup assez créatif et
                        puissant. Lorem ipsum dolor s'asseoir amet consectetur
                        élite adipisicante.
                      </h3>
                    </div>

                    <div className="tw-flex tw-items-center tw-gap-2 tw-text-xs">
                      <div className="tw-flex tw-items-center tw-gap-3">
                        <img
                          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww"
                          alt=""
                          className="tw-w-[50px] tw-h-[50px] tw-rounded-full "
                        />
                        <div className="tw-flex tw-flex-col tw-gap-1">
                          <h2 className="tw-text-md tw-font-bold">John Doe</h2>
                          <p className="tw-text-nowrap">
                            Spécialiste en marketing
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Section 8 */}
          <div className="tw-bg-[#F4F7FC] tw-py-20">
            <div className="tw-container tw-z-30 tw-mx-auto tw-flex-col tw-shadow-md tw-gap-10  tw-bg-white tw-rounded-md tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-px-10 tw-pt-5">
              <div className="tw-flex tw-justify-center">
                <img
                  // className="img-course-on-card-cours"
                  src="./img/landing_page/2.png"
                  alt=""
                  className="tw-w-[200px] tw-align-middle"
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-6 tw-justify-center tw-col-span-2">
                <h3 className="tw-text-[#FF9800]">
                  Vous ne savez pas par où commencer ?
                </h3>
                <h2 className="tw-font-bold tw-text-lg">
                  Vous voulez connaître les offres spéciales et Mises à jour de
                  nouveaux cours ?
                </h2>

                <StartSessionButton />
              </div>
            </div>
          </div>

          {/* Footer */}
          <div
            className="tw-bg-[#1B192E] tw-py-20 tw-text-white -tw-mt-2 tw-z-10"
            style={{ zIndex: 10 }}
          >
            <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-gap-10">
              <div className="tw-flex tw-flex-col tw-items-center tw-mb-10">
                <h2 className="tw-text-3xl tw-mb-2 tw-font-bold">
                  Nos étudiants disent
                </h2>
                <p className="">
                  Expérience d'apprentissage enrichissante, transformationnelle
                  et inspirante pour les étudiants.
                </p>
              </div>
              <div className="tw-rounded-lg tw-p-2 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-4 tw-gap-10 md:tw-gap-3">
                <div className="">
                  <img
                    src="img/defaru300_100px.png"
                    className=""
                    alt=""
                    loading="lazy"
                    style={{ height: 50, width: 80 }}
                  />

                  <p className="tw-mt-10">
                    Bakeli Learning est un modèle Bootstrap pour sites Web
                    éducatifs de cours en ligne prendre en charge plusieurs
                    cours
                  </p>
                </div>
                {[
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#FF9800",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                  {
                    title: "Developpement web",
                    module: "Programmation",
                    description: "Comprendre les bases en JavaScript ",
                    bg: "#274EB9",
                  },
                ].map((card, index) => (
                  <div key={index} className="tw-p-4 tw-rounded-lg tw-mb-10">
                    <h2 className="tw-font-bold tw-text-2xl">Programmes</h2>
                    <div className="tw-mt-5 tw-flex tw-flex-col tw-gap-2">
                      <p className="">Entreprise de référencement</p>
                      <p className="">Communication Digitale</p>
                      <p className="">Design Graphique</p>
                      <p className="">Programmation</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    if (userType === "student") {
      return <Navigate to="/tous-les-modules" />;
    } else if (userType === "admin") {
      return <Navigate to="/tableau-de-bord" />;
    } else {
      return false;
    }
  }
}

export default RFWelcome;

function StartSessionButton({}) {
  return (
    <div className="tw-mt-2">
      <button className="tw-bg-[#F44336] tw-text-white tw-px-4 tw-py-3 tw-rounded-full">
        Demarer maintenent
      </button>
    </div>
  );
}
