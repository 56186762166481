import React from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
// import {
//   BrowserRouter as Router,
//   Redirect,
//   Route,
//   Switch,
// } from "react-router-dom";

import { Navigate, Routes, Route } from "react-router-dom";

import isAuthenticate from "./modules/Secure/Secure";
// import Accueil from './frontend/Accueil';
import Connexion from "./frontend/Connexion";
import AllCourses from "./frontend/dashboards/AllCoursesV2";
import Downloads from "./frontend/dashboards/Downloads";
import Forum from "./frontend/dashboards/Forum";
import MyCourses from "./frontend/dashboards/MyCourses";
import QuizCours from "./frontend/dashboards/QuizCours";
import SessionDetails from "./frontend/dashboards/SessionDetails";
import SessionStart from "./frontend/dashboards/SessionStart";
// import MyCertificatV2 from './frontend/dashboards/MyCertificatesV2';
import MyCertificatesV3 from "./frontend/dashboards/MyCertificatesV3";
// import MyCertificates from './frontend/dashboards/MyCertificates';
import Apprenants from "./backend/Apprenants";
import FCModule from "./backend/Module";
import Quiz from "./backend/Quiz";
import TableauDeBord from "./backend/TableauDeBord";
import ForumDetail from "./frontend/dashboards/ForumDetail";
//import ModuleDetails from './backend/ModuleDetails';
import "react-toastify/dist/ReactToastify.css";
import Certificat from "./backend/Certificat";
import CreateQcm from "./backend/components/CreateQcm";
import ModuleDetailsV2 from "./backend/ModuleDetailsV2";
import FicheEvaluation from "./frontend/components/FicheEvaluation";
import FicheIdentificaton from "./frontend/components/FicheIdentification";
import MyProfile from "./frontend/dashboards/MyProfile";
import SliderContentDetailsSession from "./frontend/dashboards/SliderContentDetailsSession";
import ResetPassword from "./frontend/ResetPassword";
import TokenExpirated from "./frontend/TokenExpirated";
import WelcomeV3 from "./frontend/WelcomeV3";
import isFirstConnexion from "./modules/Permission/Permission";
// import ScrollToTop from './ScrollToTop';
// import { Switch } from '@material-ui/core';
import { isMobile } from "react-device-detect";
import AdminContactMessages from "./backend/AdminContactMessages";
import MobileDetect from "./modules/MobileDetect/MobileDetect";
// import ListeIdentification from './backend/ListeIdentification';
import { ToastContainer } from "react-toastify";
import packageJson from "../package.json";
import AdminUserManager from "./backend/AdminUserManager";
import Extraction from "./backend/Extraction";
import ListeIdentificationV2 from "./backend/ListeIdentificationV2";
import Performances from "./backend/Performances";
import withClearCache from "./ClearCache";
import Inscription from "./frontend/Inscription";
import LegalNotice from "./frontend/LegalNotice";
import PrivacyPolicy from "./frontend/PrivacyPolicy";
import WelcomeV2 from "./frontend/WelcomeV2";
import { getBuildDate } from "./utils/utils";
import RFConnexion from "./frontend/RFConnexion";
import RFInscription from "./frontend/RFInscription";
import RFWelcome from "./frontend/RFWelcome";
import FCSessionStart from "./frontend/dashboards/FCSessionStart";
import FCModuleDetailsV2 from "./backend/FCModuleDetailsV2";
// import { useDispatch } from "react-redux";
// import { fetchListApprenants } from "./redux/apprenantList/apprenantListAction";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  console.log("Build date:", getBuildDate(packageJson.buildDate));
  var isLogInExpired = isAuthenticate();
  var isFirst = isFirstConnexion();
  // const dispatch = useDispatch();
  const token = window.localStorage.getItem("userToken");
  var userType = window.localStorage.getItem("userType");
  console.log("Type user", userType);

  // useEffect(() => {
  //   if (userType === "admin" && token) {
  //     dispatch(fetchListApprenants());
  //   }
  // }, [token]);

  if (isMobile) {
    return <MobileDetect />;
  } else {
    return (
      <div className="router-container">
        <ToastContainer />
        <Routes>
          {/* <Route exact path="/" ={Accueil} /> */}
          <Route exact path="/" element={<RFWelcome />} />
          <Route path="/politique-de-confidentialite" element={PrivacyPolicy} />
          <Route path="/mentions-legales" element={<LegalNotice />} />
          {/*  <Route path="/welcome" element={WelcomeV3} /> */}
          <Route path="/connexion" element={<RFConnexion />} />
          {/* <Route path="/connexion" element={<Connexion />} /> */}
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/inscription-fc" element={<RFInscription />} />
          <Route
            path="/modifier-mon-mot-de-passe"
            element={<ResetPassword />}
          />

          <Route
            path="/welcome"
            element={
              isLogInExpired || !token ? (
                <Navigate to="/connexion" />
              ) : (
                <WelcomeV3 />
              )
            }
          />

          {/* <Route
            path="/tous-les-modules"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              )  : (
                <AllCourses />
              )
            }
          /> */}

          <Route
            path="/tous-les-modules"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <AllCourses />
            }
          />

          <Route
            path="/mon-parcours"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <MyCourses />
            }
          />

          <Route
            path="/telechargements"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <Downloads />
            }
          />

          {/* <Route
            path="/forum"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : isFirst ? (
                userType === "admin" ? (
                  <Forum />
                ) : (
                  <Navigate to={`/welcome`} />
                )
              ) : (
                <Forum />
              )
            }
          /> */}
          <Route
            path="/forum"
            element={isLogInExpired ? <Navigate to="/connexion" /> : <Forum />}
          />

          <Route
            path="/mon-profile"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <MyProfile />
            }
          />

          <Route
            path="/cours-details/:id"
            element={<SessionDetails />}
            render={() => {
              if (isLogInExpired) {
                return <Navigate to={`/connexion`} />;
              } else {
                return <SessionDetails />;
              }
            }}
          />
          {/* <Route path="/cours-details/:id">
            {isLogInExpired ? <Navigate to="/connexion" /> : <SessionDetails />}
          </Route> */}
          {/* <Route
            path="/cours-start/:id"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : (
                <SessionStart />
              )
            }
          /> */}
          <Route
            path="/cours-start/:id"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <FCSessionStart />
            }
          />

          <Route
            path="/quiz-cours"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <QuizCours />
            }
          />

          <Route
            path="/mon-certificat"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : (
                <MyCertificatesV3 />
              )
            }
          />

          <Route
            path="/fiche-identification"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : (
                <FicheIdentificaton />
              )
            }
          />

          <Route
            path="/fiche-evaluation"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : (
                <FicheEvaluation />
              )
            }
          />
          <Route
            path="/details-forum/:id"
            element={<ForumDetail />}
            render={() => {
              if (isLogInExpired) {
                return <Navigate to={`/connexion`} />;
              } else {
                return <ForumDetail />;
              }
            }}
          />
          <Route
            path="/session-start/:component/:id"
            element={<SliderContentDetailsSession />}
            render={() => {
              if (isLogInExpired) {
                return <Navigate to={`/connexion`} />;
              } else {
                return <SliderContentDetailsSession />;
              }
            }}
          />
          {/* <Route path="/session-start/:id">
            {isLogInExpired ? (
              <Navigate to="/connexion" />
            ) : (
              <SliderContentDetailsSession />
            )}
          </Route> */}
          <Route path="/token-expire" element={<TokenExpirated />} />

          {/* Routing Backend  */}
          <Route
            path="/tableau-de-bord"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <TableauDeBord />
            }
          />

          <Route
            path="/performances"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <Performances />
            }
          />

          <Route
            path="/extraction"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <Extraction />
            }
          />

          <Route
            path="/gestion-messages-contact"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : (
                <AdminContactMessages />
              )
            }
          />

          <Route
            path="/apprenants"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <Apprenants />
            }
          />

          <Route
            path="/parametres"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : (
                <AdminUserManager />
              )
            }
          />

          <Route
            path="/liste-des-identifications"
            element={
              isLogInExpired ? (
                <Navigate to="/connexion" />
              ) : (
                <ListeIdentificationV2 />
              )
            }
          />

          <Route
            path="/quiz-admin"
            element={isLogInExpired ? <Navigate to="/connexion" /> : <Quiz />}
          />
          <Route
            path="/module"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <FCModule />
            }
          />
          {/* <Route exact path="/module-details/:id">
            {isLogInExpired ? <Navigate to="/connexion" /> : <ModuleDetailsV2/>}
          </Route> */}
          {/* <Route
            path="/module-details/:id"
            element={<ModuleDetailsV2 />}
            render={() => {
              if (isLogInExpired) {
                return <Navigate to={`/connexion`} />;
              } else {
                return <ModuleDetailsV2 />;
              }
            }}
          /> */}
          <Route
            path="/module-details/:id"
            element={<FCModuleDetailsV2 />}
            render={() => {
              if (isLogInExpired) {
                return <Navigate to={`/connexion`} />;
              } else {
                return <FCModuleDetailsV2 />;
              }
            }}
          />
          {/* <Route exact path="/module-details/:id" element={ModuleDetails} /> */}

          <Route
            path="/qcm"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <CreateQcm />
            }
          />

          <Route
            path="/certificats"
            element={
              isLogInExpired ? <Navigate to="/connexion" /> : <Certificat />
            }
          />
        </Routes>
      </div>
    );
  }
}

export default App;
