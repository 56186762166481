import React, { Component } from "react";
import FeatherIcon from "feather-icons-react";
// import { NavLink } from 'react-router-dom';
import NavbarDashboardMobile from "./components/NavbarDashboardMobile";
import HeaderDashboard from "./components/HeaderDashboard";
import FCNavbarVerticalAdmin from "./components/FCNavbarVertical";
import QuizItem from "./components/QuizItem";
import MessageAllPage from "./components/MessageAllPage";

export default class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
    };
  }

  render() {
    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      window.location = "connexion";
    } else {
      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobile />
            </div>
          </div>

          <HeaderDashboard />

          <div className="row">
            {/* Navbar Desktop  */}
            {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
            <div className="nav-container">
              <div
                className="navbar-vertical-customisation no-view-mobile"
                style={{ top: "40px", zIndex: 98 }}
              >
                <FCNavbarVerticalAdmin />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-12"></div>

            <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
              <div className="container-fluid">
                <h6 className="text-black f-f-work fw-500 m-b-15">QUIZZ</h6>
                <div className="card-dashboard m-b-20">
                  <form>
                    <p className="fs-18 fw-500 f-f-work m-b-50 text-black">
                      Ajouter un quiz
                    </p>
                    <div className="row">
                      <div className="col-md-9">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control input-dashboard-backend"
                            placeholder="Titre"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-30">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6">
                            {/* <div className="form-group">
                                                        <select className="form-control input-dashboard-backend select-input-dashboard-backend">
                                                            <option>Choisir un module</option>
                                                        </select>
                                                    </div> */}
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="form-control input-dashboard-backend select-input-dashboard-backend">
                                <option>Choisir une leçon</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-10">
                      <div className="col-xl-1 col-lg-2 col-md-2 itm-center p-r-0">
                        <label
                          className="label-quiz-backend"
                          htmlFor="question"
                        >
                          Question
                        </label>
                      </div>
                      <div className="col-xl-8 col-lg-7 col-md-7 itm-center">
                        <textarea
                          rows="2"
                          className="form-control form-control-quiz-backend"
                          placeholder=""
                          id="question"
                        ></textarea>
                        <button className="btn-placeholder-textarea">
                          <FeatherIcon
                            className="icon-btn-placeholder-textarea"
                            icon="link-2"
                          />
                        </button>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 itm-center justify-content-end justify-left-tablette m-b-15">
                        <label
                          className="label-quiz-backend m-b-0 m-r-20"
                          htmlFor="score"
                        >
                          Score
                        </label>
                        <select
                          className="custom-select score-input-dashboard"
                          id="score"
                        >
                          <option selected>0</option>
                        </select>
                      </div>
                    </div>
                    <div className="row m-b-10">
                      <div className="col-xl-1 col-lg-2 col-md-2 itm-center p-r-0">
                        <label
                          className="label-quiz-backend"
                          htmlFor="reponse1"
                        >
                          Réponse 1
                        </label>
                      </div>
                      <div className="col-xl-8 col-lg-7 col-md-7 itm-center">
                        <textarea
                          rows="2"
                          className="form-control form-control-quiz-backend"
                          placeholder=""
                          id="reponse1"
                        ></textarea>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 itm-center m-b-15">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="bonneReponse1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="bonneReponse1"
                          >
                            Bonne réponse
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-10">
                      <div className="col-xl-1 col-lg-2 col-md-2 itm-center p-r-0">
                        <label
                          className="label-quiz-backend"
                          htmlFor="reponse1"
                        >
                          Réponse 2
                        </label>
                      </div>
                      <div className="col-xl-8 col-lg-7 col-md-7 itm-center">
                        <textarea
                          rows="2"
                          className="form-control form-control-quiz-backend"
                          placeholder=""
                          id="reponse2"
                        ></textarea>
                        <button className="btn-placeholder-textarea">
                          <FeatherIcon
                            className="icon-btn-placeholder-textarea"
                            icon="image"
                          />
                        </button>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 itm-center m-b-15">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="bonneReponse1"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="bonneReponse2"
                          >
                            Bonne réponse
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-10">
                      <div className="col-xl-1 col-lg-2 col-md-2 itm-center p-r-0">
                        <label
                          className="label-quiz-backend"
                          htmlFor="reponse3"
                        >
                          Réponse 3
                        </label>
                      </div>
                      <div className="col-xl-8 col-lg-7 col-md-7 itm-center">
                        <textarea
                          rows="2"
                          className="form-control form-control-quiz-backend"
                          placeholder=""
                          id="reponse3"
                        ></textarea>
                        <button className="btn-placeholder-textarea">
                          <FeatherIcon
                            className="icon-btn-placeholder-textarea"
                            icon="image"
                          />
                        </button>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 itm-center m-b-15">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="bonneReponse3"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="bonneReponse3"
                          >
                            Bonne réponse
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-20">
                      <div className="col-xl-1 col-lg-2 col-md-2 itm-center p-r-0">
                        <label
                          className="label-quiz-backend"
                          htmlFor="reponse4"
                        >
                          Réponse 4
                        </label>
                      </div>
                      <div className="col-xl-8 col-lg-7 col-md-7 itm-center">
                        <textarea
                          rows="2"
                          className="form-control form-control-quiz-backend"
                          placeholder=""
                          id="reponse4"
                        ></textarea>
                        <button className="btn-placeholder-textarea">
                          <FeatherIcon
                            className="icon-btn-placeholder-textarea"
                            icon="image"
                          />
                        </button>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 itm-center m-b-15">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="bonneReponse4"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="bonneReponse4"
                          >
                            Bonne réponse
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-10">
                      <div className="col-xl-1 col-lg-2 col-md-2 itm-center p-r-0"></div>
                      <div className="col-xl-9 col-lg-9 col-md-9">
                        <button
                          type="button"
                          className="btn-add-dashboard-orange"
                          data-toggle="modal"
                          data-target=".modalCreateContent"
                        >
                          <FeatherIcon
                            className="icon-btn-add-dashboard-orange"
                            icon="plus"
                          />
                          Ajouter un contenu
                        </button>
                      </div>
                    </div>

                    <div className="row dis-flex justify-content-end m-t-60">
                      <div className="col-xl-3 col-lg-5 col-md-4 col-sm-6 col-12 itm-center m-b-20">
                        <button
                          type="button"
                          className="btn-add-dashboard-orange"
                          data-toggle="modal"
                          data-target=".modalCreateContent"
                        >
                          <FeatherIcon
                            className="icon-btn-add-dashboard-orange"
                            icon="plus"
                          />
                          Ajouter une question
                        </button>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-3 col-sm-5 col-12 m-b-20">
                        <button className="btn-theme text-up w-full">
                          Terminer
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-dashboard m-b-20 p-b-200">
                  <form>
                    <p className="fs-18 fw-500 f-f-work m-b-50 text-black">
                      Liste des quizz
                    </p>
                    <div className="row m-b-30">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="form-control input-dashboard-backend select-input-dashboard-backend">
                                <option>Choisir un module</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select className="form-control input-dashboard-backend select-input-dashboard-backend">
                                <option>Choisir une leçon</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <QuizItem />
                  <QuizItem />
                  <QuizItem />
                  <QuizItem />
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <MessageAllPage />
          </div>
        </div>
      );
    }
  }
}
