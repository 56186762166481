import React, { Component } from 'react'
import FeatherIcon from 'feather-icons-react'
import {
  BootstrapTable,
  TableHeaderColumn,
  ButtonGroup,
  ClearSearchButton,
} from 'react-bootstrap-table'
import api from '../../http/global-vars'
import axios from 'axios'
import { formatDate } from '../../utils'
import _ from 'lodash'
import moment from 'moment'
import FicheIdentificationSkeleton from '../AdminSkeletons/FicheIdentificationSkeleton'
import Skeleton from 'react-loading-skeleton'

let order = 'desc'
const optionFilter = {
  true: "Oui",
  false: "Non",
};

export default class FicheIdentification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      isPageLoading: true,
      listIdentified: [],
      length: 0,
    };
  }

  componentDidMount() {
    this.getIdentifiedList();
  }

  getIdentifiedList = async () => {
    const url = api + "feddback";
    await axios
      .get(url, this.state.requestConfig)
      .then((response) => {
        // const data = response.data.results;
        // console.log("data", data);
        this.setState({
          listIdentified: response.data.results,
          length: response.data.results?.length,
        });

        setTimeout(() => {
          this.setState({
            isPageLoading: false,
          });
        }, 2000);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  handleBtnClick = () => {
    if (order === "desc") {
      this.refs.table.handleSort("asc", "name");
      order = "asc";
    } else {
      this.refs.table.handleSort("desc", "name");
      order = "desc";
    }
  };

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="export-btn" sizeClass="btn-group-md">
        {props.exportCSVBtn}
      </ButtonGroup>
    );
  };

  showFullname = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.first_name + " " + row.user.last_name;
  };

  showGender = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.sexe;
  };

  showEmail = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.email;
  };

  showAdress = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.adresse;
  };

  showPhone = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.phone;
  };

  showBadge = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.badge;
  };

  showDateNaissance = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.date_naissance;
  };

  showCertificat = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.certificate ? "Oui" : "Non";
  };
  showCertificatFilter = (cell, row) => {
    //console.log('row cell', row, cell)
    return row.user.certificate;
  };

  showDateDebut = (cell, row) => {
    if (row.date_debut) {
      return formatDate(row.date_debut);
    } else {
      return "";
    }
  };

  showMois = (cell, row) => {
    let formatter = new Intl.DateTimeFormat("FR", {
      month: "long",
    });

    //console.log('form', formatter.format(Date.parse(row.created_at)))
    return formatter.format(Date.parse(row.created_at));
  };

  showAnnee = (cell, row) => {
    return moment(row.created_at).format("YYYY");
  };

  formatSchool = (cell, row) => {
    return row?.user?.school;
  };

  handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => this.handleClearButtonClick(onClick)}
      />
    );
  };

  render() {
    const { listIdentified, isPageLoading, length } = this.state;
    const options = {
      clearSearch: true,
      clearSearchBtn: this.createCustomClearButton,
      noDataText: "Aucune donnée disponible",
      btnGroup: this.createCustomButtonGroup,
      exportCSVText: "Extraction",
    };

    // console.log('lenght ', length, listIdentified?.length)
    // console.log('fiche identification=> ', listIdentified)

    return (
      <div className="item-card-extraction">
        <img
          className="icon-item-card-extraction"
          src="img/icons/paper.png"
          alt=""
        />
        <p className="text-item-card-extraction">Fiche d’identification</p>
        <button
          type="button"
          class="btn-item-card-extraction"
          data-toggle="modal"
          data-target="#ficheIdentification"
        >
          voir
        </button>

        <div
          class="modal fade"
          id="ficheIdentification"
          tabindex="-1"
          aria-labelledby="ficheIdentificationLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content pt-5 pb-3">
              <div className="px-md-4 px-3">
                <p className="titre-card-dashboard-sans-padding m-b-15">
                  Liste des apprenants ayant rempli la fiche d’identification{" "}
                  <span className="certified-count"> ({length}) </span>{" "}
                </p>
                <div className="row mt-5">
                  {/* <div className="col-xl-4 col-lg-6 mb-3">
                    <from>
                      <button className="btn-theme fs-14 fw-400 py-2 px-4">
                        <FeatherIcon className="icon-btn-add-dashboard-orange" icon="download" />
                        Extraction
                      </button>
                    </from>
                  </div> */}
                  {/* {!isPageLoading ? 
                    (
                      <div className="col-xl-4 col-lg-6 mb-3">
                      <from>
                      <button className="fs-14 fw-400 py-2 px-4">
                          <Skeleton className="icon-btn-add-dashboard-orange" />
                        </button>
                      </from>
                  </div> 
                    )
                  :
                  (
                    null
                  )} */}
                  <div className="col-xl-4 col-lg-6 mb-3">
                    {/* <div className="row">
                      <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                        <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                          <option selected>2020</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        </div>
                        <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                          <select class="form-select form-select-line-chart w-full" aria-label="Default select example">
                            <option selected>Décembre</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                    </div> */}
                  </div>
                  <div className="col-xl-4 mb-3"></div>
                </div>
              </div>

              <div className="table-container">
                {isPageLoading ? (
                  <FicheIdentificationSkeleton />
                ) : (
                  <BootstrapTable
                    ref="table"
                    data={listIdentified}
                    striped={true}
                    hover={true}
                    condensed={true}
                    multiColumnSort={2}
                    exportCSV
                    search
                    options={options}
                    pagination
                    version="4"
                    bordered={false}
                  >
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="60"
                      dataField="id"
                      isKey={true}
                    >
                      N°
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="300"
                      dataAlign="center"
                      dataField="FullName"
                      csvFormat={this.showFullname}
                      dataSort={true}
                      dataFormat={this.showFullname}
                    >
                      Prénom & Nom
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      filter={{ type: "TextFilter" }}
                      filterValue={this.showMois}
                      dataAlign="center"
                      width="200"
                      dataField="Month"
                      csvFormat={this.showMois}
                      dataSort={true}
                      dataFormat={this.showMois}
                    >
                      Mois
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      filter={{ type: "TextFilter" }}
                      filterValue={this.showAnnee}
                      dataAlign="center"
                      width="170"
                      dataField="Year"
                      csvFormat={this.showAnnee}
                      dataSort={true}
                      dataFormat={this.showAnnee}
                    >
                      Année
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      filter={{ type: "TextFilter" }}
                      dataAlign="center"
                      width="230"
                      dataField="pays_provenance"
                    >
                      Pays de provenance
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      width="130"
                      dataAlign="center"
                      dataField="Sexe"
                      csvFormat={this.showGender}
                      dataSort={true}
                      dataFormat={this.showGender}
                    >
                      Genre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="300"
                      dataField="Email"
                      csvFormat={this.showEmail}
                      dataAlign="center"
                      dataSort={true}
                      dataFormat={this.showEmail}
                    >
                      Adresse Email
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="200"
                      dataField="Adresse"
                      csvFormat={this.showAdress}
                      dataAlign="center"
                      dataSort={true}
                      dataFormat={this.showAdress}
                    >
                      Adresse
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="200"
                      dataField="Phone"
                      csvFormat={this.showPhone}
                      dataAlign="center"
                      dataSort={true}
                      dataFormat={this.showPhone}
                    >
                      Téléphone
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="250"
                      dataField="Date_De_Naissance"
                      csvFormat={this.showDateNaissance}
                      dataAlign="center"
                      dataSort={true}
                      dataFormat={this.showDateNaissance}
                    >
                      Date de naissance
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      width="100"
                      dataField="Badge"
                      csvFormat={this.showBadge}
                      dataSort={true}
                      dataFormat={this.showBadge}
                    >
                      Badges
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      width="120"
                      dataField="Certifié ?"
                      csvFormat={this.showCertificat}
                      dataAlign="center"
                      dataSort={true}
                      dataFormat={this.showCertificat}
                      filterValue={this.showCertificatFilter}
                      filter={{
                        type: "SelectFilter",
                        options: optionFilter,
                      }}
                    >
                      Certificat
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      width="120"
                      dataAlign="center"
                      dataField="migrant"
                    >
                      Migrant
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                    thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                    filter={{ type: 'TextFilter' }} dataAlign="center"
                    width="230" dataField='pays_provenance'>Pays de provenance</TableHeaderColumn> */}
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="150"
                      dataAlign="center"
                      dataField="profession"
                    >
                      Profession
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="250"
                      dataAlign="center"
                      dataField="situation_eco"
                    >
                      Situation économique
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      width="250"
                      dataAlign="center"
                      dataField="revenu_jour"
                    >
                      Revenu journalier
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="150"
                      dataAlign="Date_Debut"
                      csvFormat={this.showDateDebut}
                      dataField="Date_Debut"
                      dataSort={true}
                      dataFormat={this.showDateDebut}
                    >
                      Début
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                    thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                    width="150" dataAlign="center" dataField="data" dataSort={true} dataFormat={this.showDateDebut}>Fin</TableHeaderColumn>
                   */}
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="150"
                      dataAlign="center"
                      dataField="secteur"
                    >
                      Secteur
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      width="200"
                      dataAlign="center"
                      dataField="nbr_employe"
                    >
                      Nbr.d’employés
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                        textAlign: "center",
                      }}
                      width="320"
                      dataAlign="center"
                      dataField="nbr_employe_temp"
                    >
                      Nbr. employés temporaires
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="300"
                      dataAlign="center"
                      dataField="type_energie"
                    >
                      Type d’énergie
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="300"
                      dataAlign="center"
                      dataField="niveau_etude"
                    >
                      niveau d’étude
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="300"
                      dataAlign="center"
                      dataField="school"
                      dataFormat={(cell, row) => this.formatSchool(cell, row)}
                    >
                      Établissement
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      thStyle={{
                        fontWeight: 600,
                        fontSize: 16,
                        color: "#1F497D",
                        border: "none",
                      }}
                      tdStyle={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.7)",
                        border: "none",
                      }}
                      width="300"
                      dataAlign="center"
                      dataField="nom_diplome"
                    >
                      Certificat/diplôme officiel
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                    thStyle={{ fontWeight: 600, fontSize: 16, color: '#1F497D', border: 'none' }}
                    tdStyle={{ fontWeight: 500, fontSize: 14, color: 'rgba(0, 0, 0, 0.7)', border: 'none' }}
                    width="150" dataAlign="center" dataField='#'>Action</TableHeaderColumn> */}
                  </BootstrapTable>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
