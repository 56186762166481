import React, { Component } from "react";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import FCNavbarVerticalAdmin from "./components/FCNavbarVertical";
import api from "./../http/global-vars";
import MessageAllPage from "./components/MessageAllPage";
import FicheIdentification from "./components/FicheIdentification";
import ReponseQuestionOuvertes from "./components/ReponseQuestionOuvertes";
import FicheEvaluation from "./components/FicheEvaluation";

export default class Extraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      isPageLoading: false,
    };
  }

  componentDidMount() {}

  render() {
    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      window.location = "connexion";
    } else {
      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobileAdmin />
            </div>
          </div>

          <HeaderDashboardAdmin />

          <div className="row">
            {/* Navbar Desktop  */}
            {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
            <div className="nav-container">
              <div
                className="navbar-vertical-customisation no-view-mobile"
                style={{ top: "40px", zIndex: 98 }}
              >
                <FCNavbarVerticalAdmin />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-12"></div>

            <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
              <div className="container-fluid">
                <div className="row mb-3">
                  <div className="col-xl-4 col-lg-4 col-md-4">
                    <h6 className="title-view-dashboard mb-2">Extraction</h6>
                  </div>
                  <div className="col-xl-6 offset-xl-2 col-lg-8 col-md-8 mb-2"></div>
                </div>
                <div className="row mb-3 px-2">
                  <div className="col-xl-3 col-lg-4 col-md-3 col-sm-4 d-flex px-2 mb-3">
                    <FicheIdentification />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-3 col-sm-4 d-flex px-2 mb-3">
                    <FicheEvaluation />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-3 col-sm-4 d-flex px-2 mb-3">
                    <ReponseQuestionOuvertes />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <MessageAllPage />
          </div>
        </div>
      );
    }
  }
}
