import React, { useEffect, useState } from "react";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import MessageAllPage from "./components/MessageAllPage";
import ModuleItem from "./components/FCModuleItem";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import FCNavbarVerticalAdmin from "./components/FCNavbarVertical";
import { Add } from "@material-ui/icons";
import { CardSkeleton } from "./Apprenants";
import Axios from "axios";
import api from "../http/global-vars";

const FCModule = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState(null);

  const onGetStatistiques = () => {
    var config = {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
      },
    };

    Axios.get(api + "dashboard_info/", config)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    onGetStatistiques();
  }, []);

  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobileAdmin />
        </div>
      </div>

      <HeaderDashboardAdmin />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="nav-container">
          <div
            className="navbar-vertical-customisation no-view-mobile"
            style={{ top: "20px", zIndex: 98 }}
          >
            <FCNavbarVerticalAdmin />
          </div>
        </div>

        <div className="col-xl-2 col-lg-3 col-md-12"></div>

        <div className="tw-flex-1 tw-mt-14 tw-p-4">
          {/* <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0"> */}
          <div className="">
            <div className="tw-flex tw-items-center tw-justify-between tw-py-2">
              <h5 className="text-black f-f-work fw-500">Module</h5>
              <button
                type="button"
                className="link-add-card-add tw-bg-[orange] tw-text-white tw-p-2 tw-rounded-full"
                data-toggle="modal"
                data-target="#AddModuleModal"
              >
                <Add />
                <span>Ajouter un module</span>
              </button>
            </div>

            {isPageLoading ? (
              <div className="row m-b-10">
                <CardSkeleton />
              </div>
            ) : (
              <div className="row m-b-10">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                  <div className="card-dashboard w-full m-b-15">
                    <p className="chiffre-statistics-apprenant-page">
                      {data?.module ?? 0}
                    </p>
                    <p className="legend-statistics-apprenant-page">Modules</p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 d-flex">
                  <div className="card-dashboard w-full m-b-15">
                    <p className="chiffre-statistics-apprenant-page">
                      {data?.sessions ?? 0}
                    </p>
                    <p className="legend-statistics-apprenant-page">
                      Sessions{" "}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <h6 className="text-black f-f-work fw-500 m-b-15">
              Liste des modules
            </h6>

            {/* La liste des modules */}
            <ModuleItem />
          </div>
        </div>
      </div>
      <div className="">
        <MessageAllPage />
      </div>
    </div>
  );
};

export default FCModule;
