import axios from 'axios'
import $ from 'jquery'
import React, { Component } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import api from '../../http/global-vars'
import ItemIntroModule from './ItemIntroModule'
import ModalAddSession from './ModalAddSession'

export default class ItemSessionModule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: window.localStorage.getItem('userToken'),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('userToken')}`,
        },
      },
      api: api,
      data: [],
      open: false,
      deleteSessionInProgress: false,
      isSuccessDeleteSession: false,
      isErrorDeleteSession: false,
    }

    this.onDeleteSession = this.onDeleteSession.bind(this)
  }

  componentDidMount() {
    this._getSessionByModule(this.props.module_id)
  }

  _getSessionByModule = async (module_id) => {
    let { api } = this.state
    let url = api + `sessionByModule/${module_id}/`
    try {
      let res = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.userToken}` },
      })
      // console.log("enter here");
      console.log(res)
      let data = res['data']
      console.log(data)
      this.setState({ data: data })
    } catch (error) {
      console.log(error?.message)
      console.log(error?.response)
      toast.error(
        'Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.',
        { position: toast.POSITION.TOP_CENTER, autoClose: 5000 },
      )
    }
  }

  onDeleteSession = (e, session_id) => {
    e.preventDefault()
    console.log(session_id)
    if (window.confirm('Confirmez-vous la suppression de cette session ?')) {
      $('.deleting' + session_id).html('<i class="fas fa-spin fa-spinner"></i>')

      var api = this.state.api
      var config = this.state.requestConfig
      axios
        .delete(api + `session/${session_id}/`, config)
        .then((response) => {
          console.log(response)
          if (response.status === 204) {
            this._getSessionByModule(this.props.module_id)
            toast.success('La session a été supprimée avec succès.')
            this.setState({
              isSuccessDeleteSession: true,
            })
            setTimeout(() => {
              this.setState({
                isSuccessDeleteSession: false,
              })
            }, 5000)
          }
        })
        .catch((error) => {
          $('.deleting' + session_id).html(
            '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash icon-btn-delete-item-sessions-module"><g><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></g></svg>',
          )
          console.log(error)
          toast.error(
            'Oupsss! Une erreur est survenue. Veuillez réessayer plus tard.',
          )
          this.setState({
            isErrorDeleteSession: true,
          })
          setTimeout(() => {
            this.setState({
              isErrorDeleteSession: false,
            })
          }, 5000)
        })
    }
  }

  render() {
    let { data } = this.state
    return (
      <React.Fragment>
        {data?.map((session) => {
          return (
            <ItemIntroModule
              module={this.props.module}
              session={session}
              key={session.id}
              getSessions={() => this._getSessionByModule(this.props.module_id)}
            />
          )
        })}
        <div
          className="modal fade modalAddSession"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content card-dashboard">
              <ModalAddSession module_id={this.props.module_id} getSessions={() => this._getSessionByModule(this.props.module_id)} />
            </div>
          </div>
        </div>
        <div className="toasty-container">
          {this.state.isErrorDeleteSession ||
          this.state.isSuccessDeleteSession ? (
            <ToastContainer />
          ) : (
            ''
          )}
        </div>
      </React.Fragment>
    )
  }
}

export function hideModal(selector) {
  $(selector).hide()
  $('.modal-backdrop').hide()
  $('body').css({ overflow: 'scroll' })
}
