import React, { Component } from "react";

export default class MobileDetect extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="mobile-detect-component">
        <nav className="navbar navbar-light bg-white  nav-accueil p-t-15 p-b-15">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img
                src={`/img/defaru300_100px.png`}
                className="logo-accueil-header"
                alt=""
                loading="lazy"
                style={{ height: 100, width: 150 }}
              />
            </a>
          </div>
        </nav>
        <div className="bg-white p-t-20 p-b-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 dis-flex itm-center">
                <div>
                  <h3 className="text-dark fw-700 f-f-poppins lh-1-5">
                    Reussir au Sénégal
                  </h3>
                  <p className="text-black fs-16 fw-300 m-b-60 gfa-intro">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Itaque aliquam nemo ipsum ad suscipit? Fuga modi, sequi
                    repudiandae, expedita magni deserunt officia, quibusdam
                    numquam quasi provident error incidunt ea ab.
                  </p>

                  <div className="goto-store-label">
                    Téléchargez l'application Bakeli Learning
                  </div>
                  <div className="link-icon-container">
                    <a
                      className="goto-store"
                      href="https://play.google.com/store/apps/details?id=com.volkeno.defaru"
                    >
                      <span className="app-icon-container">
                        <img
                          src="/img/android-app-icon.png"
                          alt="Android App"
                          className="app-icon"
                        />
                      </span>
                    </a>
                  </div>
                  <div className="link-icon-container">
                    <a
                      className="goto-store"
                      href="https://apps.apple.com/sn/app/defaru/id1538942051"
                    >
                      <span className="app-icon-container">
                        <img
                          src="/img/app-store-logo.png"
                          alt="iOS App"
                          className="app-icon"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-5 dis-flex offset-md-1 justify-content-center itm-center">
                <img className="w-full" src="img/img-accueil.png" alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
