import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class HeaderAccueil extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <nav className="navbar navbar-light bg-white  nav-accueil p-t-15">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src="img/defaru300_100px.png"
              alt="logo"
              style={{ width: 100, height: 60 }}
              loading="lazy"
            />
          </NavLink>
        </div>
      </nav>
    );
  }
}
