import React, { Component } from "react";
import NavbarDashboardMobileAdmin from "./components/NavbarDashboardMobile";
import HeaderDashboardAdmin from "./components/HeaderDashboard";
import FCNavbarVerticalAdmin from "./components/FCNavbarVertical";
import LineChart from "./components/LineChart";
import api from "./../http/global-vars";
import axios from "axios";
// import CertificationDoughnutChart from './components/CertificationDoughnutChart'
// import isAuthenticate from './../modules/Secure/Secure'
import MessageAllPage from "./components/MessageAllPage";
import Skeleton from "react-loading-skeleton";
import { BookOutlined } from "@material-ui/icons";

export default class TableauDeBord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      api: api,
      userID: window.localStorage.getItem("userID"),
      infos: [],
      isPageLoading: false,
      statData: [],
      moduleStatData: [],
      moduleName: [],
      apprenantCounting: [],
      certificatCount: [],
      finishedModuleCount: [],
      module1: [],
      module2: [],
      module3: [],
      example1: "all_users",
      dataDashboard: {},
      dashboard_infos: {
        list_modules: [],
      },
      is_load: true,
    };
  }

  componentDidMount() {
    // isAuthenticate()
    this._getInfosDashboard();
    this.onGetStatistiques();
    this.onGetModulesStatistiques();
    // this.setState({
    //     statData: this.state.infos.stat
    // })
    // console.log(this.state.userID)
  }

  // componentWillUnmount() {
  //     this._getInfosDashboard()
  //     this.onGetStatistiques()
  //     this.onGetModulesStatistiques()
  // }

  _getInfosDashboard = () => {
    let { api, requestConfig } = this.state;
    let url = api + "dashboard/";
    axios
      .get(url, requestConfig)
      .then((resp) => {
        // console.log("dashboard infos ==>", resp['data']?.data);
        if (resp["status"] === 200 || resp["status"] === 201) {
          console.log("dashboard=>", resp["data"]?.data);
          this.setState({ dataDashboard: resp["data"]?.data });
          // console.log('eligible_user', resp['data']?.data['eligible_user']);
          localStorage.setItem(
            "eligible_user",
            JSON.stringify(resp["data"]?.data["eligible_user"])
          );
          if (this.state.example1 === "all_users") {
            this.setState({
              dashboard_infos: {
                ...resp["data"]?.data["all_users"],
                list_modules: resp["data"]?.data["all_users"]?.list_modules,
              },
              is_load: false,
            });
          }
          console.log(this.state.dataDashboard);
        }
      })
      .catch((err) => {
        console.log(err.message);
        this.setState({ is_load: false });
      });
    // try {
    //     const req = await axios.get(url, requestConfig);
    //     console.log("dashboard infos ==>", req['data']?.data);
    //     this.setState({ dataDashboard: req['data']?.data })
    //     // this.setState({dashboard_infos:req['data']?.data})
    //     if (this.state.example1 === 'option3') {
    //         this.setState({ dashboard_infos: req['data']?.data['all_users'] })
    //     }
    //     // let list_student = req['data']?.data?.list_student
    //     // this.setState({ list_student: list_student })
    // } catch (error) {
    //     console.log(error.message)
    // }
  };

  onGetStatistiques = () => {
    this.setState({
      isPageLoading: true,
    });
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(api + "dashboard_info/", config)
      .then((response) => {
        // console.log(response)
        this.setState({
          infos: response.data.data,
          isPageLoading: false,
        });
        // console.log("infos ==>", response['data'].data)
      })
      .catch((error) => {
        console.log(error.message);
        this.setState({
          isPageLoading: false,
        });
      });
  };

  onGetModulesStatistiques = () => {
    var api = this.state.api;
    var config = this.state.requestConfig;

    axios
      .get(api + "certificat_info/", config)
      .then((response) => {
        // console.log(response.data.data.list_modules)
        // console.log("module info ==>", response['data'].data)
        this.setState({
          moduleStatData: response.data.data.list_modules,
          isPageLoading: false,
        });

        this.state.moduleStatData.forEach((item, index) => {
          if (index === 0) {
            this.setState({
              module1: {
                moduleName: item.module.name,
                apprenantCounting: item.nbr_apprenant,
                finishedModuleCount: item.nbr_user_finished,
              },
            });
          }
          if (index === 1) {
            this.setState({
              module2: {
                moduleName: item.module.name,
                apprenantCounting: item.nbr_apprenant,
                finishedModuleCount: item.nbr_user_finished,
              },
            });
          }
          if (index === 2) {
            this.setState({
              module3: {
                moduleName: item.module.name,
                apprenantCounting: item.nbr_apprenant,
                finishedModuleCount: item.nbr_user_finished,
              },
            });
          }
        });
        // console.log(this.state.module1)
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  onValueChange = (event) => {
    const { name, value } = event.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(this.state?.example1);
        if (this.state.example1 === "all_users") {
          this.setState({
            dashboard_infos: this.state.dataDashboard["all_users"],
          });
        }
        if (this.state.example1 === "eligible_user") {
          this.setState({
            dashboard_infos: this.state.dataDashboard["eligible_user"],
          });
        }
        if (this.state.example1 === "not_eligible_user") {
          this.setState({
            dashboard_infos: this.state.dataDashboard["not_eligible_user"],
          });
        }
      }
    );
  };

  render() {
    let { dashboard_infos, isPageLoading, is_load } = this.state;
    console.log("info", dashboard_infos);
    const renderDashBoard = () => (
      <React.Fragment>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
          <div className="card-statistics card-statistics-color-1">
            <img
              className="icon-card-statistics"
              src="img/icons/icon-card-statistics1.png"
              alt=""
            />
            {/* <p className="chiffre-card-statistics">{this.state.isPageLoading === true ? 0 : this.state.infos.apprenants}</p> */}
            <p className="chiffre-card-statistics">
              {isPageLoading === true ? 0 : dashboard_infos?.apprenants}
            </p>
            <p className="titre-card-statistics">Apprenants</p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
          <div className="card-statistics card-statistics-color-1">
            {/* <img
              className="icon-card-statistics"
              src="img/icons/icon-card-statistics1.png"
              alt=""
            /> */}
            <BookOutlined className="icon-card-statistics" fontSize="20" />
            {/* <p className="chiffre-card-statistics">{this.state.isPageLoading === true ? 0 : this.state.infos.apprenants}</p> */}
            <p className="chiffre-card-statistics">
              {isPageLoading === true ? 0 : dashboard_infos?.module}
            </p>
            <p className="titre-card-statistics">Modules</p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
          <div className="card-statistics card-statistics-color-2">
            <img
              className="icon-card-statistics"
              src="img/icons/icon-card-statistics2.png"
              alt=""
            />
            {/* <p className="chiffre-card-statistics">{this.state.isPageLoading === true ? 0 : this.state.infos.session_en_cours}</p> */}
            <p className="chiffre-card-statistics">
              {isPageLoading === true ? 0 : dashboard_infos?.fiches}
            </p>
            <p className="titre-card-statistics">Fiches D'identification</p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
          <div className="card-statistics card-statistics-color-3">
            <img
              className="icon-card-statistics"
              src="img/icons/icon-card-statistics3.png"
              alt=""
            />
            <p className="chiffre-card-statistics">
              {isPageLoading === true ? 0 : dashboard_infos?.apprenants_last}
            </p>
            <p className="titre-card-statistics">
              Nombre d’apprenants (dernières 24H )
            </p>
          </div>
        </div>
      </React.Fragment>
    );
    const renderInfoModule = () => (
      <React.Fragment>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
          <div className="card-statistics card-statistics-color-4">
            <img
              className="icon-card-statistics"
              src="img/icons/icon-card-statistics4.png"
              alt=""
            />
            {/* <p className="chiffre-card-statistics"> {this.state.isPageLoading === true ? 0 : this.state.module1.finishedModuleCount + '/' + this.state.module1.apprenantCounting} </p> */}
            <p className="chiffre-card-statistics">
              {" "}
              {isPageLoading === true
                ? null
                : dashboard_infos?.list_modules[0]?.nbr_user_finished ??
                  "0" + "/" + dashboard_infos?.list_modules[0]?.nbr_apprenant
                ? dashboard_infos?.list_modules[0]?.nbr_apprenant
                : "0"}
            </p>
            {/* <p className="titre-card-statistics">{this.state.module1.moduleName}</p> */}
            <p className="titre-card-statistics">
              {dashboard_infos?.list_modules[0]?.module?.name}
            </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
          <div className="card-statistics card-statistics-color-6">
            <img
              className="icon-card-statistics"
              src="img/icons/icon-card-statistics4.png"
              alt=""
            />
            <p className="chiffre-card-statistics">
              {" "}
              {isPageLoading === true
                ? 0
                : dashboard_infos?.list_modules[1]?.nbr_user_finished ??
                  "0" + "/" + dashboard_infos?.list_modules[1]?.nbr_apprenant ??
                  "0"}
            </p>
            {/* <p className="chiffre-card-statistics"> {this.state.isPageLoading === true ? 0 : this.state.module3.finishedModuleCount + '/' + this.state.module3.apprenantCounting} </p> */}
            {/* <p className="titre-card-statistics">{this.state.module3.moduleName}</p> */}
            <p className="titre-card-statistics">
              {dashboard_infos?.list_modules[1]?.module?.name}
            </p>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2">
          <div className="card-statistics card-statistics-color-5">
            <img
              className="icon-card-statistics"
              src="img/icons/icon-card-statistics4.png"
              alt=""
            />
            <p className="chiffre-card-statistics">
              {" "}
              {isPageLoading === true
                ? 0
                : dashboard_infos?.list_modules[2]?.nbr_user_finished ??
                  0 + "/" + dashboard_infos?.list_modules[2]?.nbr_apprenant ??
                  "0"}
            </p>
            {/* <p className="chiffre-card-statistics"> {this.state.isPageLoading === true ? 0 : this.state.module2.finishedModuleCount + '/' + this.state.module2.apprenantCounting} </p> */}
            {/* <p className="titre-card-statistics">{this.state.module2.moduleName}</p> */}
            <p className="titre-card-statistics">
              {dashboard_infos?.list_modules[2]?.module?.name}
            </p>
          </div>
        </div>
      </React.Fragment>
    );

    if (
      this.state.token === null ||
      this.state.token === undefined ||
      this.state.token === ""
    ) {
      window.location = "connexion";
    } else {
      return (
        <div className="container-fluid body-background-dashboard">
          <div className="row">
            {/* Navbar Mobile */}
            <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
              <NavbarDashboardMobileAdmin />
            </div>
          </div>

          <HeaderDashboardAdmin />

          <div className="row">
            {/* Navbar Desktop  */}
            {/* <div className="col-xl-2 col-lg-3 col-md-12"> */}
            <div className="nav-container">
              <div
                className="navbar-vertical-customisation no-view-mobile"
                style={{ top: "40px", zIndex: 98 }}
              >
                <FCNavbarVerticalAdmin />
              </div>
            </div>

            <div className="col-xl-2 col-lg-3 col-md-12"></div>

            <div className=" col-xl-10 col-lg-9 col-md-12 p-t-25 content-position-after-header px-md-3 px-0">
              <div className="container-fluid">
                {is_load ? (
                  <FilterSkeleton />
                ) : (
                  <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-4 dis-flex itm-center">
                      <h6 className="title-view-dashboard">Tableau de Bord</h6>
                    </div>
                    <div className="col-xl-6 offset-xl-3 col-lg-8 col-md-8 mb-2">
                      <div className="row px-2">
                        <div className="col-lg-1 px-1 mb-2 col-md-1 col-sm-2 dis-flex itm-center justify-content-sm-center">
                          <p className="title-filter-radio-dashboard-admin">
                            Filtrer
                          </p>
                        </div>
                        <div className="col-lg-4 mb-2 px-1 col-md-4 col-sm-4">
                          <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="popul-eligible"
                              onChange={(e) => this.onValueChange(e)}
                              value="eligible_user"
                              name="example1"
                              checked={this.state.example1 === "eligible_user"}
                            />
                            <label
                              className="custom-control-label filter-radio-dashboard-admin"
                              htmlFor="popul-eligible"
                            >
                              Populations éligibles
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
                          <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="popul-non-eligible"
                              onChange={(e) => this.onValueChange(e)}
                              value="not_eligible_user"
                              name="example1"
                              checked={
                                this.state.example1 === "not_eligible_user"
                              }
                            />
                            <label
                              className="custom-control-label filter-radio-dashboard-admin"
                              htmlFor="popul-non-eligible"
                            >
                              Populations non-éligibles
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-2 mb-2 px-1 col-md-2 col-sm-2">
                          <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
                            <input
                              type="radio"
                              className="custom-control-input"
                              id="total-filter"
                              onChange={(e) => this.onValueChange(e)}
                              value="all_users"
                              name="example1"
                              checked={this.state.example1 === "all_users"}
                            />
                            <label
                              className="custom-control-label filter-radio-dashboard-admin"
                              htmlFor="total-filter"
                            >
                              Total
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="row mb-3 px-2">
                  {is_load && <CardSkeleton />}
                  {!is_load && (
                    <React.Fragment>{renderDashBoard()} </React.Fragment>
                  )}
                </div>

                {/* <h6 className="chart-item-title mb-3">
                  {is_load ? (
                    <LabelStatSkeleton />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          'Nombre d’apprenants ayant terminé le module <span className="fw-300"> sur le nombre personnes qui l\'ont entamé </span>',
                      }}
                    />
                  )}
                </h6>
                 <div className="row mb-5 px-2">
                  {is_load && <CardSkeleton />}
                  {!is_load && (
                    <React.Fragment> {renderInfoModule()}</React.Fragment>
                  )}
                </div> */}
                <div className="row">
                  <div className="col-xl-12 m-b-5">
                    <div className="card-dashboard m-b-15">
                      {isPageLoading === false && (
                        <LineChart
                          data={this.state.dashboard_infos}
                          optionChoice={this.state.example1}
                        />
                      )}
                    </div>
                  </div>
                  {/* <div className="col-xl-12 m-t-5">
                                        <div className="card-dashboard m-b-15 p-b-20">
                                            <h2 className="chart-item-title text-black m-b-15">Certifications en %</h2>
                                            <CertificationDoughnutChart />
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <MessageAllPage />
          </div>
        </div>
      );
    }
  }
}

const CardSkeleton = () => {
  const renderModules = Array(3)
    .fill()
    .map((item, index) => (
      <div
        className="col-xl-4 col-lg-4 col-md-4 col-sm-6 d-flex px-2"
        key={index}
      >
        <div className="card">
          <Skeleton
            variant="rect"
            style={{ borderRadius: "10px" }}
            width={332}
            height={160}
          />
        </div>
      </div>
    ));
  return <React.Fragment>{renderModules}</React.Fragment>;
};

const LabelStatSkeleton = () => {
  return <Skeleton />;
};

const FilterSkeleton = () => {
  return (
    <div className="row">
      <div className="col-xl-3 col-lg-4 col-md-4 dis-flex itm-center">
        <h6 className="title-view-dashboard">
          {" "}
          <Skeleton width={200} height={40} />{" "}
        </h6>
      </div>
      <div className="col-xl-6 offset-xl-3 col-lg-8 col-md-8 mb-2">
        <div className="row px-2">
          <div className="col-lg-1 px-1 mb-2 col-md-1 col-sm-2 dis-flex itm-center justify-content-sm-center">
            <p className="title-filter-radio-dashboard-admin">
              {" "}
              <Skeleton width={40} height={20} />{" "}
            </p>
          </div>
          <div className="col-lg-4 mb-2 px-1 col-md-4 col-sm-4">
            <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
              <Skeleton width={123} height={10} />
              &nbsp;&nbsp;
              <Skeleton circle={true} height={10} width={10} />
            </div>
          </div>
          <div className="col-lg-5 mb-2 px-1 col-md-5 col-sm-4">
            <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
              <Skeleton width={160} height={10} />
              &nbsp;&nbsp;
              <Skeleton circle={true} height={10} width={10} />
            </div>
          </div>
          <div className="col-lg-2 mb-2 px-1 col-md-2 col-sm-2">
            <div className="custom-control custom-radio content-filter-radio-dashboard-admin">
              <Skeleton width={33} height={10} />
              &nbsp;&nbsp;
              <Skeleton circle={true} height={10} width={10} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
