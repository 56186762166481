import React, { Component } from 'react';
// import PdfContainer from '../../frontend/dashboards/PdfContainer';
import FeatherIcon from 'feather-icons-react';

export default class CertificateCard extends Component {

  render() {
    // let { createPdf, certificatHtml, nb_certif, data, certification, test } = this.props;
    let { nb_certif, data } = this.props;
    // console.log("teeeeeeeeest ==>", test);
    // console.log("props ==>",this.props?.nb_certif);
    let url = `https://backend.defaru.sn${data?.data?.url}`
    let renderCertif = (
      <a style={download_pdf_} target="_blank" rel="noopener noreferrer" href={url}>Télécharger votre attestation</a>
    )
    // <PdfContainer createPdf={createPdf}>
    //   <div dangerouslySetInnerHTML={{ __html: certificatHtml }} />
    // </PdfContainer>
    return (
      <div className="col-xl-12 col-lg-12 col-md-12 p-b-200 content-position-after-header pdf-viewer-container">
        <div className="container">
          {nb_certif !== 0 && data?.data?.url ? renderCertif :
            <div className="alert alert-info gfa-alert-info empty-badge-alert">
              <div className="row empty-alert-row">
                <div className="col-md-2 empty-icon-col">
                  <FeatherIcon
                    icon="alert-circle"
                    className="m-b-3 empty-alert-icon rotateIn"
                  />
                </div>
                <div className="col-md-10 empty-textual-col">
                  <p>{data?.message ? data?.message : 'il peut pas acceder au certificat pas assez de badges'}</p>
                </div>
              </div>
            </div>
          }
        </div>
        {/* <a className="no-decoration" href="/">
          <div className="card-certificate box-shadow p-l-0 p-r-0 p-t-0">
            <img className="w-full m-b-10" src="img/img-pdf.png" alt="" />
            <div className="dis-flex p-l-15">
              <img className="icon-pdf-card-cetificat" src="img/icons/vscode-icons_file-type-pdf2.png" alt="" />
              <div>
                <p className="title-info-card-certificate">Développement Web</p>
                <p className="date-info-card-certificate">Obtenu le 20/09/2020</p>
              </div>
            </div>
          </div>
        </a> */}
      </div>
    );
  }
}

const download_pdf_ = {
  top: '0',
  padding: '20px',
  borderRadius: '50px',
  background: '#f39200',
  border: '1px solid #f39200',
  color: ' #fff',
  fontWeight: '600',
  textAlign: 'center',
  display: 'block',
  width: '100%',
}